import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost, getToken } from "../services/Token";

export const ALL_COLOR = "ALL_COLOR";
export const ADD_COLOR = "ADD_COLOR"
export const EDIT_COLOR = "EDIT_COLOR"
export const DELETE_COLOR = "DELETE_COLOR"

export function getColorList() {
    return async (dispatch) => {
        const config = getToken('get', 'getColors');
        const responsive = await axios(config);
        const colorList = responsive.data.hathme;
        const payload = colorList.result;
        if (colorList.success === 1) {
            dispatch({ type: ALL_COLOR, payload, isFetching: false });
        } else {
            dispatch({ type: ALL_COLOR, payload:[], isFetching: false });
            toast.error(payload.resMsg);
        }
    }
}

export function colorAddResult(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('post', 'addColors', postData);
        const response = await axios(config);
        const payload = response.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: ADD_COLOR,
                message: payload.resMsg,
                isSuccess: true,
            });
            toast.success(payload.resMsg);
            dispatch(getColorList());
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
            dispatch({
                type: ADD_COLOR,
                message: payload.resMsg,
                isSuccess: false,
            });
        }
    };
}

export function colorUpdate(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('put', 'updateColor', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: EDIT_COLOR,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
            dispatch(getColorList());
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
        }
    };
}

export function deleteColor(colorId) {
    return async (dispatch) => {
        const postData = {
            "hathme": {
                "colorId": colorId
            }
        }
        const config = getTokenPost('post', 'deleteColor', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: DELETE_COLOR,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
        }
        dispatch(getColorList());
    };
}

export function activDeactivColor(colorId, status) {
    return async (dispatch) => {
        const postData = {
            "hathme": {
                "colorId": colorId,
                "status": status
            }
        };
        const config = getTokenPost('post', 'activeDeactiveColor', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: DELETE_COLOR,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
            dispatch(getColorList());
        }
    };
}
