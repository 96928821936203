import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import { toast } from "react-toastify";

const ChangePassword = () => {
  let navigate = useNavigate();
  const mystyle = {
    color: '#8b0707',
    fontWeigh: 'bold',
    fontSize: '13px'
  }
  const [PasswordChange, setPasswordChange] = useState({ password: '', newPassword: '', confirmPassword: '' });
  const [error, setError] = useState({ password: '', newPassword: '', confirmPassword: '' });

  const doSumbitCategory = (event) => {
    event.preventDefault();
    if (PasswordChange.password.length === 0) {
      setError({ ...error, password: 'Password cannot be empty' });
      return false;
    }
    if (PasswordChange.newPassword.length === 0) {
      setError({ ...error, newPassword: 'New password cannot be empty' });
      return false;
    }
    if (PasswordChange.confirmPassword !== PasswordChange.newPassword) {
      setError({ ...error, confirmPassword: 'Confirm password not match.' });
      return false;
    }
    updatePassword();
  }

  const passwordEvent = (event) => {
    setError({ password: '', newPassword: '', confirmPassword: '' });
    setPasswordChange({ ...PasswordChange, [event.target.name]: event.target.value })
  }

  const updatePassword = async () => {
    const postData = {
      "hathme": {
        "password": PasswordChange.password,
        "newPassword": PasswordChange.newPassword
      }
    }
    const config = getTokenPost('post', 'ChangePassword', postData);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      toast.success(dataResponse.resMsg);
      navigate("/dashboard");
    } else {
      toast.error(dataResponse.resMsg);
      setPasswordChange({ password: '', newPassword: '', confirmPassword: '' });
    }
  }

  return (
    <>
      <Layout>
        <div className='content-wrapper'>
          <Row className='page-header'>
            <h3 className="page-title">Change Password
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className='breadcrumb'>
                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Change Password</li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className='grid-margin stretch-card'>
              <Card>
                <CardBody>
                  <form className="forms-sample" onSubmit={(event) => doSumbitCategory(event)}>

                    <div className="form-group">
                      <label htmlFor="oldPassword">Old Password</label>
                      <input type="password" className="form-control" name="password" onChange={(event) => passwordEvent(event)} placeholder="Enter old password" />
                      {error.password.length > 0 ? (<span style={mystyle}>{error.password}</span>) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">New Password</label>
                      <input type="password" className="form-control" name="newPassword" onChange={(event) => passwordEvent(event)} placeholder="Enter password" />
                      {error.newPassword.length > 0 ? (<span style={mystyle}>{error.newPassword}</span>) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input type="password" className="form-control" name="confirmPassword" onChange={(event) => passwordEvent(event)} placeholder="Enter Confirm Password" />
                      {error.confirmPassword.length > 0 ? (<span style={mystyle}>{error.confirmPassword}</span>) : null}
                    </div>
                    <button type="submit" className="btn btn-gradient-primary mr-2">Change Password</button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default ChangePassword;