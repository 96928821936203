import React from "react";
import { Button } from "reactstrap";
const SearchInput = (props) => {
    const {onClick, onChange, searchTerm, placeholder} = props
  return (
    <>
      <Button
        style={{
          float: "right",
        }}
        className="btn btn-success btn-sm"
        onClick={onClick}
      >
        Search
      </Button>

      <input
        className="form-control w-auto float-right border-dark rounded ml-2"
        type="text"
        placeholder= {placeholder}
        style={{
          height: "32px",
        }}
        onChange={onChange}
        value={searchTerm}
      ></input>
    </>
  );
};

export default SearchInput