import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriverWithdrawal } from "../Action/Withdrawal";
import WithdrawalDriverAmount from "../Component/WithdrawalDriverAmount";

import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  List,Button
} from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Pagination from '../Paginate/Pagination';


let PageSize = 10;
const DriverWithdrawalList = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.withdrawalList);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState(false)
  const [pageNum, setPageNum] = useState()

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
 const [currentPage, setCurrentPage] = useState(page ? page : 1);
  useEffect(() => {
    setCurrentPage(searchTerm ? 1 : page ? page : 1 )
    dispatch(getAllDriverWithdrawal(searchTerm));
    setSearch(false)

    // eslint-disable-next-line 
  }, [search]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  useEffect(() => {
    if (location.pathname === "/driver-withdrawal") {
      setSearchTerm("");
      setSearch(true)
    }
  }, [location]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const searchData = () => {
    // dispatch(getAllDriverWithdrawal(searchTerm));
    setSearch(true)
  }
  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Manage Driver Withdrawal List</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Driver Withdrawal List
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className="grid-margin stretch-card">
                  <div className="container-fluid">
              <Row>
              <Col xs={12}>
                  <Card>
                    <CardBody>
                    <Row>
                        <Col>
                        <Button style={{
                          float: "right"
                        }} className='btn btn-success btn-sm'onClick={() => setSearch(true)}>Search</Button> 
                        <input
                        className="form-control"
                        type="text"
                        placeholder="Search UTR Number."
                        style={{
                          width: "220px",
                          border: "1px solid #333",
                          borderRadius: "1px",
                          float: "right",
                          marginLeft: "10px",
                          height:"32px"
                        }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      ></input>
                     
                        </Col>
                      </Row>
                      <div className="table-responsive">

                      <Table className="table table-striped ">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Wallet Balance</th>
                            <th>Amount To Pay</th>
                            <th>UTR Number</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Ifsc Code</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <WithdrawalDriverAmount currentItems={currentTableData} currentPage={currentPage} pageSize={PageSize}/>
                        </tbody>
                      </Table>
                      </div>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                        />
                    </CardBody>
                  </Card>
                  </Col>
              </Row>
              </div>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default DriverWithdrawalList;
