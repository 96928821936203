import React, { useState } from "react";
import StaticModal from "./StaticModal";
import NotificationModal from "./NotificationModal";
import ImageShowModal from "./ImageShowModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const UserList = ({ currentItems, currentPage, pageSize, selectedUserIds, setSelectedUserIds }) => {
  const [show, setShow] = useState(false);
  const [showPush, setPushShow] = useState(false);
  const [userId, setUserId] = useState('');
  const [ImageShow, setImageShow] = useState(false);
  const [title, settitle] = useState("");
  const sendNotification = (userId) =>{
    setUserId(userId);
    setPushShow(true);
  }

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    joinDate: "",
    status: "",
  });

  // eslint-disable-next-line 
  const handleShow = (item) => {
    settitle("Manage User Details");
    setUser({
      name: item.name,
      email: item.email,
      phone: item.mobile,
      joinDate: item.createdAt,
      status: item.isActive,
    });
    setShow(true);
  };
  const [ImgSrc, setImgSrc] = useState("");
  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  }
const handleDataSelection = (selectedItem) => {
  const isSelected = selectedUserIds.includes(selectedItem);

  if (isSelected) {
    setSelectedUserIds((prevSelectedData) =>
      prevSelectedData.filter((item) => item !== selectedItem)
    );
  } else {
    setSelectedUserIds((prevSelectedData) => [...prevSelectedData, selectedItem]);
  }
};
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <tr key={item._id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}<input type="checkbox" onClick={() => handleDataSelection(item._id)}  checked={selectedUserIds.includes(item._id) ? true : false}/></td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.mobile}</td>
            <td>{item.walletBalance}</td>
            <td >
              <LazyLoadImage src={item.profileImage}
                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                alt={item.name}
                onClick={() => { showImage(item.profileImage) }}
              />
            </td>

            <td>
              {item.isActive === 1 ? (
                <label
                  className="badge badge-success">
                  Active
                </label>
              ) : (
                <label
                className="badge badge-danger">
                  Deactive
                </label>
              )}
            </td>
            <td>{item.createdAt}</td>
            <td>
              <Link to={'/user-details/' + item._id} className='btn btn-info btn-sm'>View</Link>&nbsp;
              { item.isActive === 1 ? (<>
                <Button className='btn btn-warning btn-sm' onClick={() => sendNotification(item._id)}> <i className="mdi mdi-bell menu-icon"></i></Button> 
              </>) : ''}
              
            </td>
          </tr>
        ))}
      <StaticModal show={show} setShow={setShow} title={title} user={user} />
      <NotificationModal show={showPush} setShow={setPushShow} userId={userId} />
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
    </>
  );
};

export default UserList;


