import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";
import { getAllCategoryModel } from "./Category";

export const ADD_MODEL_STRUCTURE = "ADD_CATEGORY";
export const DELETE_CATEGORY = "REGISTER_SUCCESS";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const SINGLE_CATEGORY = "SINGLE_CATEGORY";
export const DELETE_MODEL_STRUCTURE = "DELETE_MODEL_STRUCTURE";
export const UPDATE_MODEL_STRUCTURE = "UPDATE_MODEL_STRUCTURE";
// export function getAllCategory() {
//   return async (dispatch, getState) => {
//     const config = getToken('get','GetAllCategory');
//     const responsive = await axios(config);
//     const categoriesList = responsive.data.hathme;
//     const payload = categoriesList.data;
//     if (categoriesList.success === 1) {
//       dispatch({ type: ALL_CATEGORY, payload, isFetching: false });
//     }else{
//       dispatch({ type: ALL_CATEGORY, isFetching: false});
//     }
//   }
// }

export function addModelStructure(payloadData) {

  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post','productModelStructure',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      toast.success(payload.resMsg);
    //   dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}

export function getModelStructureById(id) {
  return async (dispatch) => {
  
    const config = getTokenPost('get',`productModelStructureDetails/${id}`);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
  
    if (payload.success == 1) {
      // toast.success(payload.resMsg);
      // dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}


export function deleteModelStructure(id) {
  return async (dispatch) => {
  
    const config = getTokenPost('post',`deleteModelStructure/${id}`)
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_MODEL_STRUCTURE,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCategoryModel());
    }
  };
}

export function editModelStructure(payloadData,id) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('put',`updateModelStructure/${id}`,postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: UPDATE_MODEL_STRUCTURE,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      // dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}
// export function deleteCategory(id) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme": {
//         "categoryId":id
//       }
//     } 
//     const config = getTokenPost('post','deleteCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({
//         type: DELETE_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//       dispatch(getAllCategory());
//     }
//   };
// }
//

// export function editCategory(payloadData) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme":payloadData
//     } 
//     const config = getTokenPost('post','updateCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({
//         type: DELETE_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//       dispatch(getAllCategory());
//     }else if(payload.success === 0){
//       toast.error(payload.resMsg);
//     }
//   };
// }
