import { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import loaderGif from "../assets/loader.gif";
import { getAllUsers } from "../Action/User";
import { Col, Row, Card, CardBody, Table, List,Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Paginate/Pagination";
import UserList from "../Component/UserList";
import NotificationModal from "../Component/NotificationModal";
import SearchInput from "../Component/SearchInput";

let PageSize = 10;

const User = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [showPush, setPushShow] = useState(false);
  const [userId, setUserId] = useState('');
  const [Search, setSearch] = useState(false)
  const [pageNum, setPageNum] = useState()
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
 const [currentPage, setCurrentPage] = useState(page ? page : 1);

  useEffect(() => {
    // searchTerm = searchTerm.trim('')
    setCurrentPage(selectedStatusFilter || searchTerm ? 1 : page ? page : 1);
   
    dispatch(getAllUsers(searchTerm, selectedStatusFilter));
    setSearch(false)
    // eslint-disable-next-line
  }, [Search, selectedStatusFilter]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (location.pathname === "/user") {
      setSearchTerm("");
      setSelectedStatusFilter("")
      setSearch(true)
    }
  }, [location]);

  // New event handler to handle status filter change
  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
    setCurrentPage(1);
  };
  const sendNotification = (userId) =>{
    setUserId(userId);
    setPushShow(true);
  }
  const groupbyNotifications = (data) =>{
    setUserId(data);
    setPushShow(true);
  }
  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">Manage Users</h3>

                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User List
                    </li>
                  </List>
                </nav>
              </div>
              <Row>
                <Col className="grid-margin stretch-card">
                  <Card>
                    <CardBody>
                        <SearchInput
                        onClick={() => setSearch(true)}
                        placeholder="Search By Name Email or Number"
                        onChange={handleSearchChange}
                        searchTerm = {searchTerm}
                        />
                      {/* status based filter */}
                      <div className="float-right  col-md-3">
                        <select
                          className="form-control float-right mr-1 h-75 w-75 border-dark rounded"
                          onChange={handleStatusFilterChange}
                          value={selectedStatusFilter}
                        >
                          <option value="">Status Filter</option>
                          <option value="1">Active</option>
                          <option value="0">Deactive</option>
                        </select>
                      </div>
                      <Button className='btn btn-warning btn-sm' onClick={() => sendNotification(1)}>Send Notification</Button>&nbsp;
                      <Button className='btn btn-warning btn-sm px-3' onClick={() => groupbyNotifications(0)}>GroupBy Notifications</Button>
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th style={{ width: "15%" }}>S.No</th>
                            <th style={{ width: "15%" }}>Name</th>
                            <th style={{ width: "15%" }}>Email</th>
                            <th style={{ width: "15%" }}>Number</th>
                            <th style={{ width: "15%" }}>Wallet Balance</th>
                            <th style={{ width: "15%" }}>Profile</th>
                            <th style={{ width: "15%" }}>Status</th>
                            <th style={{ width: "15%" }}>Register Date</th>
                            <th style={{ width: "15%" }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <UserList currentItems={currentTableData} setSelectedUserIds={setSelectedUserIds} selectedUserIds = {selectedUserIds} currentPage= {currentPage} pageSize={PageSize}/>
                        </tbody>
                      </Table>
                      <Pagination
                        className="pagination-bar"
                        currentPage={Number(currentPage)}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                      />
                      <NotificationModal show={showPush} setShow={setPushShow} userId={userId} type={1} selectedUserIds={selectedUserIds} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default User;
