
import {
    ALL_ORDER,
    ADD_ORDER,
    EDIT_ORDER,
    DELETE_ORDER,
    SINGLE_ORDER,
    ORDER_ASSIGN_DRIVER,
    ORDER_CANCELLED
  } from "../Action/Order";

  
  
  export default function allOrder(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      case ALL_ORDER:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload

        });
      case EDIT_ORDER:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: action.payload.resMsg,
        });
        case ORDER_ASSIGN_DRIVER:
          return Object.assign({}, state, {
            isSucess:action.isSecess,
            msg: action.msg,
          });
        case ORDER_CANCELLED:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: action.msg,
        });
      case ADD_ORDER:
        return Object.assign({}, state, {
          isSecess: action.isSucess,
            msg: ''
        });
        case SINGLE_ORDER:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
              orderResult:action.payload
            });

        case DELETE_ORDER:
        return Object.assign({}, state, {
            isSucess: action.isSucess,
             
            msg: action.msg
        });
      default:
        return state;
    }
  }
  