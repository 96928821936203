import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';

let PageSize = 10;
const DriverOrdersPayment = () => {
    const { id } = useParams();
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [driverName, setDriverName] = useState("");

    const getUserPayments = async () => {
        try {
            const postData = {
                hathme: {
                    driverId: id
                }
            };
            const config = getTokenPost('post', 'driverPaymentDetailById', postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.success === 1) {
                const payments = dataResponse.data.result;
                setUserPayments(payments);
                setDriverName(payments[0].userName); // Updated variable name
            }
        } catch (error) {
            console.error("Error fetching driver payments:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUserPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userPayments.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userPayments]);

    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{driverName} Payment History Details </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Payment List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Payment List</h4> */}
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>Order ID</th>
                                                    <th>Total KMs</th>
                                                    <th>Per Km Comission</th>
                                                    <th>Base price</th>
                                                    <th>Tip</th>
                                                    <th>Total Amount</th>
                                                    <th>Status</th>
                                                    <th>Received Date</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {currentItems.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{payment.sNo}</td>
                                                        <td>{payment.orderId}</td>
                                                        <td>
                                                        {Number(payment.totalKilometers) ?  !Number.isInteger(payment.totalKilometers) && Math.floor(payment.totalKilometers.toString().split(".")[1].length) > 2 ? Number(payment.totalKilometers).toFixed(2) :payment.totalKilometers : 0 }
                                                            {/* {payment.totalKilometers} */}
                                                            </td>
                                                        <td>{payment.commissionPerKm}</td>
                                                        <td>{payment.driverBasePrice}</td>
                                                        <td>{payment.tip}</td>
                                                        <td>
                                                            {Number(payment.totalAmount) ?  !Number.isInteger(payment.totalAmount) && Math.floor(payment.totalAmount.toString().split(".")[1].length) > 2 ? Number(payment.totalAmount).toFixed(2) :payment.totalAmount : 0 }
                                                            </td>

                                                        {/* <td>{payment.amount}</td> */}

                                                        <td>
                                                            {payment.status ? (
                                                                <label className="">Done</label>
                                                            ) : null}
                                                        </td>

                                                        <td>{payment.createdAt}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userPayments.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default DriverOrdersPayment;
