import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeposit } from "../Action/Deposit";
import DepositAmount from "../Component/DepositAmount";

import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  List,
  Input,
  Button
} from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Pagination from '../Paginate/Pagination';


let PageSize = 10;
const DepositList = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.allDepositList);
  const [search, setSearch] = useState(false)
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNum, setPageNum] = useState()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
   window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
 }
 const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    dispatch(getAllDeposit(searchTerm, selectedStatusFilter));
    setSearch(false)
    setCurrentPage(searchTerm ||  selectedStatusFilter ? 1 : page ? page : 1)
  }, [search, selectedStatusFilter]);

  useEffect(() => {
    if (location.pathname === "/deposit") {
      setSearchTerm("");
      setSearch(true)
    }
  }, [location]);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);
  //

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Manage Deposit List</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Deposit List
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className="grid-margin stretch-card">
                  <div className="container-fluid">
                    <Row>
                      <Col xs={12}>
                        <Card>
                          <CardBody>
                      
                            <div className="float-right">
                              <div>
                                <Button
                                  style={{ float: "right" }}
                                  className="btn btn-success btn-sm"
                                  onClick={() => setSearch(true)}
                                >
                                  Search
                                </Button>
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="Search by Name or UTC"
                                  value={searchTerm}
                                  style={{
                                    width: "220px",
                                    border: "1px solid #333",
                                    borderRadius: "1px",
                                    float: "right",
                                    marginLeft: "10px",
                                    height: "32px",
                                  }}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="float-right  col-md-3">
                        <select
                          className="form-control float-right mr-1 h-75 w-75 border-dark rounded"
                          onChange={(e) => setSelectedStatusFilter(e.target.value)}
                          value={selectedStatusFilter}
                        >
                          <option value="">Status Filter</option>
                          <option value="1">Approved</option>
                          <option value="0">not Approved</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>

                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    {/* <th>User Type</th> */}
                                    <th>Amount</th>
                                    <th>Payment Mode</th>
                                    <th>UTR Number</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <DepositAmount
                                    currentItems={currentTableData} currentPage={currentPage} pageSize = {PageSize}
                                  /> 
                                </tbody>
                              </table>
                            </div>

                            <Pagination
                              className="pagination-bar"
                              currentPage={Number(currentPage)}
                              totalCount={payload.length}
                              pageSize={PageSize}
                              onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                              />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default DepositList;
