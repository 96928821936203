import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { updateVehicleResult, deleteVehicle } from "../Action/Vehicle";
import { confirm } from "react-confirm-box";
import Text from "./Text";
import NumberInput from "./Number";
import Form from "./Form";
import Select from "./Select";

const VehicleList = ({ currentItems, currentPage, pageSize }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [title, settitle] = useState("");
  const handleClose = () => setShow(false);

  const [updateVehicle, setUpdateubVehicle] = useState({
    vehicleId: "",
    name: "",
    commissionPerKm: "",
    driverBasePrice: "",
    BaseKilomiter: "",
    status: "",
  });

  const vehicleUpdate = (item) => {
    settitle("Update Vehicle Details");
    setUpdateubVehicle({
      name: item.name,
      commissionPerKm: item.commissionPerKm,
      driverBasePrice: item.driverBasePrice,
      BaseKilomiter: item.BaseKilomiter,
      status: item.status,
      vehicleId: item._id,
    });
    setShow(true);
  };
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const StatusOptions = [
    { label: "Active", value: 1 },
    { label: "InActive", value: 2 },
  ];

  const deleteVehicleData = async (vehicleId) => {
    const result = await confirm("Are you sure! You want to Delete.", options);
    if (result) {
      dispatch(deleteVehicle(vehicleId));
    }
  };
  const doSumbitVehicle = (event) => {
    dispatch(updateVehicleResult(event));
    setShow(false);
  };
  const initialValues = {
    commissionPerKm: updateVehicle.commissionPerKm
      ? updateVehicle.commissionPerKm
      : "",
    name: updateVehicle.name ? updateVehicle.name : "",
    driverBasePrice: updateVehicle.driverBasePrice
      ? updateVehicle.driverBasePrice
      : "",
      BaseKilomiter: updateVehicle.BaseKilomiter
      ? updateVehicle.BaseKilomiter
      : "",
    status: updateVehicle.status ? updateVehicle.status : 1,
    vehicleId : updateVehicle.vehicleId ? updateVehicle.vehicleId : "",

  };
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <tr key={item._id}>
            <td>{(currentPage - 1) * pageSize + (index + 1)}</td>
            <td>{item.name}</td>
            <td>{item.commissionPerKm}</td>
            <td>{item.driverBasePrice}</td>
            <td>{item.BaseKilomiter}</td>
            <td>
              {item.status == "1" ? (
                <label className="badge badge-success">Active</label>
              ) : (
                <label className="badge badge-danger">Deactive</label>
              )}
            </td>
            <td>
              <Button
                className="btn btn-info btn-sm"
                onClick={() => vehicleUpdate(item)}
              >
                Edit
              </Button>{" "}
              <Button
                className="btn btn-danger btn-sm"
                onClick={() => deleteVehicleData(item._id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      <Modal show={show} onHide={setShow}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(event) => doSumbitVehicle(event)}
          >
            <Text name="name" label="Vehicle Name" required />
            <NumberInput
              name="commissionPerKm"
              label="Vehicle Commission Per Km"
              required
            />
                 <NumberInput
                  name="driverBasePrice"
                  label = "Drive Base Price"
                  required
                  />
                   <NumberInput
                  name="BaseKilomiter"
                  label = "Base Kilometre"
                  required
                  />
            <Select
              name="status"
              label="Status"
              placeholder="Status"
              options={StatusOptions}
              required
            />
            <div className='d-flex justify-content-end'>
            <Button type="submit" className="btn btn-gradient-primary mr-2">Submit</Button>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VehicleList;