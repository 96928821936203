import { ALL_COUPON, ADD_COUPON,EDIT_COUPON, DELETE_COUPON } from "../Action/Coupon";


  export default function allCoupon(state = {isFetching: true,isSucess:false,payload:[]}, action) {
    switch (action.type) {
      
      case ALL_COUPON:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload
        });

        case ADD_COUPON:
          return Object.assign({}, state, {
            isSecess: action.isSucess,
              msg: ''
          });

          case EDIT_COUPON:
            return Object.assign({}, state, {
              isSucess:action.isSecess,
              msg: action.message,
            });

            case DELETE_COUPON:
              return Object.assign({}, state, {
                  isSucess: action.isSucess,
                  msg: action.message
              });
      
   
      default:
        return state;
    }
  }