import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ImageShowModal from "./ImageShowModal";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CategoryListTool = ({ currentItems }) => {
    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");
    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
    }

    return (
        <>
            {currentItems && currentItems.map((item) => (
                <tr key={item._id}>
                    <td>{item.name}</td>
                    <td >
                        <LazyLoadImage src={item.image}
                            style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                            alt={item.name}
                            onClick={() => { showImage(item.image) }}
                        />
                    </td>
                    <td><Link to={'/category-form/' + item._id} className='btn btn-info btn-sm'>Add Form</Link></td>
                </tr>
            ))}
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
        </>
    )

}

export default CategoryListTool;