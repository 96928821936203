import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import {
  Col,
  Row,
  Card,
  CardBody,
  List,
  Button,
} from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import Text from "../Component/Text";
import Select from "../Component/Select";
import Form from "../Component/Form";
import NumberInput from "../Component/Number";

const Setting = () => {
  const { settingId } = useParams();
  const [SectionBlok, setSectionBlok] = useState(1);
  const [updateSetting, setUpdateSetting] = useState({});
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    if (settingId) {
      getSettingDetails(settingId);
    }
  }, [settingId]);
  const getSettingDetails = async (settingId) => {
    const postData = {
      hathme: {
        settingId: settingId,
      },
    };
    const config = getTokenPost("post", "getProjectSettingDetails", postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      const getData = payload.result;
      setUpdateSetting(getData);
      setBase64Image(getData.depositQRCode);
      setSectionBlok(getData.userType);
    }
  };
  const {
    _id,
    iosVersion,
    showPopupIos,
    iosHardReset,
    appMaintenance,
    showPopupAndroid,
    androidHardReset,
    androidVersion,
    merchantCommision,
    userCommision,
    rateUserValue,
    minimumShippingCharge,
    returnDays,
    upiOnHold,
    bankTransferOnHold,
    depositOnHold,
    onlineOnHold,
    depositUPI,
    depositQRCode,
    depositAccountHolderName,
    depositBankName,
    depositBankAccountNumber,
    depositBankIfscCode,
    cancelTime,
    statusCOD,
    statusOnlinePayment,
    statusWallet,
    taxesAndCharges,
    reelsLike,
    reelsComment,
    reelsShare,
    reelsView,
    videoLike,
    videoComment,
    videoView,
    videoShare,
    withdrawalOnHold,
    driverCodAmountLimit,
  } = updateSetting;
  const initialValues = {
    _id: _id ? _id : "",
    iosVersion: iosVersion ? iosVersion : 0,
    showPopupIos: showPopupIos ? showPopupIos : 0,
    iosHardReset: iosHardReset ? iosHardReset : 0,
    appMaintenance: appMaintenance ? appMaintenance : 0,
    showPopupAndroid: showPopupAndroid ? showPopupAndroid : 1,
    androidHardReset: androidHardReset ? androidHardReset : 1,
    androidVersion: androidVersion ? androidVersion : "",
    merchantCommision: merchantCommision ? merchantCommision : "",
    userCommision: userCommision ? userCommision : "",
    rateUserValue: rateUserValue ? rateUserValue : "",
    minimumShippingCharge: minimumShippingCharge ? minimumShippingCharge : 0,
    returnDays: returnDays ? returnDays : 0,
    upiOnHold: upiOnHold ? upiOnHold == "Non Hold" ? '0' : '1'  : '0',
    bankTransferOnHold: bankTransferOnHold ? bankTransferOnHold  : '0',
    depositOnHold: depositOnHold ? depositOnHold == "Non Hold" ? '0' : '1'  : '0',
    onlineOnHold: onlineOnHold ? onlineOnHold : '0',
    depositUPI: depositUPI ? depositUPI : "",
    depositQRCode: "",
    depositAccountHolderName: depositAccountHolderName
      ? depositAccountHolderName
      : "",
    depositBankName: depositBankName ? depositBankName : "",
    depositBankAccountNumber: depositBankAccountNumber
      ? depositBankAccountNumber
      : "",
    depositBankIfscCode: depositBankIfscCode ? depositBankIfscCode : "",
    cancelTime: cancelTime ? cancelTime : "",
    statusCOD: statusCOD ? statusCOD : "",
    statusOnlinePayment: statusOnlinePayment ? statusOnlinePayment : "",
    statusWallet: statusWallet ? statusWallet : "",
    taxesAndCharges: taxesAndCharges ? taxesAndCharges : 0,
    reelsLike: reelsLike ? reelsLike : 0,
    reelsComment: reelsComment ? reelsComment : 0,
    reelsShare: reelsShare ? reelsShare : 0,
    reelsView: reelsView ? reelsView : 0,
    videoLike: videoLike ? videoLike : 0,
    videoComment: videoComment ? videoComment : 0,
    videoView: videoView ? videoView : 0,
    videoShare: videoShare ? videoShare : 0,
    withdrawalOnHold: withdrawalOnHold ? withdrawalOnHold : '0',
    driverCodAmountLimit: driverCodAmountLimit ? driverCodAmountLimit : 0,
  };
  const updateSettingData = async (values) => {
    const postData = {
      hathme: values,
    };
    const config = getTokenPost(
      "post",
      "updateProjectSettingDetails",
      postData
    );
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success == 1) toast.success(payload.resMsg);
  };
  const doSubmitRecords = (event) => {
    updateSettingData(event);

  };

  const options = [
    { label: "No", value: 0 },
    { label: "Yes", value: 1 },
  ];
  const options2 = [
    { label: "No", value: 2 },
    { label: "Yes", value: 1 },
  ];
  
  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">
              {" "}
              {SectionBlok == 1
                ? "Customer"
                : SectionBlok == 2
                ? "Merchant"
                : "Driver"}{" "}
              Manage Setting
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {SectionBlok == 1
                    ? "Customer"
                    : SectionBlok == 2
                    ? "Merchant"
                    : "Driver"}{" "}
                  Manage Setting{" "}
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <Form
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(event) => doSubmitRecords(event)}
                  >
                    <Row>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            label="Show Popup IOS"
                            name="showPopupIos"
                            options={options}
                            required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <NumberInput
                            name="iosVersion"
                            placeholder="Enter ios Version"
                            label="Ios version"
                            required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="iosHardReset"
                            label="Ios Hard Reset"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="showPopupAndroid"
                            label="Show Popup Android"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            label="Android Hard Reset"
                            name="androidHardReset"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <NumberInput
                            label="Android Version"
                            name="androidVersion"
                            required
                          />
                        </Col>
                      </Col>
                    </Row>
                    {/*  */}
                    <Row>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="bankTransferOnHold"
                            options={options}
                            label={"Bank Transfer On Hold"}
                              required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="onlineOnHold"
                            label=" Online On Hold"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="upiOnHold"
                            options={options}
                            label="UPI On Hold"
                              required
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="appMaintenance"
                            label="App Maintenance"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>

                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            label="Deposit On Hold"
                            name="depositOnHold"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                      <Col md={4}>
                        <Col className="form-group">
                          <Select
                            name="withdrawalOnHold"
                            label="Withdrawal On Hold"
                            options={options}
                              required
                          />
                        </Col>
                      </Col>
                      {SectionBlok == 3 && (
                        <Col className="form-group">
                          <Col md={4}>
                            <NumberInput
                              label="Driver Cod Amount Limit"
                              name="driverCodAmountLimit"
                              required
                            />
                          </Col>
                        </Col>
                      )}
                    </Row>

                    {SectionBlok == 1 ? (
                      <>
                        <Row>
                          <Col md={4}>
                            <Col className="form-group">
                              <Text
                                label="Deposit UPI"
                                name="depositUPI"
                                required
                              />
                              {base64Image ? (
                                <>
                                  <img src={base64Image} alt={"QR Code"}></img>
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Col className="form-group">
                              <h3 className="page-title">Payment Via Type</h3>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Col className="form-group">
                              <Select
                                name="statusCOD"
                                label="COD"
                                options={options2}
                                  required
                              />
                            </Col>
                          </Col>
                          <Col md={4}>
                            <Col className="form-group">
                              <Select
                                label={"Online Payment"}
                                name="statusOnlinePayment"
                                options={options2}
                                  required
                              />
                            </Col>
                          </Col>
                          <Col md={4}>
                            <Col className="form-group">
                              <Select
                                name="statusWallet"
                                label="From Wallet"
                                options={options2}
                                  required
                              />
                            </Col>
                          </Col>
                        </Row>
                        {/*  */}
                        <Row>
                          <Col md={12}>
                            <Col className="form-group">
                              <h3 className="page-title">
                                Product Return Based Settings
                              </h3>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Col className="form-group">
                              <NumberInput
                                label="Taxes And Charges"
                                name="taxesAndCharges"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={4}>
                            <Col className="form-group">
                              <NumberInput
                                name="returnDays"
                                label="Return Number of Days"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={4}>
                            <Col className="form-group">
                              <NumberInput
                                label="Order Cancel Time"
                                name="cancelTime"
                                required
                              />
                            </Col>
                          </Col>
                        </Row>
                        {/* Update  */}
                        <Row>
                          <Col md={12}>
                            <Col className="form-group">
                              <h3 className="page-title">
                                Video & Reels Based Settings
                              </h3>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="videoLike"
                                label="Video Like Point"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                label="Video Comment Point"
                                name="videoComment"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="videoView"
                                label="Video View Point"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="videoShare"
                                label="Video Share Point"
                                required
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="reelsLike"
                                label="Reels Like Point"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="reelsComment"
                                label="Reels Comment Point"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                name="reelsView"
                                label="Reels View Point"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={3}>
                            <Col className="form-group">
                              <NumberInput
                                label="Reels Share Point"
                                name="reelsShare"
                                required
                              />
                            </Col>
                          </Col>
                        </Row>
                        {/* Closed */}
                        <Row>
                          <Col md={12}>
                            <Col className="form-group">
                              <h3 className="page-title">Bank Details</h3>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Col className="form-group">
                              <Text
                                name="depositAccountHolderName"
                                label="Account Holder Name"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={6}>
                            <Col className="form-group">
                              <Text
                                name="depositBankName"
                                label="Bank Name"
                                required
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Col className="form-group">
                              <NumberInput
                                label="Bank Account Number"
                                name="depositBankAccountNumber"
                                required
                              />
                            </Col>
                          </Col>
                          <Col md={6}>
                            <Col className="form-group">
                              <Text
                                name="depositBankIfscCode"
                                label="Bank Ifsc Code"
                                required
                              />
                            </Col>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}

                    <Button
                      type="submit"
                      className="btn btn-gradient-primary mr-2"
                    >
                      Update Setting
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default Setting;
