import React, { useEffect, useState,useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch,useSelector } from 'react-redux';
import Pagination from '../Paginate/Pagination';

import {
  Col,
  Row,Card,CardBody,
  Table,
  List,
  Input,
  Button
} from "reactstrap";
import loaderGif from "../assets/loader.gif";
import { getAllCoupon } from '../Action/Coupon';
import CouponList from '../Component/CouponList';

let PageSize = 10;
const Coupon = () => {
    const dispatch = useDispatch();
    const { payload, isFetching} = useSelector((state) => state.coupon);
    const [search, setSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [pageNum, setPageNum] = useState()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const page = queryParams.get('page');
    if(pageNum){
      queryParams.set('page', pageNum);
      window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
    }
   const [currentPage, setCurrentPage] = useState(page ? page : 1);
    useEffect(()=>{
        dispatch(getAllCoupon(searchTerm));
        setSearch(false)
        setCurrentPage(searchTerm ? 1 : page ? page : 1);
    }, [isFetching, search]);

    useEffect(() => {
      if (location.pathname === "/coupon") {
        setSearchTerm("");
        setSearch(true)
      }
    }, [location]);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,payload]);


     return (
        <>
    { isFetching ?  (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loaderGif} alt={'logo'} />
        </div>
    ) : (
  
        <Layout>
          <div className='content-wrapper'>
            <Row className='page-header'>
              <h3 className="page-title">Manage Coupon List
                </h3>
                <nav aria-label="breadcrumb">
                    <List tag="ol" className='breadcrumb'>
                      <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Manage Coupon List</li>
                    </List>
                </nav>
              </Row>
            <Row>
              <Col className='grid-margin stretch-card'>
              <div className="container-fluid">
                    <Row>
                      <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className='d-flex justify-content-between'>
                    <div>
                          <Link to={'/add-coupon'} style={{float:"right"}} className='btn btn-info btn-sm' >Add New Coupon</Link>
                          </div>
                    <div className=''>
                    <Button style={{
                         float: "right"
                        }} className='btn btn-success btn-sm' onClick={() => setSearch(true)} >Search</Button> 
                        <Input
                      className="form-control"
                      type="text"
                      placeholder="Search by Category & Coupon"
                      style={{
                        width: "220px",
                          border: "1px solid #333",
                          borderRadius: "1px",
                          float: "right",
                          marginLeft: "10px",
                          height:"32px"
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      /> 
                    </div>
                    
                          
                    </div>
                    <div className="table-responsive">
                    <table className="table table-striped">
                    
                    <thead>
                      <tr>
                    
                        <th>S.No</th>
                        <th>Category Name</th>
                        <th>Coupon Code</th>
                        <th>Expiry Date</th>
                        <th>Max Discount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Status Action</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                        <CouponList currentItems={currentTableData} currentPage= {currentPage} pageSize={PageSize}></CouponList>
                      </tbody>
                      </table>
                            </div>
                  <Pagination
                      className="pagination-bar"
                      currentPage={Number(currentPage)}
                      totalCount={payload.length}
                      pageSize={PageSize}
                      onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                      />
                  </CardBody>
                </Card>
                </Col>
                    </Row>
                  </div>
              </Col>
            </Row>
          </div>
          {/* </Col>
              </Row>
            </div> */}
         </Layout>
)}
       </>
     )
 }
 export default Coupon;