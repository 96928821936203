import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";

export const ADD_PRODUCT = "ADD_CATEGORY";
export const PRODUCT_MODEL_GET = "REGISTER_SUCCESS";
export const EDIT_PRODUCT = "EDIT_CATEGORY";
export const SINGLE_PRODUCT = "SINGLE_PRODUCT";

export function getProductModelStructure(userId) {
  return async (dispatch, getState) => {
    const config = getToken('get','getproductModelStructure?userId='+userId);
    const responsive = await axios(config);
    const modelData = responsive.data.hathme;
    const payload = modelData.data;
    if (modelData.resCode == 1) {
      dispatch({ type: PRODUCT_MODEL_GET, payload, isFetching: false });
    }else{
      dispatch({ type: PRODUCT_MODEL_GET,payload:[], isFetching: false});
    }
  }
}

export function addProductModel(payloadData) {
  return async (dispatch) => {
    const postData = {"hathme":payloadData} 
    const config = getTokenPost('post','addProduct',payloadData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.resCode == 1) {
      toast.success(payload.resMsg);
    }else if(payload.resCode === 0){
      toast.error(payload.resMsg);
    }
  };
}
export function updateProductModel(payloadData) {
  return async (dispatch) => {
    const postData = {"hathme":payloadData} 
    const config = getTokenPost('post','updateProduct',payloadData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.resCode == 1) {
      toast.success(payload.resMsg);
    }else if(payload.resCode === 0){
      toast.error(payload.resMsg);
    }
  };
}
// export function getProductDetails(payloadData) {
//   return async (dispatch) => {
//     const postData = {"hathme":payloadData} 
//     const config = getTokenPost('post','getProductDetails',payloadData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({type: SINGLE_PRODUCT,message: payload.resMsg,isSucess: false,});
//       toast.success(payload.resMsg);
//     }else if(payload.success === 0){
//       toast.error(payload.resMsg);
//     }
//   };
// }

// export function deleteCategory(id,status) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme": {
//         "categoryId":id,
//         status:status
//       }
//     } 
//     const config = getTokenPost('post','deleteCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({
//         type: DELETE_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//       dispatch(getAllCategory());
//     }
//   };
// }


// export function editCategory(payloadData) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme":payloadData
//     } 
//     const config = getTokenPost('post','updateCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({
//         type: DELETE_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//       dispatch(getAllCategory());
//     }else if(payload.success === 0){
//       toast.error(payload.resMsg);
//     }
//   };
// }