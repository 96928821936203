import React, { useEffect, useState, useMemo } from 'react';
import Layout from "./Layout/Layout";
import loaderGif from "../assets/loader.gif";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { getTokenPost } from "../services/Token";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from '../Component/SearchInput';

const RedPoint = () => {
    const [redPointData, setRedPointData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [userName, setUserName] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)
    
    let PageSize = 10;

    const fetchRedPointData = async (searchTerm='') => {
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('get', `getRedPointUserLog/${id}?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;

            if (dataResponse.success === 1) {
                // Extract user's name and red point data
                const { senderName, redPointData } = dataResponse.data;
                setUserName(senderName);
                setRedPointData(redPointData);
                setLoading(false);;
            }
        } catch (error) {
            console.error("Error fetching red point data:", error.message);
        }
    };

    useEffect(() => {
        fetchRedPointData(searchTerm);
        setSearch(false)
        // eslint-disable-next-line
    }, [Search]);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return redPointData.slice(firstPageIndex, lastPageIndex);
      }, [currentPage, redPointData]);
      const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      };
    return (
        <Layout>
            <div className='content-wrapper'>
                <Row className='page-header'>
                    <h3 className="page-title">{userName} Red Point History </h3>
                    <nav aria-label="breadcrumb">
                        <List tag="ol" className='breadcrumb'>
                            <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Red Point List</li>
                        </List>
                    </nav>
                </Row>

                <Row>
                    <Col className='grid-margin stretch-card'>
                        <Card>
                            <CardBody>
                                {/* <h4 className="card-title">Manage Red Points List</h4> */}
                                <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search By Name"
                      onClick ={() => setSearch(true)}
                      />
                                {loading ? (
                                    <img src={loaderGif} alt="Loading..." />
                                ) : (
                            <div className="table-responsive">

                                    <Table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Sender Name</th>
                                                <th>Receiver Name</th>
                                                <th>Credit/Debit</th>
                                                <th>Points</th>
                                                <th>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.senderName}</td>
                                                    <td>{item.receiverName}</td>

                                                    <td>
                                                        {item.creditOrDebit === 1 ? (
                                                            <label className="badge badge-success">Credit</label>
                                                        ) : (
                                                            <label className="badge badge-warning">Debit</label>
                                                        )}
                                                    </td>

                                                    <td>{ !Number.isInteger(item.quantity) && Math.floor(item.quantity.toString().split(".")[1].length) > 4 ? item.quantity.toFixed(4) : item.quantity }</td>
                                                    <td>{item.createdAt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                            </div>

                                )}
                             <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={redPointData.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default RedPoint;
