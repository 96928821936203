import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import { Link, useParams } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import axios from 'axios';
import { getTokenPost } from '../services/Token';

let PageSize = 10;

const DriverOrders = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [driverOrders, setDriverOrders] = useState([]);
    const [userName, setUserName] = useState("");

    const { id } = useParams();

    // Function to fetch driver orders
    const fetchDriverOrders = async () => {
        try {
            const postData = {
                driverId: id
            };

            const config = getTokenPost('GET', `getDriverOrders/${id}`, postData);
            const response = await axios(config);
            const orderData = response.data.hathme.data;
            const driverName = orderData[0]?.userName || ""; // Extract the userName from the first order

            if (Array.isArray(orderData) && orderData.length > 0) {
                setDriverOrders(orderData);
                setUserName(driverName); // Set the userName
            } else {
                setDriverOrders([]); // an empty array if no orders are found
                setUserName(""); // Set the userName to an empty string
            }

        } catch (error) {
            console.error("Error fetching driver orders:", error.message);
        }
        setLoading(false);
    };

    const [selectedStatusFilter, setSelectedStatusFilter] = useState('');

    const statusFilterFunction = (order) => {
        if (!selectedStatusFilter || selectedStatusFilter === 'all') {
            return true; // No filter selected or "All" selected, show all orders
        }

        // Filter orders based on the selected status
        switch (selectedStatusFilter) {
            case 'pending':
                return order.orderStatus === '1';
            case 'accepted':
                return order.orderStatus === '2';
            case 'cancel':
                return order.orderStatus === '3';
            case 'pickup':
                return order.orderStatus === '4';
            case 'picked':
                return order.orderStatus === '5';
            case 'delivered':
                return order.orderStatus === '6';
            default:
                return false; // Return false for all other cases
        }
    };

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        return driverOrders
            .filter(statusFilterFunction)
            .slice(firstPageIndex, lastPageIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, driverOrders, selectedStatusFilter]);

    const handleStatusFilterChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedStatusFilter(selectedValue);
    };

    useEffect(() => {
        fetchDriverOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatusFilter]);

    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{userName} Driver Orders</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Order List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Driver Order List</h4> */}

                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <>
                                            <div className="float-right px-5">
                                                <select className="form-control" style={{ backgroundColor: '#f5f5f5', color: 'black', border: "1px solid #333", borderRadius: "5px", width: "150%"  }} onChange={handleStatusFilterChange} value={selectedStatusFilter}>
                                                    <option value="">Status Filter</option>
                                                    <option value="accepted">Accepted</option>
                                                    <option value="picked">Picked up</option>
                                                    <option value="delivered">Delivered</option>
                                                </select>
                                            </div>

                                            <Table className="table table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th >S No</th>
                                                        <th style={{ width: "15%" }}>Order ID</th>
                                                        <th style={{ width: "15%" }}>Payment Method</th>
                                                        <th style={{ width: "15%" }}>Status</th>
                                                        <th style={{ width: "15%" }}>Payment Status</th>
                                                        <th style={{ width: "15%" }}>Total Amount</th>
                                                        <th style={{ width: "15%" }}>Order Date</th>
                                                        <th style={{ width: "15%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((order) => (
                                                        <tr key={order.order_Id}>
                                                            <td>{order.sNo}</td>
                                                            <td>{order.orderId}</td>

                                                            <td><label className={(order.paymentMethod === 1 ? "badge badge-info" : (order.paymentMethod === 2 ? "badge badge-info" : "badge badge-info"))} >{(order.paymentMethod === 1 ? (<span>Wallet</span>) : (order.paymentMethod === 2 ? (<span>Online</span>) : (<span>COD</span>)))}</label></td>

                                                            <td>
                                                                {order.orderStatus === '1' ? (
                                                                    <label className="badge badge-warning">Pending</label>
                                                                ) : (
                                                                    order.orderStatus === '2' ? (
                                                                        <label className="badge badge-success">Accepted</label>
                                                                    ) : (
                                                                        order.orderStatus === '3' ? (
                                                                            <label className="badge badge-danger">{order.cancelBy ? "Rejected" : "Cancel"}</label>
                                                                        ) : (
                                                                            order.orderStatus === '4' ? (
                                                                                <label className="badge badge-info">Driver reached pickup location</label>
                                                                            ) : (
                                                                                order.orderStatus === '5' ? (
                                                                                    <label className="badge badge-info">Picked up</label>
                                                                                ) : (<label className="badge badge-success">Delivered</label>)
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                                }
                                                            </td>

                                                            <td>
                                                                {order.paymentStatus === 1 ? (
                                                                    <label className="badge badge-success">Paid</label>
                                                                ) : (
                                                                    <label className="badge badge-danger">Not Paid</label>
                                                                )}
                                                            </td>

                                                            <td>{order.finalAmount}</td>
                                                            <td>{order.createdAt}</td>

                                                            <td>
                                                                <Link to={'/order-details/' + order.order_Id} className="btn btn-info btn-sm">View</Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    )}

                                    {selectedStatusFilter === "" ? (
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={driverOrders.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    ) : null}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default DriverOrders;
