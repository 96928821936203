import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ListGroupItem, ListGroup} from "reactstrap";

function StaticModal({show,setShow,title,user}) { 
  
    const handleClose = () => setShow(false);

    return (
      <>
        <Modal show={show} onHide={setShow}>
          <Modal.Header>
             <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
           <Modal.Body>
           <ListGroup>
                <ListGroupItem disabled>Name: {user.name} </ListGroupItem>
                <ListGroupItem disabled>
                 Email: {user.email}
                </ListGroupItem>
                     <ListGroupItem disabled> Phone: {user.phone}
                </ListGroupItem>
                     <ListGroupItem disabled>Join Date: {user.joinDate}</ListGroupItem>
                </ListGroup>
            </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
      </>
    );
  }
export default StaticModal;