import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllMerchant,categoriesListGet } from "../Action/Merchant";
import MerchantList from "../Component/MerchantList";
import { Col, Row, Card, CardBody, Table, List,Button } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Pagination from '../Paginate/Pagination';
import NotificationModal from "../Component/NotificationModal";
import SearchInput from "../Component/SearchInput";

let PageSize = 10;
const Merchant = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.merchant);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [categoryFilter, setSelectedCategoryFilter] = useState("");
  const [Search, setSearch] = useState(false)
  const [showPush, setPushShow] = useState(false);
  const [pageNum, setPageNum] = useState()
  const [userId, setUserId] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
 const [currentPage, setCurrentPage] = useState(page ? page : 1);

  useEffect(() => {
    dispatch(getAllMerchant())
  }, []);

  useEffect(() => {
        setCurrentPage(searchTerm||categoryFilter|| selectedStatusFilter ? 1 :  page ? page : 1);
    dispatch(getAllMerchant(searchTerm,categoryFilter, selectedStatusFilter));
    setSearch(false)
    // eslint-disable-next-line
  }, [Search,categoryFilter, selectedStatusFilter ]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);


  // Reset searchTerm to /merchant
  useEffect(() => {
    if (location.pathname === "/merchant") {
      setSearchTerm("");
      setSelectedStatusFilter("")
      setSelectedCategoryFilter("")
      setSearch(true)
    }
  }, [location]);

  const handleSearchChange = (e) => {
    const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
    setSearchTerm(result);
  };

  // New event handler to handle status filter change
  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when changing the filter
  };
  const handleCategoryFilterChange = (e) => {
    setSelectedCategoryFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when changing the filter
  };
  const sendNotification = (userId) =>{
    setUserId(userId);
    setPushShow(true);
  }
  const groupbyNotifications = (data) =>{
    setUserId(data);
    setPushShow(true);
  }
  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Merchant Users</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Merchant List
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className="grid-margin stretch-card">
                  <Card>
                    <CardBody className="p-3 table-responsive">
                 
                      <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search By Name Email or Number"
                      onClick ={() => setSearch(true)}
                      />

                      {/* Dropdown for status filter */}
                      <div className="float-right">
                          <select
                            className="form-control rounded border-dark w-auto h-auto pb-2"
                            style={{
                              backgroundColor: '#f5f5f5',
                            }}
                            onChange={handleCategoryFilterChange}
                            value={categoryFilter}
                          >
                          <option value="">Status Category</option>
                          { categoriesListGet.length > 0 && categoriesListGet.map((item)=>(
                             <option value={item._id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="float-right" style={{marginRight:'10px'}}>
                          <select
                            className="form-control rounded border-dark w-auto h-auto pb-2"
                            style={{
                              backgroundColor: '#f5f5f5',
                            }}
                            onChange={handleStatusFilterChange}
                            value={selectedStatusFilter}
                          >
                          <option value="">Status Filter</option>
                          <option value="1"> Profile Verified</option>
                          <option value="0"> Pending Profile</option>
                          <option value="2"> Rejected Profile</option>
                        </select>
                      </div>
                      <Button className='btn btn-warning btn-sm' onClick={() => sendNotification(2)}>Send Notification</Button> &nbsp;
                      <Button className='btn btn-warning btn-sm' onClick={() => groupbyNotifications(0)}>GroupBy Notifications</Button>

                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th >S.No</th>
                            <th >Name</th>
                            <th>Email</th>
                            <th >Number</th>
                            <th >Bussiness Name</th>
                            <th> Category Name</th>
                            <th>Commission</th>
                            <th>Status</th>
                            <th >Profile Status</th>
                            <th >Image</th>
                            <th >Register Date</th>
                            <th >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <MerchantList currentItems={currentTableData} currentPage= {currentPage} pageSize={PageSize} setSelectedUserIds={setSelectedUserIds} selectedUserIds = {selectedUserIds} />
                        </tbody>
                      </Table>
                      <NotificationModal show={showPush} setShow={setPushShow} userId={userId} type={2} selectedUserIds={selectedUserIds}/>
                      <Pagination
                        className="pagination-bar"
                        currentPage={Number(currentPage)}
                        // totalCount={payload.length}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                        />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default Merchant;