import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleList,addVehicle } from "../Action/Vehicle";
import VehicleListComponent from '../Component/VehicleList';
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, List, Button, Input } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Modal from 'react-bootstrap/Modal';
import Form from '../Component/Form';
import Text from '../Component/Text';
import Select from '../Component/Select';
import NumberInput from "../Component/Number";

let PageSize = 10;
const VehicleList = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.allVehicleList);
  const [search, setSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNum, setPageNum] = useState()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
 const [currentPage, setCurrentPage] = useState(page ? page : 1);
  useEffect(() => {
      dispatch(getVehicleList(searchTerm));
      setSearch(false)
      setCurrentPage(searchTerm ? 1 : page ? page : 1)
      // eslint-disable-next-line
  }, [search]);
  useEffect(() => {
    if (location.pathname === "/vehicle-list") {
      setSearchTerm("");
      setSearch(true)
    }
  }, [location]);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  //  Add New Vechicle

  const [show, setShow] = useState(false);
  const [title, settitle] = useState("");
  const handleClose = () => setShow(false);
  const vechicleAdd = () => {
    settitle("Add New Vehicle");
    setShow(true);
  }

  const handleSubmit = (event) => {
    dispatch(addVehicle(event));
    setShow(false);
  }

  const initialValues = {
    commissionPerKm:"",
    name :"",
    driverBasePrice: "",
    BaseKilomiter: "",
    status : 1
  }

  const options = [
    {label: "Active", value: 1},
    {label: "InActive", value: 2}
  ]
  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt={'logo'} />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Vehicle List
                </h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Manage Vehicle List</li>
                  </List>
                </nav>
              </Row>
              <Row>
              <Col className="grid-margin stretch-card">
                  <div className="container-fluid">
                  <Card>
                    <CardBody>
                      <div className='d-flex justify-content-between'>
                      <div>
                      <Button style={{ float: "right" }} className='btn btn-info btn-sm' onClick={vechicleAdd}>Add New Vehicle</Button>
                      </div>
                    <div className=''>
                    <Button style={{
                         float: "right"
                        }} className='btn btn-success btn-sm' onClick={() => setSearch(true)} >Search</Button> 
                        <Input
                      className="form-control w-auto rounded border-dark floart-right ml-2 "
                      type="text"
                      placeholder="Search by Name"
                      style={{
                          height:"32px"
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      /> 
                    </div>
                      
                    </div>     
                    <div className="table-responsive">

                      <table className="table table-striped">
                        <thead>
                          <tr>

                            <th>S No</th>
                            <th>Vehicle Name</th>
                            <th>Commission Per Km</th>
                            <th>Driver Base Price</th>
                            <th>Base Kilomiter</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <VehicleListComponent currentItems={currentTableData} currentPage = {currentPage} pageSize = {PageSize}/>
                        </tbody>
                      </table>
                      </div>
                      <Pagination
                        className="pagination-bar"
                        currentPage={Number(currentPage)}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                       />
                    </CardBody>
                  </Card>
               </div>
                </Col>
              </Row>
            </div>
          </Layout>
          <Modal show={show} onHide={setShow}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
              <Modal.Body>
                <Form
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Text
                  name="name"
                  label = "Vehicle Name"
                  required
                  />
                  <NumberInput
                  name= "commissionPerKm"
                  label="Vehicle Commission Per Km"
                  required
                  />
                  <NumberInput
                  name="driverBasePrice"
                  label = "Drive Base Price"
                  required
                  />
                  <NumberInput
                  name="BaseKilomiter"
                  label = "Base Kilometre"
                  required
                  />
                  <Select
                  name = "status"
                  label = "Status"
                  placeholder = 'Status'
                  options = {options}
                  required
                  />
                  <div className='d-flex justify-content-end'>
                <Button type="submit" className="btn btn-gradient-primary mr-2">Submit</Button>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
          </Modal>
        </>)}
    </>
  )
}

export default VehicleList;