import { toast } from "react-toastify";
import axios from "axios";
import { BaseURL } from "../Action/BaseURL";
import { getToken, getTokenPost } from "../services/Token";
export const ADD_MERCHANT = "ADD_MERCHANT";
export const DELETE_MERCHANT = "DELETE_SUCCESS";
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const ALL_MERCHANT = "ALL_MERCHANT";
export const SINGLE_MERCHANT = "SINGLE_MERCHANT";
export const UPDATE_MERCHANT_DETAIL = "UPDATE_MERCHANT_DETAIL";
export let categoriesListGet = [];


export function addMerchant(payload) {
  return async (dispatch, getState) => {
    const responsive = await axios.post(`${BaseURL}/AddCategory`, payload);
    if (responsive.data.hathme.resCode === 1) {
      dispatch({ type: ADD_MERCHANT, payload, isSucess: true });
      toast.success("Successfully updated");
      dispatch(getAllMerchant());
    }
  };
}

export function deleteMerchant(id) {
  return async (dispatch) => {
    const responsive = await axios.delete(`${BaseURL}/DeleteMerchant/${id}`);
    const merchantList = responsive.data.hathme;
    // const payload = merchantList.data;
    if (merchantList.resCode === 1) {
      dispatch({
        type: DELETE_MERCHANT,
        message: "merchant has been deleted",
        isSucess: true,
      });
      dispatch(getAllMerchant());
    }
  };
}


export function editCategory(data) {
  let payload = data;
  let id = payload.hathme.id;
  return async (dispatch, getState) => {
    const responsive = await axios.put(`${BaseURL}/updateCategory/${id}`, data);
    const categoriesList = responsive.data.hathme;
    const payload = categoriesList.data;
    if (categoriesList.resCode === 1) {
      dispatch({ type: EDIT_MERCHANT, payload, isSucess: true });
      dispatch(getAllMerchant());
    }
  };
}

export function getSingleMerchant(marchentId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "merchantId": marchentId
      }
    }
    const config = getTokenPost('post', 'MerchantDetailById', postData);
    const responsive = await axios(config);
    const Merchant = responsive.data.hathme;
    const payload = Merchant.data;
    if (Merchant.success === 1) {
      dispatch({ type: SINGLE_MERCHANT, payload, isFetching: false });
    }
  };
}

export function getAllMerchant(searchTerm='',category='',isProfileVerified='' ) {
  return async (dispatch, getState) => {
    const config = getToken('get', 'GetAllMerchant?searchTerm='+searchTerm+'&categoryId='+category+'&isProfileVerified='+isProfileVerified);
    const responsive = await axios(config);
    const MerchantList = responsive.data.hathme;
    const payload = MerchantList.data;
    categoriesListGet = MerchantList.category;
    if (MerchantList.success === 1) {
      dispatch({ type: ALL_MERCHANT, payload, isFetching: false });
    } else {
      dispatch({ type: ALL_MERCHANT, isFetching: false });
    }
  };
}

export function approvedMarchant(marchentId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "merchantId": marchentId,
        "adminCommission": 0,
      }
    }
    const config = getTokenPost('post', 'ApproveMerchant', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: DELETE_MERCHANT,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllMerchant());
    }
  };
}

export function RejectMerchant(marchentId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "merchantId": marchentId,
      }
    }
    const config = getTokenPost('post', 'RejectMerchant', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: DELETE_MERCHANT,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllMerchant());
    }
  };
}

export function onlineMerchants() {
  return async (dispatch, getState) => {
    const config = getToken('get', 'onlineMerchants');
    const responsive = await axios(config);
    const MerchantList = responsive.data.hathme;
    const payload = MerchantList.data;
    categoriesListGet = MerchantList.category;
    if (MerchantList.resCode === 1) {
      dispatch({ type: ALL_MERCHANT, payload, isFetching: false });
    } else {
      dispatch({ type: ALL_MERCHANT, isFetching: false });
    }
  };
}



