import React, { useEffect, useState } from "react";
import loaderGif from "../assets/loader.gif";
import { Link, useParams } from 'react-router-dom';
import { getSingleOrder,CancelOrder } from '../Action/Order';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List,Button } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import ImageShowModal from "../Component/ImageShowModal";
import DriverShowModal from "../Component/DriverShowModal";
import { confirm } from "react-confirm-box";
import paymentMethod from "../Action/paymentMethod";


const OrderDetails = () => {
  const [ImageShow, setImageShow] = useState(false);
  const [driverShow, setDriverShow] = useState(false);
  const [ImgSrc, setImgSrc] = useState("");
  const [driverList, setdriverList] = useState([]);
  const [orderId, setOrderId] = useState('');
  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  }
  const showDriverList = (items,orderId) => {
    setdriverList(items);
    setOrderId(orderId);
    setDriverShow(true);
  }


  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderResult } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getSingleOrder(id));
    // eslint-disable-next-line 
  }, []);
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  const cancleOrder = async (orderId) => {
    const result = await confirm("Are you sure! You want to cancle order.", options);
    if (result) {
      dispatch(CancelOrder(orderId));
    }
  };

  return (
    <>
      {orderResult === undefined ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt='logo' />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Order Detail
                </h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Orders Detail</li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className='grid-margin stretch-card'>
                  <Card>

                    <CardBody>
                      <Row>
                        <Col style={{textAlign:'right'}}>
                        {orderResult.assignDriverDetails == '' && orderResult.orderStatus == '2' ? (
                                <>
                                <Button className="btn btn-success btn-sm"  onClick={() => { showDriverList(orderResult.drivers,orderResult._id) }} >Driver List</Button>
                                </>
                              ) : '' }
                          
                           &nbsp;&nbsp;
                           {orderResult.orderStatus == '2' ? (
                                <>
                                <Button className="btn btn-success btn-sm" onClick={() => cancleOrder(orderResult._id)}>Cancel Order</Button>
                                </>
                              ) : '' }
                        </Col>
                      </Row>
                    
                      {/* <h4 className="card-title">Manage Orders Detail</h4> */}
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Customer Name</th>
                            <th>Customer Number</th>
                            <th>Payment Method</th>
                            <th>Status</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={orderResult._id}>
                            <td>{orderResult.orderId}</td>
                            <td>{orderResult.userDetail.name}</td>
                            <td>{orderResult.userDetail.mobile}</td>
                            <td>
                              {/* <label className={(orderResult.paymentMethod === 1 ? "badge badge-info" : (orderResult.paymentMethod === 2 ? "badge badge-info" : "badge badge-info"))} >{(orderResult.paymentMethod === 1 ? (<span>Wallet</span>) : (orderResult.paymentMethod === 2 ? (<span>Online</span>) : (<span>COD</span>)))}</label> */}
                              <label className="badge badge-info">{paymentMethod(orderResult.paymentMethod)}</label>
                            </td>
                            <td>
                              {orderResult.orderStatus === '1' ? (
                                <label className="badge badge-warning">Pending</label>
                              ) : (
                                orderResult.orderStatus === '2' ? (
                                  <label className="badge badge-success">Accepted</label>
                                ) : (
                                  orderResult.orderStatus === '3' ? (
                                    <label className="badge badge-danger">Cancel</label>
                                  ) : (
                                    orderResult.orderStatus === '4' ? (
                                      <label className="badge badge-info">Driver reached pickup location</label>
                                    ) : (
                                      orderResult.orderStatus === '5' ? (
                                        <label className="badge badge-info">Picked up</label>
                                      ) : (<label className="badge badge-success">Delivered</label>)
                                    )
                                  )
                                )
                              )
                              }
                            </td>
                            <td>
                              {orderResult.paymentStatus === 1 ? (
                                <label className="badge badge-success">Paid</label>
                              ) : (
                                <label className="badge badge-danger">Not Paid</label>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <th>Delivery Charge</th>
                            <th>Taxes And Charges</th>
                            <th>Tip Amount</th>
                            <th>Discount</th>
                            <th>Total Amount</th>
                          </tr>
                          <tr>
                          <td>{orderResult.totalAmount}</td>
                          <td>{orderResult.delivery ? orderResult.delivery : 0}</td>
                          <td>{orderResult.taxesAndCharges}</td>
                          <td>{orderResult.tip ? orderResult.tip : 0 }</td>
                          <td>{orderResult.discount}</td>
                          <td>{orderResult.toPay}</td>
                          </tr>
                          <tr>
                            <th>Customer Email</th>
                            <th>Order Type</th>
                            <th>Distance</th>
                            <th>Driver Order Accepted Time</th>
                            <th>Return Before Date</th>
                            <th>Applied Coupon Code</th>
                          </tr>
                          <tr>
                          <td>{orderResult.userDetail.email}</td>
                          <td>{orderResult.orderType == 1 ? 'Normal' : 'Document' }</td>
                          <td>{orderResult.distance}</td>
                          <td>{orderResult.driverOrderAcceptedTime}</td>
                          <td>{orderResult.returnBeforeDate ? orderResult.returnBeforeDate : 'N/A' }</td>
                          <td>{orderResult.couponCode ? <Link to={`/coupon-detail/${orderResult.couponId}`}>{orderResult.couponCode ? orderResult.couponCode : 'N/A'}</Link> : 'N/A'} </td>
                          </tr>

                          <tr>
                            <th>Address Type</th>
                            <th>Area Name</th>
                            <th>Landmark</th>
                            <th>Complete Address</th>
                            <th>Floor</th>
                            <th>Order Date</th>
                          </tr>
                          <tr>
                          <td>{( orderResult.addressDetail.addressType === '1' ? 'Home' : orderResult.addressDetail.addressType === '2' ? 'Work' : orderResult.addressDetail.addressType === '3' ? 'Hotel' : 'Other')}</td>
                            <td>{orderResult.addressDetail.areaName}</td>
                            <td>{orderResult.addressDetail.landmark}</td>
                            <td>{orderResult.addressDetail.completeAddress}</td>
                            <td>{orderResult.addressDetail.floor}</td>
                            <td>{orderResult.createdAt}</td>
                          </tr>
                          <tr>
                            <th >Merchant To Customer Rating</th>
                            <th >Driver To Customer Rating</th>
                            <th>Assign Driver Name</th>
                            <th>Wallet Balance</th>
                            <th>GP Point</th>
                            <th>Online Amount</th>
                          </tr>
                          <tr>
                            <td >
                              <ReactStars
                                count={5}
                                size={24}
                                value={orderResult.mercToCustRating}
                                edit={false}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </td>
                            <td >
                              <ReactStars
                                count={5}
                                size={24}
                                value={orderResult.driverToCustRating}
                                edit={false}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </td>
                            <td>
                              {orderResult.assignDriverDetails != '' ? (<Link to={`/driver-details/${orderResult.assignDriverDetails._id}`} >{orderResult.assignDriverDetails.name}</Link>) : 'N/A'}
                            </td>
                            <td>{orderResult.walletDeductedAmount}</td>
                            <td>{orderResult.gpDeductedQuantity} = ({orderResult.gpDeductedAmount} ) </td>
                            <td>{orderResult.onlineDeductedAmount}</td>
                          </tr>
                          <tr>
                          <th >Merchant To Customer Comment</th>
                            <th >Driver To Customer Comment</th>
                          </tr>
       
                          <tr>

                            <td>{orderResult.mercToCustComment ? orderResult.mercToCustComment : ""}</td>
                            <td>{orderResult.driverToCustComment ? orderResult.driverToCustComment : ""}</td>
                          </tr>
                        </tbody>
                      </Table><br></br>
                      <h4 className="card-title">Product List</h4>
                              <Table className="table">
                                <thead>
                                  <tr>
                                    <th>Product Name</th>
                                    <th>Rating</th>
                                    <th>Item Price</th>
                                    <th>Sell Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    orderResult.products.map((item) => (
                                      <tr key={item.productId}>
                                        <td>{item.name}</td>
                                        <td>
                                          { ( item.rating > 0 ? (<>
                                            <ReactStars
                                              count={5}
                                              size={24}
                                              value={item.rating}
                                              edit={false}
                                              isHalf={false}
                                              emptyIcon={<i className="far fa-star"></i>}
                                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                                              fullIcon={<i className="fa fa-star"></i>}
                                              activeColor="#ffd700"
                                            />
                                          </>) : 'N/A')} 
                                        {item.review }</td>
                                        <td>{item.priceOfEachItem}</td>
                                        <td>{item.sellingPrice}</td>
                                        <td>{item.quantity}</td>
                                        <td>{ (item.priceWithQuantity ) }</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
      <DriverShowModal  show={driverShow} setShow={setDriverShow} listData={driverList} orderId={orderId}  />
    </>
  );
}

export default OrderDetails;