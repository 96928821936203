
import {
    ALL_SETTING,
    SINGLE_SETTING
  } from "../Action/Setting";

  export default function allSettingDetails(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      case ALL_SETTING:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload

        });
    //   case EDIT_SUB_CATEGORY:
    //     return Object.assign({}, state, {
    //       isSucess:action.isSecess,
    //       msg: action.resMsg,
    //     });
    //   case ADD_SUB_CATEGORY:
    //     return Object.assign({}, state, {
    //       isSecess: action.isSucess,
    //         msg: ''
    //     });
        case SINGLE_SETTING:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
            });

        // case DELETE_SUB_CATEGORY:
        // return Object.assign({}, state, {
        //     isSucess: action.isSucess,
        //     msg: action.msg
        // });
      default:
        return state;
    }
  }
  