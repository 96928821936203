import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout/Layout";
import { getAllCategory } from "../Action/Category";
import { Col, Row, Card, CardBody, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import { addCoupon, editCoupon, getAllCoupon } from "../Action/Coupon";
import Text from "../Component/Text";
import Form from "../Component/Form";
import Select from "../Component/Select";
import NumberInput from "../Component/Number";

const AddCoupon = () => {
  const dispatch = useDispatch();
  const [addNewCoupon, setaddNewCoupon] = useState({
    categoryId: "",
    couponCode: "",
    amount: "",
    maxDiscount: "",
    expireDate: "",
    type: "",
    useType: "",
    days: "",
    NoOfTimes : "",
    minPriceForApplyCoupon: "",
    maxPriceForApplyCoupon: "1000000",
  });
  const [type, setType] = useState();
  const [useType, setUseType] = useState();
  const [updateCouponId, setUpdateCouponId] = useState({ id: 0 });
  const [expireDate, setExpireDate] = useState();
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState("");
  const { payload, isFetching } = useSelector((state) => state.category);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("coupon");
  const page = new URLSearchParams(search).get("page");
  useEffect(() => {
    if (id != null) {
      getCoupon(id);
    }
    dispatch(getAllCoupon());
    dispatch(getAllCategory());
  }, []);

  const getCoupon = async () => {
    const config = getTokenPost("get", `getCouponDetail/${id}`);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success == 1) {
      setUseType(dataResponse.data.useType)
      setaddNewCoupon(dataResponse.data);
      setAmount(dataResponse.data.amount);
      setUpdateCouponId({ id: dataResponse.data._id });
    }
  };

  let navigate = useNavigate();

  const types = [
    { value: 1, label: "percentage" },
    { value: 2, label: "Flat" },
  ];

  const categoryList =
    payload &&
    payload.map((category) => {
      return { value: category._id, label: category.name };
    });
  const initialValues = {
    couponCode: addNewCoupon.couponCode ? addNewCoupon.couponCode : "",
    categoryId: addNewCoupon.categoryId ? addNewCoupon.categoryId : "",
    type: addNewCoupon.type ? addNewCoupon.type : "",
    expireDate: addNewCoupon.expireDate ? addNewCoupon.expireDate : "",
    amount: addNewCoupon.amount ? addNewCoupon.amount : "",
    maxDiscount: addNewCoupon.maxDiscount ? addNewCoupon.maxDiscount : "",
    minPriceForApplyCoupon: addNewCoupon.minPriceForApplyCoupon
      ? addNewCoupon.minPriceForApplyCoupon
      : "",
    days : addNewCoupon.days ? addNewCoupon.days : "",
    useType : addNewCoupon.useType ? addNewCoupon.useType : "",
    NoOfTimes : addNewCoupon.NoOfTimes? addNewCoupon.NoOfTimes : "",
    maxPriceForApplyCoupon: "1000000",
  };
  const handleSubmit = (values) => {
    if(useType == 1){
      values.days = "";
      values.NoOfTimes = "";
    }else if(useType == 3){
      values.expireDate= "";
      values.amount = "";
      values.maxDiscount = "";
      values.minPriceForApplyCoupon = "";
      values.type = "";
    }else{
      values.expireDate= "";
    }
    if (updateCouponId.id != 0) {
      dispatch(editCoupon(values, id)).then((res) => {
        if (res == 1) {
  const page = new URLSearchParams(search).get("page");
          navigate("/coupon?page="+page);
        }
      });
    } else {
      dispatch(addCoupon(values)).then((res) => {
        if (res == 1) {
          navigate("/coupon?page="+page);
        }
      });
    }
  };
  const useTypeOptions = [
    { value: 1, label: "Normal" },
    { value: 2, label: "Registration" },
    { value: 3, label: "Delivery" },
    { value: 4, label: "Other" },
  ];
  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">
              {updateCouponId.id != 0 ? "Update Coupon" : "Add New Coupon"}
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {updateCouponId.id != 0 ? "Update" : "Add"}
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <Form
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                  >
                    {updateCouponId.id != 0 ? null : (
                      <div className="form-group">
                        <Select
                          name="categoryId"
                          label="Category"
                          placeholder="Select Category"
                          placeholderValue={0}
                          options={categoryList}
                          required
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <Select
                        label="Use Type"
                        name="useType"
                        placeholder="Select UseType"
                        handleChange={(e) => setUseType(e.target.value)}
                        required={true}
                        options={useTypeOptions}
                      />
                    </div>
                    {useType && useType != 4 && (
                      <>
                        <div className="form-group">
                          <Text
                            name="couponCode"
                            label="Coupon Code"
                            placeholder="Enter Coupon Code"
                            required
                          />
                        </div>
                        {useType != 3 && (
                          <>
                            <div className="form-group">
                              <Select
                                label="Type"
                                name="type"
                                placeholder="Select Type"
                                handleChange={(e) => setType(e.target.value)}
                                required={useType == 3 ? false : true}
                                options={types}
                              />
                            </div>
                            <div className="form-group">
                              <NumberInput
                                name="amount"
                                label="Discount"
                                placeholder="Enter Discount"
                                onChange={(e) => setAmount(e.target.value)}
                                min={0}
                                required={useType == 3 ? false : true}
                              />
                            </div>
                            <div className="form-group">
                              <NumberInput
                                name="maxDiscount"
                                min={0}
                                placeholder="Enter Maximum Discount"
                                label={`Maximum Discount ${
                                  type == 2
                                    ? "(optional)"
                                    : addNewCoupon.type == 2
                                    ? "(optional)"
                                    : ""
                                }`}
                                required={
                                  useType == 3
                                    ? false
                                    : type == 2
                                    ? false
                                    : addNewCoupon.type == 2
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </>
                        )}
                        {useType == 2 || useType == 3 ? (
                          <>
                            <div className="form-group">
                              <NumberInput
                                name="days"
                                label="Days"
                                placeholder="Enter No of Days"
                                min={0}
                                required={
                                  useType == 2 || useType == 3 ? true : false
                                }
                              />
                            </div>
                            <div className="form-group">
                              <NumberInput
                                name="NoOfTimes"
                                label="NoOfTimes"
                                placeholder="Enter No of Times"
                                min={0}
                                required={
                                  useType == 2 || useType == 3 ? true : false
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <div className="form-group">
                            <Text
                              type="date"
                              name="expireDate"
                              label={`Expiry Date ${
                                addNewCoupon && addNewCoupon.isActive
                                  ? addNewCoupon.isActive == 1
                                    ? ""
                                    : "(optional)"
                                  : ""
                              } `}
                              onChange={(e) => setExpireDate(e.target.value)}
                              min={
                                (addNewCoupon.isActive &&
                                  addNewCoupon.isActive == 1) ||
                                expireDate
                                  ? new Date().toISOString().split("T")[0]
                                  : ""
                              }
                              required={
                                useType == 2 || useType == 3
                                  ? false
                                  : addNewCoupon.isActive
                                  ? addNewCoupon.isActive == 1
                                    ? true
                                    : false
                                  : true
                              }
                            />
                          </div>
                        )}
                       { useType != 3 && 
                        <div className="form-group">
                          <NumberInput
                            label="Minimum price for Apply Coupon"
                            name="minPriceForApplyCoupon"
                            placeholder="Enter Minimum Price for Apply Coupon"
                            error={
                              error
                                ? "please Enter Valid Minimum Price For Coupon"
                                : undefined
                            }
                            min={0}
                            onChange={(e) => {
                              if (type == 2 && e) {
                                if (Number(e.target.value) < Number(amount)) {
                                  setError(true);
                                } else {
                                  setError(false);
                                }
                              }
                            }}
                            required={useType == 3 ? false : true}
                          />
                        </div>}
                      </>
                    )}
                    <button
                      type="submit"
                      className="btn btn-gradient-primary mr-2"
                      disabled = {useType == 4 ? true : false}
                    >
                      {updateCouponId.id != 0 ? "Update" : "Submit"}
                    </button>
                    <Link to={"/coupon"} className="btn btn-light">
                      Cancel
                    </Link>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default AddCoupon;
