import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost } from "../services/Token";

export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const ALL_SUB_CATEGORY = "ALL_SUB_CATEGORY";
export const SINGLE_SUB_CATEGORY = "SINGLE_SUB_CATEGORY";

export function getSubCategoryList(payloadData) {
  return async (dispatch, getState) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post', 'SubCategoryListByCategoryId', postData);
    const responsive = await axios(config);
    const categoriesList = responsive.data.hathme;
    const payload = [];
    if (categoriesList.success === 1) {
      const payload = categoriesList.data.subCategories;
      dispatch({ type: ALL_SUB_CATEGORY, payload, isFetching: false });
    } else {
      dispatch({ type: ALL_SUB_CATEGORY, payload:[], isFetching: false });
      toast.error(payload.resMsg);
    }
  }
}

export function subCategoryAddResult(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post', 'AddSubCategroy', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: ADD_SUB_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getSubCategoryList({ categoryId: payloadData.categoryId }));
    } else if (payload.success === 0) {
      toast.error(payload.resMsg);
    }
  };
}

export function subCategoryUpdateResult(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post', 'UpdateSubCategory', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: EDIT_SUB_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getSubCategoryList({ categoryId: payloadData.categoryId }));
    } else if (payload.success === 0) {
      toast.error(payload.resMsg);
    }
  };
}


export function deleteSubCategory(subCategoryId, categoryId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "subCategoryId": subCategoryId
      }
    }
    const config = getTokenPost('post', 'DeleteSubCategory', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: DELETE_SUB_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
    }
    dispatch(getSubCategoryList({ categoryId: categoryId }));
  };
}

