import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useLocation  } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";
import SearchInput from '../Component/SearchInput';

let PageSize = 10;
const DriverOrdersPayment = () => {
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [status, setStatus] = useState("");
    const [pageNum, setPageNum] = useState()
    const [search, setSearch] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const page = queryParams.get('page');
    if(pageNum){
      queryParams.set('page', pageNum);
      window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
    }
   const [currentPage, setCurrentPage] = useState(page ? page : 1);
  
    const getUserPayments = async (searchTerm = '', status='') => {
        try {
            const config = getTokenPost('post', `driversTransactionsHistory?searchTerm=${searchTerm}&status=${status}`);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.resCode === 1) {
                const payments = dataResponse.data;
                setUserPayments(payments);
            }
        } catch (error) {
            console.error("Error fetching driver payments:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setCurrentPage(searchTerm||status ? 1 :  page ? page : 1);

        getUserPayments(searchTerm, status);
        setSearch(false)
    }, [search, status]);

      // Reset searchTerm to /merchant
  useEffect(() => {
    if (location.pathname === "/driver-send-money") {
      setSearchTerm("");
      setStatus('')
      setSearch(true)
    }
  }, [location]);


    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userPayments.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userPayments]);

    const ApprovedAccount = async (transactionId,type) => {
        const result = await confirm("Are you sure! You want to Approve this Amount.", options);
        if (result) {
            updateStatus(transactionId,type);
        }
      }
      const RejectedAccount = async (transactionId,type) => {
        const result = await confirm("Are you sure! You want to Reject this Amount.", options);
        if (result) {
            updateStatus(transactionId,type);
        }
      }
      const updateStatus = async (transactionId,type)=>{
        try {
            const postData = { hathme: {transactionId: transactionId,type:type }};
            const config = getTokenPost('post', 'depositAmountAcceptRejected', postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            toast.success(dataResponse.resMsg);
            getUserPayments();
        } catch (error) {
            console.error("Error fetching driver payments:", error);
        }
    }


    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">Driver Send Money </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Payment List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>

                        <Col className='grid-margin stretch-card'>
                       <div className="container-fluid">
                    <Row>
                      <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between'>

                                    <select
                            className="form-control rounded border-dark w-auto h-auto pb-2"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                            style={{
                                backgroundColor: '#f5f5f5',
                              }}
                            >
                                        <option value = {""}>Select Status</option>
                                        <option value = {0}>Pending</option>
                                        <option value = {1}>Approved</option>
                                        <option value = {2}>Rejected</option>
                                    </select>
                                    <div>

                                <SearchInput
                                   onChange={(e) => setSearchTerm(e.target.value)}
                                   searchTerm ={searchTerm}
                                   placeholder = "Search By Name Email or Number"
                                   onClick ={() => setSearch(true)}
                                   />  
                                    </div>
                                   </div>
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                 <tr>
                                                    <th>S. No</th>
                                                    <th>Name</th>
                                                    <th>Payment ID</th>
                                                    <th>Transaction ID</th>
                                                    <th>Amount</th>
                                                    <th>status</th>
                                                    <th>Request Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {currentItems.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{payment.index}</td>
                                                        <td>
                                                            <Link to = {`/driver-details/${payment.driverId}`}>
                                                            {payment.driverName}
                                                            </Link>
                                                            </td>
                                                            <td>{payment.paymentId}</td>
                                                        <td>{payment.transactionId}</td>
                                                        <td>{payment.amount}</td>
                                                        <td>{ (payment.status == 1 ? (<label className="badge badge-success">Approved</label>): (payment.status == 0 ? (<label className="badge badge-info">Pending</label>): (<label className="badge badge-danger">Rejected</label>) )  ) }</td>
                                                        <td>{payment.createdAt}</td>
                                                        <td>
                                                           {(payment.status == 0 ?
                                                            (
                                                                <>
                                                                    <Button className="btn btn-success btn-sm"
                                                                     onClick={() => ApprovedAccount(payment.transactionId,1)}
                                                                     >Approve</Button>&nbsp;
                                                                    <Button className="btn btn-danger btn-sm" 
                                                                    onClick={() => RejectedAccount(payment.transactionId,2)}
                                                                    >Reject</Button>&nbsp;
                                                                </>
                                                            ) : payment.status == 1 ?
                                                            (
                                                                <Button className="btn btn-danger btn-sm" 
                                                                onClick={() => RejectedAccount(payment.transactionId,2)}
                                                                >Reject</Button> 
                                                            ) : (
                                                                <Button className="btn btn-success btn-sm"
                                                                     onClick={() => ApprovedAccount(payment.transactionId,1)}
                                                                     >Approve</Button>
                                                            ) )

                                                            }
                                                        </td>   
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={Number(currentPage)}
                                        totalCount={userPayments.length}
                                        pageSize={PageSize}
                                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                                        />
                                </CardBody>
                            </Card>

                            </Col>
                    </Row>
                        </div>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default DriverOrdersPayment;
