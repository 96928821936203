import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategory } from '../Action/Category';
import CategoryList from '../Component/CategoryList';
import Pagination from '../Paginate/Pagination';

import {
  Col,
  Row, Card, CardBody,
  Table,
  List,
  Input,
  Button
} from "reactstrap";
import loaderGif from "../assets/loader.gif";

let PageSize = 10;
const Category = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.category);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllCategory(searchTerm));
    setSearch(false)
  }, [search]);

  useEffect(() => {
    if (location.pathname === "/category-list") {
      setSearchTerm("");
      setSearch(true)
    }
  }, [location]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);


  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt={'logo'} />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Category List
                </h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Manage Category List</li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className='grid-margin stretch-card'>
                  <Card>
                    <CardBody>
                      {/* <h4 className="card-title">Manage Category List</h4> */}
                      <div className='d-flex justify-content-between'>
                    <div className=''>
                    <Button className='btn btn-success btn-sm float-right' onClick={() => setSearch(true)} >Search</Button> 
                        <Input
                      className="form-control w-auto rounded border-dark floart-right ml-2 "
                      type="text"
                      placeholder="Search by Name"
                      style={{
                          height:"32px"
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      /> 
                    </div>
                      <div>
                      <Link to={'/add-category'} style={{ float: "right" }} className='btn btn-info btn-sm' >Add New Category</Link>
                      </div>
                    </div> 
                    <div className="table-responsive">

                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Name</th>
                            <th>Is Document</th>
                            <th>Image</th>
                            <th>Sub Category</th>
                            <th>Status</th>
                            <th>Register Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <CategoryList currentItems={currentTableData} currentPage = {currentPage} pageSize = {PageSize}></CategoryList>
                        </tbody>
                      </Table>
                      </div>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>)}
    </>
  )
}
export default Category;