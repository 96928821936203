import React from "react";
const Footer = () => {
   return(
    <>
        <footer className="footer">
            <div className="container-fluid clearfix d-flex justify-content-center">
              <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © Hathme.com</span>
            </div>
          </footer>
    </>
   );
 }
 export default Footer;