import React from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux"; useNavigate,
import { useLocation } from "react-router-dom";
import auth from '../../services/auth';
// import { logoutUser } from "../../Action/Auth";

import LogoIcon from "../../Component/LogoIcon";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const Capitalize = ()=>{
    return auth().name.charAt(0).toUpperCase() + auth().name.slice(1);
  }
  const sideBar = () => {
    var body = $('body');
    if(pathname !== "/dashboard"){
        var sidebarIconOnly = body.hasClass('sidebar-icon-only');
        var sidebarFixed = body.hasClass("sidebar-fixed");
        if(!sidebarIconOnly){
          $("body").addClass("sidebar-icon-only");
        }else{
          $("body").removeClass("sidebar-icon-only");
        }
    }else{
        var sidebarIconOnly =  $("body").hasClass("sidebar-icon-only sidebar-fixed");
        if(sidebarIconOnly){
          $("body").removeClass("sidebar-icon-only sidebar-fixed");
         }else{
          $("body").addClass("sidebar-icon-only sidebar-fixed");
         }
      // $("body").addClass("sidebar-icon-only sidebar-fixed");
      // $("body").removeClass("sidebar-icon-only");
      // $("body").addClass("sidebar-icon-only");
    }
  }
   return(
       <>
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link to={'/'} className="navbar-brand brand-logo"><LogoIcon/> HATHME</Link>
          <Link to={'/'} className="navbar-brand brand-logo-mini"><img src={'../../web/assets/images/logo-mini.svg'} alt={'logo'} /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" onClick={sideBar} type="button" data-toggle="minimize">
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                {/* <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span> */}
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                <h6 className="p-3 mb-0">Notifications</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Event today</h6>
                    <p className="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
                    <p className="text-gray ellipsis mb-0"> Update dashboard </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Launch Admin</h6>
                    <p className="text-gray ellipsis mb-0"> New admin wow! </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">See all notifications</h6>
              </div>
            </li>
            <li className="nav-item nav-profile dropdown">
              <Link to={'#'} className="nav-link" id="profileDropdown" data-toggle="dropdown" aria-expanded="false">
                <div className="nav-profile-img">
                    <img src={auth().profileImg} alt={'profile'} />
                    <span className="availability-status online"></span>
                  </div>
                  <div className="nav-profile-text">
                    <p className="mb-1 text-black">{ Capitalize() }</p>
                  </div>
              </Link>

            </li>
            
            {/* <li className="nav-item nav-logout d-none d-lg-block">
              <Link to={'#'} onClick={() => doLogout()}  className="nav-link"><i className="mdi mdi-power"></i></Link>
            </li> */}
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
       </>
   );
 }
 export default Header;