import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { sizeUpdate, deleteSize, activDeactivSize } from "../Action/Size"; // Import appropriate size-related actions
import { confirm } from "react-confirm-box";

const SizeList = ({ currentItems }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const handleClose = () => setShow(false);

  const [updateSize, setUpdateSize] = useState({ name: "", sizeId: "" });

  const sizeUpdateHandler = (item) => {
    setTitle("Update Size");
    setUpdateSize({ name: item.name, sizeId: item._id });
    setShow(true);
  };

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const activateSize = async (sizeId) => {
    const result = await confirm(
      "Are you sure you want to activate this size?",
      options
    );
    if (result) {
      dispatch(activDeactivSize(sizeId, 1)); // Update with the correct action for activating a size
    }
  };

  const deactivateSize = async (sizeId) => {
    const result = await confirm(
      "Are you sure you want to deactivate this size?",
      options
    );
    if (result) {
      dispatch(activDeactivSize(sizeId, 2)); // Update with the correct action for deactivating a size
    }
  };

  const deleteSizeData = async (sizeId) => {
    const result = await confirm(
      "Are you sure you want to delete this size?",
      options
    );
    if (result) {
      dispatch(deleteSize(sizeId)); // Update with the correct action for deleting a size
    }
  };

  const doSubmitSize = (event) => {
    event.preventDefault();
    dispatch(sizeUpdate(updateSize)); // Update with the correct action for updating a size
    setShow(false);
  };

  const onChangeEvent = (event) => {
    setUpdateSize({ ...updateSize, [event.target.name]: event.target.value });
  };

  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <tr key={item._id}>
            <td>{item.name}</td>
            <td>
              {item.status === "1" ? (
                <label className="badge badge-success">Active</label>
              ) : (
                <label className="badge badge-danger">Inactive</label>
              )}
            </td>
            <td>{item.createdAt}</td>
            <td>
              {item.status === "1" ? (
                <>
                  <Button
                    className="btn btn-danger btn-sm"
                    onClick={() => deactivateSize(item._id)}
                  >
                    Deactivate
                  </Button>
                  &nbsp;
                </>
              ) : (
                <>
                  <Button
                    className="btn btn-success btn-sm"
                    onClick={() => activateSize(item._id)}
                  >
                    Activate
                  </Button>
                  &nbsp;
                </>
              )}

              <Button
                className="btn btn-info btn-sm"
                onClick={() => sizeUpdateHandler(item)}
              >
                Edit
              </Button>{" "}
              <Button
                className="btn btn-danger btn-sm"
                onClick={() => deleteSizeData(item._id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form className="forms-sample" onSubmit={(event) => doSubmitSize(event)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="exampleInputName1">Size Name</label>
              <input
                type="text"
                className="form-control"
                value={updateSize.name}
                name="name"
                onChange={(event) => onChangeEvent(event)}
                id="exampleInputName1"
                placeholder="Enter Size Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-gradient-primary mr-2">
              Submit
            </button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SizeList;
