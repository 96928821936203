import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List } from "reactstrap";
import { addCategoryForm } from "../Action/CategoryForm";

const AddCategoryForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [Result, setResult] = useState({ labelName: "", type: 0, mandatoryType: 0 });

  let navigate = useNavigate();

  const onchangeEvent = (event) => {
    setResult({ ...Result, [event.target.name]: event.target.value });
  };

  const doSumbitCategory = (event) => {
    event.preventDefault();
    dispatch(addCategoryForm(Result, id));
    navigate("/category-form");
  };

  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">Manage Category Form</h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {"Add"}
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <form
                    className="forms-sample"
                    onSubmit={(event) => doSumbitCategory(event)}
                  >
                    <div className="form-group">
                      <label htmlFor="exampleInputName1">Label Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="labelName"
                        onChange={(event) => {
                          onchangeEvent(event);
                        }}
                        id="exampleInputName1"
                        placeholder="Enter Label Name"
                      />
                    </div>

                    <div className="form-group">
                      <div className="form-group">
                        <select className="form-control" name="type" onChange={(event) => onchangeEvent(event)}>
                          <option value="">Select</option>
                          <option value={0}>Text Box</option>
                          <option value={1}>Decimal Number</option>
                          <option value={2}>Date</option>
                          <option value={3}>Time</option>
                          <option value={4}>Date And Time</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group"></div>
                    <div className="form-group">
                      <div className="form-group">
                        <select className="form-control" name='mandatoryType' onChange={(event) => onchangeEvent(event)} >
                          <option value="">Select</option>
                          <option value={0}>Not Required</option>
                          <option value={1}>Required</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group"></div>

                    <button
                      type="submit"
                      className="btn btn-gradient-primary mr-2"
                    >
                      Submit
                    </button>
                    <Link to={"/category-form"} className="btn btn-light">
                      Cancel
                    </Link>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default AddCategoryForm;
