import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getSubCategoryList, subCategoryAddResult } from '../Action/SubCategory';
import SubCategoryList from '../Component/SubCategoryList';
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Modal from 'react-bootstrap/Modal';

let PageSize = 10;
const SubCategory = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.subCategory);
  const [currentPage, setCurrentPage] = useState(1);
  const search = useLocation().search;
  const category = new URLSearchParams(search).get("categoryId");

  useEffect(() => {
    if (category != null) {
      dispatch(getSubCategoryList({ categoryId: category }));
    }
    // eslint-disable-next-line
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  //  Add New Category
  const [addNewSubCategory, setaddNewSubCategory] = useState({ name: "", categoryId: category })

  const [show, setShow] = useState(false);
  const [title, settitle] = useState("");
  const handleClose = () => setShow(false);
  const subcategoryAdd = () => {
    settitle("Add New Sub Category");
    setaddNewSubCategory({ name: "", categoryId: category });
    setShow(true);
  }

  const doSumbitCategory = (event) => {
    event.preventDefault();
    dispatch(subCategoryAddResult(addNewSubCategory));
    setShow(false);
  }

  const onchangeEvent = (event) => {
    setaddNewSubCategory({ ...addNewSubCategory, [event.target.name]: event.target.value });
  }

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt={'logo'} />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Category List
                </h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Manage Category List</li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className='grid-margin stretch-card'>
                  <Card>
                    <CardBody>
                      {/* <h4 className="card-title">Manage Category List</h4> */}
                      <Button style={{ float: "right" }} className='btn btn-info btn-sm' onClick={subcategoryAdd}>Add Sub Category</Button>
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Add Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <SubCategoryList currentItems={currentTableData} />
                        </tbody>
                      </Table>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
          <Modal show={show} onHide={setShow}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <form className="forms-sample" onSubmit={(event) => doSumbitCategory(event)}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="exampleInputName1">Sub Category Name</label>
                  <input type="text" className="form-control" value={addNewSubCategory.name} name="name" onChange={(event) => { onchangeEvent(event) }} id="exampleInputName1" placeholder="Sub Enter category Name" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
              </Modal.Footer>
            </form>
          </Modal>
        </>)}
    </>
  )
}

export default SubCategory;