import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const MyMap = ({ merchantData }) => {
    const position = [20.5937, 78.9629]; // Default center position for the map (e.g., India)

    return (
        <MapContainer center={position} zoom={4} style={{ height: '350px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {Array.isArray(merchantData) && merchantData.length > 0 ? (
                merchantData.map((merchant) => {

                    return (
                        <Marker
                            key={merchant._id}
                            position={[merchant.latitude, merchant.longitude]} // Use latitude and longitude from merchant data
                        >
                            <Popup >
                                <div className='d-flex'>
                                <div><img src={merchant.image} alt="businessImage" width="40" height="40" style={{borderRadius: "10%"}} /></div>
                                <div className='px-1'>
                                <span style = {{fontWeight: 900, fontSize: '12px'}}>{merchant.businessName}</span>
                                <br/>
                                <span> {merchant.address}</span>
                                </div>
                                <br />
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            ) : (
                // Handle loading or empty state
                <p>No merchants to display.</p>
            )}
        </MapContainer>
    );
};

export default MyMap;


