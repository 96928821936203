import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { deleteCategory } from '../Action/Category';
import ImageShowModal from "./ImageShowModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { confirm } from "react-confirm-box";

const CategoryList = ({ currentItems, currentPage, pageSize }) => {
    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");

    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
    }

    const dispatch = useDispatch();
    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No"
        }
    }

    const deleteCategoryData = async (categoryId,status) => {
        const shouldDelete = await confirm("Are you sure you want to "+(status == '1' ? 'deactive':'active')+" this category?", options);
        if (shouldDelete) {
           dispatch(deleteCategory(categoryId,status));
        }
    }
    let navigate = useNavigate();
    const EditCategoryData = (item) => {
        navigate("/add-category?category=" + item._id);
    }
    return (
        <>
            {currentItems && currentItems.map((item, index) => (
                <tr key={item._id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                    <td>{item.name}</td>
                    <td>{ (item.isDocument == 0 ? 'Not Allowed' :'Allowed') }</td>
                    <td >
                        <LazyLoadImage src={item.image}
                            style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                            alt={item.name}
                            onClick={() => { showImage(item.image) }}
                        />
                    </td>
                    <td><Link to={'/manage-subcategory-list?categoryId=' + item._id} className='btn btn-info btn-sm'>Manage Subcategory</Link></td>
                    <td>{(item.isActive === 1 ? (<label className="badge badge-success">Active</label>) : (<label className="badge badge-danger">Deactive</label>))}</td>
                    <td>{item.createdAt}</td>
                    <td>
                        <Button className='btn btn-info btn-sm' onClick={() => { EditCategoryData(item) }}>Edit</Button>&nbsp;&nbsp;
                       {/* <Button className={(item.isActive === 1 ? ('btn btn-danger btn-sm') : ('btn btn-success btn-sm'))}  onClick={() => { deleteCategoryData(item._id,item.isActive) }}>{(item.isActive === 1 ? 'Deactive' : 'Active')}</Button> */}
                    </td>
                </tr>
            ))}
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />

        </>
    )
}

export default CategoryList;