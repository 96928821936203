import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from "../Action/Product";
import ProductList from "../Component/ProductList";
import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  List,
  Input,
  Button,
} from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Pagination from "../Paginate/Pagination";

let PageSize = 10;

const Product = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.product);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState()
  const [search, setSearch] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  
   if(pageNum){
     queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
  const [currentPage, setCurrentPage] = useState(page ? page : 1);

  useEffect(() => {
    dispatch(
      getAllProduct(
        searchTerm,
        selectedCategory,
        selectedSubCategory,
        selectedStatusFilter
        )
        );
        setSearch(false);
    // eslint-disable-next-line
  }, [search, selectedCategory, selectedSubCategory, selectedStatusFilter]);

  useEffect(() => {
    setCurrentPage(page ? page : 1);
  }, [search]);

  if (data.length == 0 && payload.length > 0) {
    setData(payload);
  }

  // Reset search, filter to /product
  useEffect(() => {
    if (location.pathname === "/product") {
      setSearchTerm("");
      setSelectedStatusFilter("");
      setSelectedCategory("");
      setSelectedSubCategory("");
      setSearch(true);
    }
  }, [location]);

  const filteredProducts = useMemo(() => {
    return payload.filter(
      (product) =>
        product.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        product.merchantName
          ?.toLowerCase()
          .indexOf(searchTerm?.toLowerCase()) !== -1
    );
  }, [payload]);

  const currentItems = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);
  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory("");
    setCurrentPage(1);
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
    setCurrentPage(1);
  };

  // Extract unique category values from payload
  let uniqueCategories;
  if (selectedSubCategory && !selectedCategory) {
    uniqueCategories = Array.from(
      new Set(
        data
          .filter((item) => item.subCategoryDetail.name == selectedSubCategory)
          .map((item) => item.categoryDetail.name)
      )
    );
    if (!selectedCategory) {
      setSelectedCategory(uniqueCategories[0]);
    }
  } else {
    uniqueCategories = Array.from(
      new Set(data.map((item) => item.categoryDetail.name))
    );
  }
  // Extract unique subcategory values based on the selected category
  let uniqueSubcategories;
  if (selectedCategory) {
    uniqueSubcategories = Array.from(
      new Set(
        data
          .filter((item) => item.categoryDetail.name === selectedCategory)
          .map((item) => item.subCategoryDetail.name)
      )
    );
  } else {
    uniqueSubcategories = Array.from(
      new Set(data.map((item) => item.subCategoryDetail.name))
    );
  }
  return (
    <>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loaderGif} alt="loader" />
        </div>
      ) : (
        <Layout>
          <div className="content-wrapper">
            <Row className="page-header">
              <h3 className="page-title">Manage Products</h3>
              <nav aria-label="breadcrumb">
                <List tag="ol" className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/dashboard"}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Product List
                  </li>
                </List>
              </nav>
            </Row>
            <Row>
              <Col className="grid-margin stretch-card">
                <Card>
                  <CardBody >
                    {/* search input */}
                    <div className="float-right">
                      <Button
                        style={
                          {
                            //  float: "right"
                          }
                        }
                        className="btn btn-success btn-sm float-right"
                        onClick={() => setSearch(true)}
                      >
                        Search
                      </Button>
                      <Input
                        className="form-control float-right rounded border-dark ml-2 w-auto"
                        type="text"
                        placeholder="Search by order"
                        style={{
                          height: "32px",
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Category Based Filter */}
                    <div className="float-left col-lg-2 ">
                      <select
                        className="form-control rounded border-dark h-auto"
                        style={{
                          backgroundColor: "#f5f5f5",
                        }}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">Category Based Filter</option>
                        {uniqueCategories
                          .filter((category) => category !== "")
                          .map((category) => (
                            <option key={category} value={category}>
                              {category}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* SubCategory Based Filter */}
                    <div className="float-left col-lg-2">
                      <select
                        className="form-control rounded border-dark h-auto"
                        style={{
                          backgroundColor: "#f5f5f5",
                        }}
                        value={selectedSubCategory}
                        onChange={handleSubCategoryChange}
                      >
                        <option value="">SubCategory Based Filter</option>
                        {Array.from(uniqueSubcategories)
                          .filter((subcategory) => subcategory !== "")
                          .map((subcategory) => (
                            <option key={subcategory} value={subcategory}>
                              {subcategory}
                            </option>
                          ))}
                      </select>
                    </div>
                    {/* status based filter */}
                    <div className="float-left  col-lg-2">
                      <select
                        className="form-control rounded border-dark h-auto"
                        style={{
                          backgroundColor: "#f5f5f5",
                        }}
                        onChange={handleStatusFilterChange}
                        value={selectedStatusFilter}
                      >
                        <option value="">Status Filter</option>
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="3">Rejected</option>
                      </select>
                    </div>
<div className=" table-responsive">

                    <Table className="table table-striped m-2">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>S.No</th>
                          <th style={{ width: "10%" }}>Merchant Name</th>
                          <th className="text-nowrap">Product Name</th>
                          <th className="text-nowrap">Price</th>
                          <th className="text-nowrap">Quantity</th>
                          <th className="text-nowrap">Status</th>
                          <th className="text-nowrap">Add Date</th>
                          <th className="text-nowrap">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ProductList searchTerm= {searchTerm} selectedCategory={selectedCategory} selectedSubCategory= {selectedSubCategory} selectedStatusFilter={selectedStatusFilter} currentItems={currentItems} currentPage = {currentPage} pageSize = {PageSize} />
                      </tbody>
                    </Table>
</div>

                    <Pagination
                      className="pagination-bar"
                      currentPage={Number(currentPage)}
                      totalCount={payload.length}
                      pageSize={PageSize}
                      onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      )}
    </>
  );
};
export default Product;