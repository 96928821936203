import React, { useState } from "react";
import { Button } from "reactstrap";
import { confirm } from "react-confirm-box";
import { approvedDocument } from "../Action/Document";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageShowModal from "./ImageShowModal";

const DocumentList = ({ currentItems }) => {
    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");
    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
    }

    const dispatch = useDispatch();
    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No"
        }
    }

    const approveDocument = async (documentId) => {
        const result = await confirm("Are you sure! You want to Approve this Document.", options);
        if (result) {
            dispatch(approvedDocument(documentId, 3));
        }
    }

    const rejectDocument = async (documentId) => {
        const result = await confirm("Are you sure! You want to Reject this Document.", options);
        if (result) {
            dispatch(approvedDocument(documentId, 2));
        }
    }

    return (
        <>
            {currentItems && currentItems.map((item, index) => (
                <tr key={item.documentId}>
                    <td>{(index + 1)}</td>
                    <td> <Link to={"/merchant-details/" + item._id}>{item.customerName}</Link></td>
                    <td>{item.businessName}</td>
                    <td>{item.mobile}</td>
                    <td>{item.number}</td>
                    <td>{item.name}</td>

                    <td >
                        <LazyLoadImage src={item.backImage}
                            style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                            alt={item.name}
                            onClick={() => { showImage(item.backImage) }}
                        />
                    </td>

                    <td >
                        <LazyLoadImage src={item.frontImage}
                            style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                            alt={item.name}
                            onClick={() => { showImage(item.frontImage) }}
                        />
                    </td>

                    <td>{item.createdAt}</td>

                    <td>
                        {item.documentStatus === '1' ? (
                            <label className="badge badge-warning">Pending</label>
                        ) : item.documentStatus === '2' ? (
                            <label className="badge badge-danger">Rejected</label>
                        ) : (
                            <label className="badge badge-success">Approved</label>
                        )}
                    </td>

                    <td>
                        {
                            (item.documentStatus === '1' ?
                                (
                                    <>
                                        <Button className="btn btn-success btn-sm" onClick={() => approveDocument(item.documentId)}>Approve</Button>&nbsp;
                                        <Button className="btn btn-danger btn-sm" onClick={() => rejectDocument(item.documentId)}>Reject</Button>&nbsp;
                                    </>
                                ) :
                                (item.documentStatus === '2' ?
                                    (
                                        <>
                                            <Button className="btn btn-success btn-sm" onClick={() => approveDocument(item.documentId)}>Approve</Button>&nbsp;
                                        </>
                                    ) : (
                                        <>
                                            <Button className="btn btn-danger btn-sm" onClick={() => rejectDocument(item.documentId)} >Reject</Button>&nbsp;
                                        </>

                                    ))
                            )
                        }
                    </td>
                </tr>
            ))}
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
        </>
    )
}

export default DocumentList;

