import React from "react";
import { Link } from "react-router-dom";
import auth from "../../services/auth";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutUser } from "../../Action/Auth";
import { toast } from 'react-toastify';
import NavigationCard from "../../Component/NavigationCard";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const { pathname } = location;
  const urls = pathname.split('/')
  const pathnameURL = '/' + urls['1'];




  let navigate = useNavigate();
  const doLogout = () => {
    dispatch(logoutUser());
    toast.success("Logout successfully");
    navigate('/');
    window.location.reload();
  }
  const Capitalize = () => {
      return auth().name.charAt(0).toUpperCase() + auth().name.slice(1);
  };

  const NavigationDetails =[
    {
      className : pathnameURL === "/dashboard" ? "nav-item active" : "nav-item",
      redirect : "/dashboard",
      name:'Dashboard',
      icon : 'mdi-home',
    },
    {
      className : pathnameURL === "/category-list" || pathnameURL === "/add-category" || pathnameURL === "/manage-subcategory-list" ? "nav-item active" : "nav-item",
      redirect: "/category-list",
      name: "Manage Category",
      icon :"mdi-apps-box"
    },
    {
      className : pathnameURL === "/model-structure" || pathnameURL === '/add-model-structure' || pathnameURL === '/model-structure-detail' ? "nav-item active" : "nav-item",
      redirect: '/model-structure',
      name: "Manage Product Model",
      icon:'mdi-microsoft'
    },
    {
      className: pathnameURL === "/user" || pathnameURL === "/user-details" || pathnameURL === "/video-details" || pathnameURL === "/green-point-details" || pathnameURL === "/red-point-details" || pathnameURL === "/group-details" || pathnameURL === "/group-members" || pathnameURL === "/order-history" || pathnameURL === "/transaction-history"  || pathnameURL === "/bank-list" ? "nav-item active" : "nav-item",
      redirect:"/user",
      name: "Manage Users",
      icon: "mdi-account-multiple"
    },
    {
      className: pathnameURL === "/merchant" || pathnameURL === "/merchant-details" || pathnameURL === "/merchant-payment-history" || pathnameURL === "/merchant-products-details" || pathnameURL === "/merchant-orders-details" || pathnameURL === "/add-new-merchant" || pathnameURL === "/add-product"  || pathnameURL === "/edit-product" || pathnameURL === "/merchant-bank-list" ? "nav-item active" : "nav-item",
      redirect: '/merchant',
      name: "Manage Merchant",
      icon: "mdi-shopping"
    },
    {
      className: pathnameURL === "/product" || pathnameURL === "/product-details" ? "nav-item active" : "nav-item",
      redirect: "/product",
      name: 'Manage Product',
      icon: "mdi-server"
    },
    {
      className: pathnameURL === "/driver" || pathnameURL === "/driver-details" || pathnameURL === "/driver-order-details" || pathnameURL === "/driver-payment-details" ? "nav-item active" : "nav-item",
      redirect:  '/driver',
      name:"Manage Drivers",
      icon: "mdi-bank"
    },
    {
      className: pathnameURL === "/order" || pathnameURL === "/order-details" ? "nav-item active" : "nav-item",
      redirect: '/order',
      name: 'Manage Orders',
      icon: 'mdi-database'
    },
    {
      className: pathnameURL === "/deposit" ? "nav-item active" : "nav-item",
      redirect: '/deposit',
      name: 'Manage Deposit',
      icon: 'mdi-bank'
    },
    {
      className: pathnameURL === "/merchant-withdrawal" ? "nav-item active" : "nav-item",
      redirect: "/merchant-withdrawal",
      name: "Merchant Withdrawal",
      icon: "mdi-cash-usd"
    },
    {
      className: pathnameURL === "/driver-withdrawal" ? "nav-item active" : "nav-item",
      redirect: "/driver-withdrawal",
      name: 'Driver Withdrawal',
      icon: "mdi-chart-pie",
    },
    {
      className: pathnameURL === "/driver-send-money"  ? "nav-item active" : "nav-item",
      redirect:  '/driver-send-money',
      name:"Driver Send Money",
      icon: "mdi-bank"
    },
    {
      className : pathnameURL === "/coupon" || pathnameURL === '/add-coupon' || pathnameURL === '/coupon-detail' ? "nav-item active" : "nav-item",
      redirect: "/coupon",
      name: 'Manage Coupon',
      icon: 'mdi-cash'
    },
    {
      className : pathnameURL === "/banner" || pathnameURL === '/add-banner' ? "nav-item active" : "nav-item",
      redirect: "/banner",
      name: 'Manage Banner',
      icon: 'mdi-image-multiple'
    },
    {
      className : pathnameURL === "/vehicle-list" ? "nav-item active" : "nav-item",
      redirect : "/vehicle-list",
      name: "Manage Vehicle",
      icon: 'mdi-car'
    }
  ]
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style = {{height : '50px', overflow: "auto"}}>
        <ul className="nav">
          <li className="nav-item nav-profile">
            <Link to={"/"} className="nav-link">
              <div className="nav-profile-image">
                <img
                  src={auth().profileImg}
                  alt="profile"
                />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">{Capitalize()}</span>
              </div>
            </Link>
          </li>
          {NavigationDetails.map ((navigation, index) => {
            return(
              <NavigationCard
              key={index} 
              name = {navigation.name}
              redirect = {navigation.redirect}
              className = {navigation.className}
              icon = {navigation.icon}
              />
            )
          })}
          <li className="nav-item">
              <a className="nav-link collapsed" data-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                <span className="menu-title">Manage Setting</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-brightness-7 menu-icon"></i>
              </a>
              <div className={ pathnameURL === "/setting" || pathnameURL === "/setting-details" ||pathnameURL === "/brand" || pathnameURL === "/color" || pathnameURL === "/profile"  || pathnameURL === "/logout" || pathnameURL === "/size"  ? "show" : "collapse"} id="general-pages">
                <ul className="nav flex-column sub-menu">
                  <li className={pathnameURL === "/setting" || pathnameURL === "/setting-details" ? "nav-item active" : "nav-item"}>
                    <Link to={"/setting"} className="nav-link">
                      <span className="menu-title">Settings</span>
                      <i className="mdi mdi-brightness-7 menu-icon"></i>
                    </Link>
                  </li>
                  <li className={pathnameURL === "/brand" ? "nav-item active" : "nav-item"}>
                  <Link to={"/brand"} className="nav-link">
                    <span className="menu-title">Manage Brand</span>
                    <i className="mdi mdi-cloud menu-icon"></i>
                  </Link>
                </li>
                <li className={pathnameURL === "/color" ? "nav-item active" : "nav-item"}>
                  <Link to={"/color"} className="nav-link">
                    <span className="menu-title">Manage Color</span>
                    <i className="mdi mdi-collage menu-icon"></i>
                  </Link>
                </li>
                <li className={pathnameURL === "/size" ? "nav-item active" : "nav-item"}>
                  <Link to={"/size"} className="nav-link">
                    <span className="menu-title">Manage Size</span>
                    <i className="mdi mdi-coin menu-icon"></i>
                  </Link>
                </li>

                <li className={pathnameURL === "/profile" ? "nav-item active" : "nav-item"}>
                  <Link to={"/profile"} className="nav-link">
                    <span className="menu-title">Profile</span>
                    <i className="mdi mdi-account menu-icon"></i>
                  </Link>
                </li>
                <li className={pathnameURL === "/logout" ? "nav-item active" : "nav-item"}>
                  <Link to={'/'} onClick={() => doLogout()} className="nav-link">
                    <span className="menu-title">Logout</span>
                    <i className="mdi mdi-key-variant menu-icon"></i>
                  </Link>
                </li>

                </ul>
              </div>
            </li>
        </ul>
      </nav>
    </>
  );
};
export default Navigation;
