import React from "react";
import { Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import {useNavigate,Link } from 'react-router-dom';
import { deleteCoupon, editCoupon } from "../Action/Coupon";
import { confirm } from "react-confirm-box";

const CouponList = ({currentItems, currentPage, pageSize })=>{
   
      const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const dispatch = useDispatch();
    const deleteCouponData = (id)=>{
        const shouldDelete = window.confirm("Are you sure you want to delete this coupon?");
        if (shouldDelete) {
          dispatch(deleteCoupon(id));
        }
           
    }
    let navigate = useNavigate();
    const EditCouponData = (item,currentPage)=>{
        navigate("/add-coupon?coupon="+item._id+'&page='+currentPage);
    }
    const editStatus = async (item) => {
        
        if(item.isActive == 1){
            const result = await confirm("Are you sure! You want to Inactive this Coupon.", options);
            if(result){
                dispatch(editCoupon({isActive : 2},item._id));
            }
        }else{
            const result = await confirm("Are you sure! You want to Active this Coupon.", options);
            if(result){
                dispatch(editCoupon({isActive : 1},item._id));
            }
        }
    }
    return (
            <>
             {currentItems && currentItems.map((item,index) =>(
                <tr key={item._id}>
                <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                <td>{item.categoryName}</td>
                <td>{item.couponCode}</td>
                <td>{item.expireDate ? item.expireDate : "N/A"}</td>
                <td>{item.maxDiscount ? item.maxDiscount : "N/A"}</td>
                <td>{item.type == 1 ? "Percentage" : item.type == 2 ? "Flat" : "N/A"}</td>
                <td>{ (item.isActive == 1 ? (<label className="badge badge-success">Active</label>): (<label className="badge badge-danger">Inactive</label>) ) }</td>
                <td><Button className={`btn btn-sm ${item.isActive == 1 ? 'btn-danger' : 'btn-success'}`} onClick={()=>{editStatus(item)}}>{item.isActive == 1 ? "Deactive": "Active"}</Button></td>
                <td><Link to={'/coupon-detail/'+item._id+'&page='+currentPage} className='btn btn-info btn-sm'>View</Link> &nbsp;
                <Button className='btn btn-info btn-sm' onClick={()=>{EditCouponData(item,currentPage)}}>Edit</Button> <Button className='btn btn-danger btn-sm' onClick={()=>{deleteCouponData(item._id)}}>Delete</Button></td>
                </tr>
            ))}
            
        </>
    )
}

export default CouponList;