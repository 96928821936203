import React, { useEffect, useState } from "react";
import loaderGif from "../assets/loader.gif";
import { Link, useParams } from 'react-router-dom';
import { approvedDriverBankDetail, getSingleDriver } from '../Action/Driver';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import ImageShowModal from "../Component/ImageShowModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from 'axios';
import { getTokenPost } from '../services/Token';
import { confirm } from "react-confirm-box";

const DriverDetails = () => {
  const [ImageShow, setImageShow] = useState(false);
  const [ImgSrc, setImgSrc] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  }

  const { id } = useParams();

  const dispatch = useDispatch();
  const { driverDetails } = useSelector((state) => state.allDrivers);

  useEffect(() => {
    dispatch(getSingleDriver(id));
  }, [id]);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }

  const ApprovedAccount = async (driverId) => {
    const result = await confirm("Are you sure! You want to Approve this Driver Bank Detail.", options);
    if (result) {
      dispatch(approvedDriverBankDetail(driverId, 1)).then(() => {
        dispatch(getSingleDriver(driverId));
      });
    }
  }
  const RejectedAccount = async (driverId) => {
    const result = await confirm("Are you sure! You want to Reject this Driver Bank Detail.", options);
    if (result) {
        dispatch(approvedDriverBankDetail(driverId, 2)).then(() => {
        dispatch(getSingleDriver(driverId));
      });
    }
  }

  const getTotalEarning = async () => {
    try {
      const postData = {
        hathme: {
          driverId: id
        }
      };
      const config = getTokenPost('post', 'driverPaymentDetailById', postData);
      const response = await axios(config);
      const dataResponse = response.data.hathme;
      if (dataResponse.success === 1) {
        setTotalAmount(dataResponse.data.totalAmount);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    getTotalEarning()
    // eslint-disable-next-line 
  }, []);

  return (
    <>
      {driverDetails === undefined ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              aligndriverDetailss: "center",
            }}
          >
            <img src={loaderGif} alt='loader' />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Driver Detail</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-driverDetails"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-driverDetails active" aria-current="page">Driver Detail</li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className='grid-margin stretch-card'>
                  <Card>
                    <CardBody>
                      <Table className="table table-striped table-responsive">
                          <tr>
                            <th>Name</th>
                            <th style={{ width: '10%' }}>Email</th>
                            <th>Phone</th>
                            {/* <th>Referral Code</th> */}
                            <th>Status</th>
                            <th>Country Name</th>
                            <th>Date Of Birth</th>
                            <th>COD Hold Amount</th>
                          </tr>
                        <tbody>
                          <tr>
                            <td>{driverDetails.name}</td>
                            <td>{driverDetails.email}</td>
                            <td>{driverDetails.mobile}</td>
                            {/* <td>{driverDetails.referralCode}</td> */}
                            <td>{(driverDetails.isOnOff === 1 ? (<><i className="mdi mdi-checkbox-blank-circle" style={{ color: '#05f805' }}></i> Online</>) : 'Offline')}</td>
                            <td>{driverDetails.countryName}</td>
                            <td>{driverDetails.dateOfBirth}</td>
                            <td>
                              <Link to={`/driver-cod-payment/${driverDetails.userId}`} className='btn btn-info btn-sm bg-gradient-warning'>
                                <i className="mdi mdi-currency-inr menu-icon mdi-20px">{ parseFloat(driverDetails.cashHoldAmount).toFixed(2) }</i>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <th>Total Earning</th>
                            <th>Join Date</th>
                            <th>Country Symbol</th>
                            <th>Account Status</th>
                            <th>Orders</th>
                            <th>Rating</th>
                            <th>Profile Status</th>
                          </tr>
                          <tr>
                            <td>
                              <Link to={`/driver-payment-details/${driverDetails.userId}`} className='btn btn-info btn-sm bg-gradient-success'>

                                <i className="mdi mdi-currency-inr menu-icon mdi-20px">{driverDetails.walletBalance }</i>
                              </Link>
                            </td>
                            <td>{driverDetails.createdAt}</td>
                            <td>{driverDetails.countrySymbol}</td>
                            <td>{(driverDetails.isActive === 1 ? ('Active') : ('Blocked'))}</td>
                            <td>
                              <Link to={`/driver-order-details/${driverDetails.userId}`} className='btn btn-info btn-sm  bg-gradient-danger'>
                                <i className="mdi mdi-file-document menu-icon mdi-20px"></i>
                              </Link>
                            </td>
                            <td>
                              <ReactStars
                                count={5}
                                size={24}
                                value={driverDetails.averageRating}
                                edit={false}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </td>
                            <td>{(driverDetails.isProfileVerified === 0 ? ('Pending') : (driverDetails.isProfileVerified === 1 ? ('Verified') : ('Rejected')))}</td>

                          </tr>
                        </tbody>
                      </Table><br></br>
                      {
                        (driverDetails.accountHolderName ? (
                          <>
<h4 className="card-title">Bank Details</h4>
                      <Table className="table table-striped  table-responsive">
                        <thead>
                          <tr>
                            <th>Account Holder Name</th>
                            <th>Bank Name</th>
                            <th>Branch</th>
                            <th>Account Number</th>
                            <th>Account Type</th>
                            <th>IFSC Code</th>
                            <th>Bank Approve Status</th>
                            <th>Action</th>
                          </tr>
                          <tr>
                            <td>{driverDetails.accountHolderName}</td>
                            <td>{driverDetails.bankName}</td>
                            <td>{driverDetails.branch}</td>
                            <td>{driverDetails.accountNumber}</td>
                            
                            <td>{(driverDetails.accountType == 1 ? ('Saving Account') : ('Current Account'))}</td>
                            <td>{driverDetails.ifsc}</td>
                            <td>{(driverDetails.isBankDetailsVerified == 1 ? (<label className="badge badge-success">Verified</label>) :
                              (driverDetails.isBankDetailsVerified == 0 ? (<label className="badge badge-warning">Pending</label>) : (<label className="badge badge-danger">Rejected</label>))
                            )}</td>
                            
                            <td>
                              {(driverDetails.isBankDetailsVerified == 0 ?
                                (
                                  <>
                                    <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(driverDetails.userId)}>Approve</Button>&nbsp;
                                    <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(driverDetails.userId)}>Reject</Button>&nbsp;
                                  </>
                                ) :
                                (driverDetails.isBankDetailsVerified == 1 ?
                                  (
                                    <>
                                      <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(driverDetails.userId)}>Reject</Button>&nbsp;
                                    </>
                                  ) : (
                                    <>
                                      <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(driverDetails.userId)}>Approve</Button>&nbsp;
                                    </>
                                  ))
                              )

                              }
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </Table>
                          </>
                        ) : '')
                      }
                      
                      {
                        (driverDetails.aadharCardNumber ? (<>
<h4 className="card-title">Personal Details</h4>
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th>Aadhar Card Number</th>
                            <th>Aadhar Front</th>
                            <th>Aadhar Back</th>
                            <th>Pan Card Number</th>
                            <th>Pan Card Picture</th>
                            <th>Profile Image</th>
                            <th>Vehicle Type</th>
                            <th>DL Number</th>
                            <th>DL Front</th>
                            <th>DL Back</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{driverDetails.aadharCardNumber}</td>
                            <td>
                              <LazyLoadImage src={driverDetails.aadharCardFrontPicture}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.aadharCardNumber}
                                onClick={() => { showImage(driverDetails.aadharCardFrontPicture) }}
                              />
                            </td>
                            <td>
                              <LazyLoadImage src={driverDetails.aadharCardBackPicture}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.aadharCardNumber}
                                onClick={() => { showImage(driverDetails.aadharCardBackPicture) }}
                              />
                            </td>
                            <td>{driverDetails.panCardNumber}</td>
                            <td>
                              <LazyLoadImage src={driverDetails.panCardPicture}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.panCardNumber}
                                onClick={() => { showImage(driverDetails.panCardPicture) }}
                              />
                            </td>
                            
                            <td>
                              <LazyLoadImage src={driverDetails.profileImage}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.name}
                                onClick={() => { showImage(driverDetails.profileImage) }}
                              />
                            </td>
                            <td>{driverDetails.vehicleName} ({driverDetails.countrySymbol} {driverDetails.vehiclePerKM} / KM) </td>
                            <td>{driverDetails.dlNumber}</td>
                            <td>
                              <LazyLoadImage src={driverDetails.drivingLicenseFront}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.dlNumber}
                                onClick={() => { showImage(driverDetails.drivingLicenseFront) }}
                              />
                            </td>
                            <td>
                              <LazyLoadImage src={driverDetails.drivingLicenseBack}
                                style={{ height: "150px", width: "150px", cursor: 'pointer' }}
                                alt={driverDetails.dlNumber}
                                onClick={() => { showImage(driverDetails.drivingLicenseBack) }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                        </>) : '')
                      }
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
    </>
  );
}

export default DriverDetails;
