import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";

export const ADD_CATEGORY_FORM = "ADD_CATEGORY_FORM";



export function addCategoryForm(payloadData,id) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post',`addCategoryForm/${id}`,postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      toast.success(payload.resMsg);
    //   dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}



