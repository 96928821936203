import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { driverAssing } from '../Action/Order';
import { Button} from "reactstrap";
import { confirm } from "react-confirm-box";
// import { ListGroupItem, ListGroup} from "reactstrap";

function DriverShowModal({show,setShow,listData,orderId}) {
// const [driverShow, setDriverShow] = useState(show);
const dispatch = useDispatch();
const modalContent = {
  width:'100%',
  padding:'10px',
}
const options = {
  labels: {
    confirmable: "Yes",
    cancellable: "No"
  }
}
const modelBody = {
  padding:'0px',height:'400px',overflowY: 'scroll'
}
const AssignDriver = async (driverId) => {
  const result = await confirm("Are you sure! You want to Reject this product.", options);
  if (result) {
   dispatch(driverAssing(driverId,orderId));
   setShow(false);
  }
};
    return (
      <>
        <Modal show={show} onHide={setShow}  style={modalContent } contentClassName={'model-width'}>
          <div style={{padding: '11px'}}>
          <h1>Near by location driver. </h1>
            <Modal.Body style={modelBody}>
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr></thead>
                {
                  listData && listData.map((item, index) => (
                    <>
                    <tr>
                      <td>{++index}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td><Button className="btn btn-success btn-sm" onClick={() => AssignDriver(item._id)}>Assign</Button></td>
                    </tr>
                    </>
                  ))
                }
                </table>
            </Modal.Body>
            </div>
        </Modal>
      </>
    );
  }
export default DriverShowModal;