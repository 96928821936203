import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from '../Component/SearchInput';

let PageSize = 10;
const OrderHistory = () => {
    const { id } = useParams();
    const [userOrders, setUserOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState()
    const [userName, setUserName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    if(pageNum){
      queryParams.set('page', pageNum);
      window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
    }
   const page = queryParams.get('page');
   const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const getUserOrders = async (searchTerm='') => {
        
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('post', `getUserOrders?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.success === 1) {
                // Extract user's name and orders data
                const { userName, orders } = dataResponse.data;
                setUserName(userName);
                setUserOrders(orders);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user orders:", error);
        }
    };

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userOrders.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userOrders]);

    useEffect(() => {
        setCurrentPage(searchTerm ? 1 : page ? page : 1);
        getUserOrders(searchTerm);
        setSearch(false)
        // eslint-disable-next-line
    }, [Search]);
    const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^0-9]/gi, '');
        setSearchTerm(result);
      };
    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{userName} Order Details </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Orders List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search By Order Number"
                      onClick ={() => setSearch(true)}
                      />
                                    {/* <h4 className="card-title">Manage Orders List</h4> */}
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped table-responsive">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "10%" }}>Order ID</th>
                                                    <th style={{ width: "20%" }}>Payment Method</th>
                                                    <th style={{ width: "20%" }}>Status</th>
                                                    <th style={{ width: "20%" }}>Payment Status</th>
                                                    <th style={{ width: "15%" }}>Total Amount</th>
                                                    <th style={{ width: "20%" }}>Order Date</th>
                                                    <th style={{ width: "15%" }}>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {currentItems.map((order) => (
                                                    <tr key={order.orderId}>
                                                        <td>{order.orderId}</td>
                                                        <td><label className={(order.paymentMethod === 1 ? "badge badge-info" : (order.paymentMethod === 2 ? "badge badge-info" : "badge badge-info"))} >{(order.paymentMethod === 1 ? (<span>Wallet</span>) : (order.paymentMethod === 2 ? (<span>Online</span>) : (<span>COD</span>)))}</label></td>

                                                        <td>
                                                            {order.status === '1' ? (
                                                                <label className="badge badge-warning">Pending</label>
                                                            ) : (
                                                                order.status === '2' ? (
                                                                    <label className="badge badge-success">Accepted</label>
                                                                ) : (
                                                                    order.status === '3' ? (
                                                                        <label className="badge badge-danger">Cancel</label>
                                                                    ) : (
                                                                        order.status === '4' ? (
                                                                            <label className="badge badge-info">Driver reached pickup location</label>
                                                                        ) : (
                                                                            order.status === '5' ? (
                                                                                <label className="badge badge-info">Picked up</label>
                                                                            ) : (<label className="badge badge-success">Delivered</label>)
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            }
                                                        </td>

                                                        <td>
                                                            {order.paymentStatus === 1 ? (
                                                                <label className="badge badge-success">Paid</label>
                                                            ) : (
                                                                <label className="badge badge-danger">Not Paid</label>
                                                            )}
                                                        </td>

                                                        <td>{order.finalAmount}</td>
                                                        <td>{order.createdAt}</td>

                                                        <td>
                                                            <Link to={`/order-details/${order._id}`} className='btn btn-info btn-sm'>View</Link>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={Number(currentPage)}
                                        totalCount={userOrders.length}
                                        pageSize={PageSize}
                                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                                        />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default OrderHistory;
