import axios from 'axios';
import { getToken,getTokenPost } from "../services/Token";
import { toast } from "react-toastify";
import { getSingleMerchant } from "./Merchant";

export const ALL_DOCUMENT = 'ALL_DOCUMENT';
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export function getAllDocument() {
    return async (dispatch) => {
        const config = getToken('get', 'allDocumentData');
        try {
            const response = await axios(config);
            const DocumentList = response.data.hathme;
            const payload = DocumentList.data;
            if (DocumentList.success === 1) {
                dispatch({ type: ALL_DOCUMENT, payload, isFetching: false });
            } else {
                dispatch({type: ALL_DOCUMENT, isFetching: false, payload: []});
            }
        } catch (error) {
            console.error("Error fetching document data:", error.message);
            dispatch({ type: ALL_DOCUMENT, isFetching: false, payload: [] }); // Set payload to an empty array in case of an error
        }
    }
}

export function approvedDocument(documentId, status,merchent,adminCommission) {
    return async (dispatch) => {
      const postData = {
        "hathme": {
          "documentId": documentId,
          "status": status,
          "adminCommission":adminCommission
        }
      };
      const config = getTokenPost('post', 'approveRejectDocument', postData);
      const responsive = await axios(config);
      const payload = responsive.data.hathme;
      if (payload.success === 1) {
        dispatch({
          type: DELETE_DOCUMENT, 
          message: payload.resMsg,
          isSucess: false,
        });
        toast.success(payload.resMsg);
        dispatch(getSingleMerchant(merchent));
      }
    };
  }
  