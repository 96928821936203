import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";

export const ADD_BANNER = "ADD_BANNER";
export const DELETE_BANNER = "REGISTER_SUCCESS";
export const EDIT_BANNER = "EDIT_BANNER";
export const ALL_BANNER = "ALL_BANNER";
export const SINGLE_BANNER = "SINGLE_BANNER";
export const ALL_BANNER_MODEL = "ALL_BANNER_MODEL";

export function getAllBanners(searchTerm) {
  return async (dispatch, getState) => {
    const config = getToken('get','getBannersList', searchTerm);
    const responsive = await axios(config);
    const bannerList = responsive.data.hathme;
    const payload = bannerList.data;
    if (bannerList.resCode === 1) {
      dispatch({ type: ALL_BANNER, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_BANNER, isFetching: false});
    }
  }
}

export function deleteBanner(id) {
  return async (dispatch) => {
    const config = getToken('post',`deleteBanner?id=${id}`);
    const responsive = await axios(config);
    const bannerList = responsive.data.hathme;
    if (bannerList.resCode == 1) {
      dispatch({
        type: DELETE_BANNER,
        message: bannerList.resMsg,
        isSucess: false,
      });
      toast.success(bannerList.resMsg);
      dispatch(getAllBanners());
    }else{
      toast.error(bannerList.resMsg);
    }
  }
}

export function editBannerData (payloadData, callback) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post',`updateBanner`,payloadData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({
        type: EDIT_BANNER,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllBanners());
      return callback && callback(payload.resCode)
    }else if(payload.resCode === 0){
      toast.error(payload.resMsg);
    }
  };
}

export function addBanner (payloadData, callback) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    console.log("payloadData",payloadData)
    const config = getTokenPost('post',`addBanner`,payloadData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({
        type: ADD_BANNER,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllBanners());
      return callback && callback(payload.resCode)
    }else if(payload.resCode === 0){
      toast.error(payload.resMsg);
    }
  };
}