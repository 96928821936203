import React, { useEffect, useState } from "react";
import { Link, useParams , useLocation, useNavigate} from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMerchant } from "../Action/Merchant";
import ReactStars from "react-rating-stars-component";
import ImageShowModal from "../Component/ImageShowModal";
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { approvedDocument } from "../Action/Document";
import { confirm } from "react-confirm-box";
import { getTokenPost } from '../services/Token';
import { Col, Row, Card, CardBody, Table, List} from "reactstrap";
import loaderGif from "../assets/loader.gif";
import { toast } from "react-toastify";
import queryString from "query-string"

const MerchantDetails = () => {
  const { page } = queryString.parse(useLocation().search);
  let navigate = useNavigate();
  const imageStyle = { width: "155px", height: "155px" };
  const [ImageShow, setImageShow] = useState(false);
  const [merchent, setMerchentId] = useState(false);
  const [ImgSrc, setImgSrc] = useState("");
  const [totalAmount, setTotalAmount] = useState(0); 
  const [adminCommission, setAdminCommission] = useState("");
  const [base64Image, setBase64Image] = useState(null)
  const [profileConfirmationModalOpen, setProfileConfirmationModalOpen] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [marchentIdForConfirmation, setMarchentIdForConfirmation] = useState(null);

  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  }
 const updateProfile = ()=>{
  setBase64Image(marchantDetails?.profileImage);
  setProfileConfirmationModalOpen(true);
 }
  const { id } = useParams();
  const dispatch = useDispatch();
  const { marchantDetails } = useSelector((state) => state.merchant);
  
  useEffect(() => {
    dispatch(getSingleMerchant(id));
    setMerchentId(id);
   
    // eslint-disable-next-line
  }, [id]);
  const options = {
    labels: {
        confirmable: "Yes",
        cancellable: "No"
    }
}
const [profileUpdateData, setProfileUpdateData] = useState({profileImg: '',id:''});
const eventHandle = (event) => {
  if (event.target.name === 'image') {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64Image(reader.result);
      let images = reader.result.split(",");
      setProfileUpdateData({'profileImg': images[1],id:marchantDetails._id });
    };
  }
}
const handleProfileConfirmation = async () => {
  const postData = {
    "hathme": profileUpdateData
  }
  setProfileConfirmationModalOpen(false);
  const config = getTokenPost('post', 'UpdateMerchantProfile', postData);
  const response = await axios(config);
  const dataResponse = response.data.hathme;
  if (dataResponse.success === 1) {
      setBase64Image(dataResponse.data.profileImg)
      marchantDetails.profileImage = base64Image;
      //navigate("/merchant?page=" + page);
      toast.success(dataResponse.resMsg);
  }
  
}

const approveDocument = async (documentId) => {
    toggleConfirmationModal(documentId);
   // const result = await confirm("Are you sure! You want to Approve this Document.", options);
    // if (result) {
    //     dispatch(approvedDocument(documentId, 3,merchent));
    // }
}

const rejectDocument = async (documentId) => {
    const result = await confirm("Are you sure! You want to Reject this Document.", options);
    if (result) {
        dispatch(approvedDocument(documentId, 2,merchent,0));
    }
}
const toggleProfileConfirmationModal = () => {
  setProfileConfirmationModalOpen(!profileConfirmationModalOpen);
};


const toggleConfirmationModal = (documentId) => {
      setMarchentIdForConfirmation(documentId);
      setConfirmationModalOpen(!confirmationModalOpen);
};
const handleAdminCommissionChange = (e) => {
  const newValue = e.target.value;
  setAdminCommission(newValue);
};
  const getBusinessAmount = async () => {
    try {
      const postData = {
        hathme: {
          merchantId: id
        }
      };
      const config = getTokenPost('post', 'merchantPaymentDetailById', postData);
      const response = await axios(config);
      const dataResponse = response.data.hathme;
      if (dataResponse.success === 1) {
        setTotalAmount(dataResponse.data.totalAmount);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    getBusinessAmount()
    // eslint-disable-next-line
  }, []);

  const handleConfirmation = async () => {
    // Check if inputValue is not empty
    if (!adminCommission.trim()) {
      return;
    }
    // Dispatch the action to save admin commission to the database
    // dispatch(approvedMarchant(marchentIdForConfirmation, adminCommission));
    dispatch(approvedDocument(marchentIdForConfirmation, 3,merchent,adminCommission));
    toggleConfirmationModal(null);
    setAdminCommission("")
  };
  return (
    <>
      {marchantDetails === undefined ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Merchant Details</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Merchant Details
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
              
                <Col className="grid-margin stretch-card">
               
                  <Card>
                    <CardBody className="">
                    <Button  onClick={updateProfile} style={{float: 'right'}} className="badge badge-warning">Profile Update</Button>
                      {/* <h4 className="card-title">Merchant Details</h4> */}
                      <Table className="table table-striped table-responsive">
                          <tr>
                            <th style={{ width: '10%' }}>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Status</th>
                            <th>Image</th>
                            <th>Profile Detail Status</th>
                            <th>Profile Status</th>
                          </tr>
                        <tbody>
                          <tr>
                            <td>{marchantDetails.name}</td>
                            <td>{marchantDetails.email}</td>
                            <td>{marchantDetails.mobile}</td>
                            <td>
                              {marchantDetails.isActive === 1 ? (
                                <label className="badge badge-success">Active</label>
                              ) : (
                                <label className="badge badge-danger">Deactive</label>
                              )}
                            </td>
                            <td>
                              <img style={{ cursor: 'pointer' }} onClick={() => { showImage(marchantDetails.profileImage) }} src={marchantDetails.profileImage} alt={marchantDetails.name} />
                            </td>
                            <td>{(marchantDetails.isProfileCompleted > 0 ? 'Completed' : 'Incompleted')}</td>
                            <td>
                              {marchantDetails.isProfileVerified === 1 ? (
                                <label className="badge badge-success">Approved</label>
                              ) : marchantDetails.isProfileVerified === 2 ? (
                                <label className="badge badge-danger">Rejected</label>
                              ) : (
                                <label className="badge badge-warning">Pending</label>
                              )}
                            </td>

                          </tr>
                          <tr>
                            <th>Category Name</th>
                            <th>Business Name</th>
                            <th>Store List item</th>
                            <th>Open Time</th>
                            <th>Closed Time</th>
                            <th>Total Business Amount</th>
                            <th>Establish Year</th>
                          </tr>
                          <tr>
                            <td>{(marchantDetails.categoryName ? marchantDetails.categoryName : 'N/A')}</td>
                            <td>{(marchantDetails.businessName ? marchantDetails.businessName : 'N/A')}</td>
                            <td>
                              <Link to={"/merchant-products-details/" + marchantDetails._id}><i class="mdi mdi-store" style={{fontSize:
                              '30px',color:'#8b1111'}} ></i></Link>
                            </td>
                            <td>{marchantDetails.startTime}</td>
                            <td>{marchantDetails.endTime}</td>
                            <td>
                              <Link to={`/merchant-payment-history/${marchantDetails._id}`}>
                                {totalAmount}
                              </Link>
                            </td>
                            <td>{marchantDetails.establishmentYear}</td>
                          </tr>

                          <tr>
                            {/* <th>City Name</th> */}
                            <th>Orders</th>
                            <th>GSTIN</th>
                            <th>FSSAI</th>
                            <th>Bank Detail Status</th>
                            <th>Rating</th>
                            <th>Commission Rate</th>
                            <th>Bank Details</th>
                          </tr>

                          <tr>
                            {/* <td>{(marchantDetails.city ? marchantDetails.city : 'N/A')}</td> */}

                            <td>
                              <Link to={"/merchant-orders-details/" + marchantDetails._id} style={{fontSize:'30px'}}><i class="mdi mdi-shopping menu-icon"></i></Link>
                            </td>

                            <td>{(marchantDetails.GSTIN ? marchantDetails.GSTIN : 'N/A')}</td>
                            <td>{(marchantDetails.FSSAI ? marchantDetails.FSSAI : 'N/A')}</td>
                            <td>{(marchantDetails.isBankDetailsCompleted == 0 ? 'Incompleted' : 'Completed')}</td>

                            <td>
                              <ReactStars
                                count={5}
                                size={24}
                                value={marchantDetails.rating}
                                edit={false}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </td>
                            <td>{(marchantDetails.adminCommission ? marchantDetails.adminCommission+' %' : 'N/A')}</td>
                            <td><Link  to={"/merchant-bank-list/" + marchantDetails._id}><i class="mdi mdi-bank" style={{fontSize:"30px"}}></i></Link></td>
                          </tr>
                          <tr>
                            <th>License No</th>
                            <th colSpan={4}>Online Status</th>
                          </tr>
                          <tr> 
                            <td>{(marchantDetails.licenseNo ? marchantDetails.licenseNo : 'N/A')}</td>
                            <td>{(marchantDetails.isOnOff == 1 ? 'Online':'Offline')}</td>
                            </tr>
                        </tbody>
                      </Table><br></br>

                      <h4 className="card-title">Address Details</h4>
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            {/* <th style={{ width: "15%" }}>Flat No</th> */}
                            <th style={{ width: "15%" }}>Address</th>
                            <th style={{ width: "15%" }}>Landmark</th>
                            <th style={{ width: "15%" }}>Pincode</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td>{(marchantDetails.flatNo ? marchantDetails.flatNo : 'N/A')}</td> */}
                            <td>{(marchantDetails.address ? marchantDetails.address : 'N/A')}</td>
                            <td>{marchantDetails.landmark}</td>
                            <td>{marchantDetails.pincode}</td>
                          </tr>
                        </tbody>
                      </Table><br></br>
                      <h4 className="card-title">Documents Details</h4>
                      
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Document Number</th>
                            <th>Document Type</th>
                            <th>Back Image</th>
                            <th>Front Image</th>
                            <th>Add Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {  marchantDetails.documents.name != '' ? (
                              <>
                               <tr>
                              <td>{marchantDetails.documents.number}</td>
                              <td>{marchantDetails.documents.name}</td>
                              <td >
                                  <LazyLoadImage src={marchantDetails.documents.backImage}
                                      style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                      alt={marchantDetails.documents.name}
                                      onClick={() => { showImage(marchantDetails.documents.backImage) }}
                                  />
                              </td>
                              <td >
                                  <LazyLoadImage src={marchantDetails.documents.frontImage}
                                      style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                      alt={marchantDetails.documents.name}
                                      onClick={() => { showImage(marchantDetails.documents.frontImage) }}
                                  />
                              </td>

                              <td>{marchantDetails.documents.createdAt}</td>

                              <td>
                                  {marchantDetails.documents.documentStatus === '1' ? (
                                      <label className="badge badge-warning">Pending</label>
                                  ) : marchantDetails.documents.documentStatus === '2' ? (
                                      <label className="badge badge-danger">Rejected</label>
                                  ) : (
                                      <label className="badge badge-success">Approved</label>
                                  )}
                              </td>

                              <td>
                                  {
                                      (marchantDetails.documents.documentStatus === '1' ?
                                          (
                                              <>
                                                  <Button className="btn btn-success btn-sm" onClick={() => approveDocument(marchantDetails.documents.documentId)}>Approve</Button>&nbsp;
                                                  <Button className="btn btn-danger btn-sm" onClick={() => rejectDocument(marchantDetails.documents.documentId)}>Reject</Button>&nbsp;
                                              </>
                                          ) :
                                          (marchantDetails.documents.documentStatus === '2' ?
                                              (
                                                  <>
                                                      <Button className="btn btn-success btn-sm" onClick={() => approveDocument(marchantDetails.documents.documentId)}>Approve</Button>&nbsp;
                                                  </>
                                              ) : (
                                                  <>
                                                      <Button className="btn btn-danger btn-sm" onClick={() => rejectDocument(marchantDetails.documents.documentId)} >Reject</Button>&nbsp;
                                                  </>

                                              ))
                                      )
                                  }
                              </td>
                              </tr>
                              </>
                            ) :''}
                          </>
                       
                        </tbody>
                      </Table><br></br>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
      <Modal isOpen={confirmationModalOpen} toggle={toggleConfirmationModal}>
        <ModalHeader toggle={toggleConfirmationModal}>Add Merchant Commission</ModalHeader>
        <ModalBody>
          {marchentIdForConfirmation && (
            <>
              <h4>Are you sure you want to Approve this merchant?</h4>
              <input
                type="Number"
                placeholder="Admin Commission"
                className="form-control"
                value={adminCommission}
                onChange={handleAdminCommissionChange}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmation}>Confirm</Button>
          <Button color="secondary" onClick={toggleConfirmationModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={profileConfirmationModalOpen} toggle={toggleProfileConfirmationModal}>
        <ModalHeader toggle={toggleProfileConfirmationModal}>Profile Image Update</ModalHeader>
        <form className="forms-sample">
        <ModalBody>
                <div style={{textAlign: 'center'}}>
                <img style={imageStyle} src={base64Image} alt="" />
                   <input type="file" name="image" onChange={(event) => { eventHandle(event) }} className="form-control file-upload-info" placeholder="Upload Image" />
                </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleProfileConfirmation}>Upload</Button>
          <Button color="secondary" onClick={toggleProfileConfirmationModal}>Cancel</Button>
        </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
export default MerchantDetails;