import {
  ALL_PRODUCT,
  APPROVE_PRODUCT,
  PENDING_PRODUCT,
} from "../Action/Product";
import { ADD_PRODUCT,PRODUCT_MODEL_GET,EDIT_PRODUCT } from "../Action/AddProducts";

export function allProduct(
  state = {
    isFetching: true,
    isSucess: false,
    payload: [],
  },
  action
) {
  switch (action.type) {
    case ALL_PRODUCT:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        msg: "",
        payload: action.payload,
      });
    case PENDING_PRODUCT:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        msg: "",
        payload: action.payload,
      });
    case APPROVE_PRODUCT:
      return Object.assign({}, state, {
        isSucess: action.isSucess,
        isFetching: action.isFetching,
        msg: action.message,
      });
    default:
      return state;
  }
}

export function getProductModel(
  state = {
    isFetching: true,
    isSucess: false,
    payload: [],
  },
  action
) {
  switch (action.type) {
    case PRODUCT_MODEL_GET:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        msg: "",
        payload: action.payload,
      });
    case ADD_PRODUCT:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          isFetching: action.isFetching,
          msg: action.message,
      });
    case EDIT_PRODUCT:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          isFetching: action.isFetching,
          msg: action.message,
    });
    default:
      return state;
  }
}

