import React from "react";
// import { Button } from "reactstrap";
import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import {useNavigate,Link } from 'react-router-dom';
// import { deleteCategory } from '../Action/Category';

const SettingList = ({currentItems})=>{
    // const dispatch = useDispatch();
    // const deleteCategoryData = (categoryId)=>{
    //        dispatch(deleteCategory(categoryId));
    // }
    // let navigate = useNavigate();
    // const EditCategoryData = (item)=>{
    //     navigate("/add-category?category="+item._id);
    // }
    return (
            <>
             {currentItems && currentItems.map((item) =>(
                <tr key={item._id}>
                <td>{item.userType}</td>
                <td>{item.appMaintenance}</td>
                <td>{item.androidVersion}</td>
                <td>{item.iosVersion}</td>
                <td><Link to={'/setting-details/'+item._id} className="btn btn-info btn-sm">Edit</Link></td>
                </tr>
            ))}
        </>
    )
}

export default SettingList;