import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { brandUpdate, deleteBrand, activDeactivBrand } from "../Action/Brand";
import { confirm } from "react-confirm-box";

const BrandList = ({ currentItems }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [title, settitle] = useState("");
    const handleClose = () => setShow(false);

    const [updateBrand, setUpdateBrand] = useState({ name: "", brandId: "" });

    const brandUpdateHandler = (item) => {
        settitle("Update Brand");
        setUpdateBrand({ name: item.name, brandId: item._id });
        setShow(true);
    };

    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No",
        },
    };

    const ActiveBrand = async (brandId) => {
        const result = await confirm("Are you sure! You want to Active this Brand.", options);
        if (result) {
            dispatch(activDeactivBrand(brandId, 1));
        }
    }

    const DeactiveBrand = async (brandId) => {
        const result = await confirm("Are you sure! You want to Deactive this Brand.", options);
        if (result) {
            dispatch(activDeactivBrand(brandId,2));
        }
    }

    const deleteBrandData = async (brandId) => {
        const result = await confirm(
            "Are you sure you want to delete this brand?",
            options
        );
        if (result) {
            dispatch(deleteBrand(brandId));
        }
    };



    const doSubmitBrand = (event) => {
        event.preventDefault();
        dispatch(brandUpdate(updateBrand));
        setShow(false);
    };

    const onchangeEvent = (event) => {
        setUpdateBrand({ ...updateBrand, [event.target.name]: event.target.value });
    };

    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>
                            {item.status === "1" ? (
                                <label className="badge badge-success">Active</label>
                            ) : (
                                <label className="badge badge-danger">Deactive</label>
                            )}

                        </td>
                        <td>{item.createdAt}</td>
                        <td>

                            {
                                (item.status === '1' ?
                                    (
                                        <>
                                            <Button className="btn btn-danger btn-sm" onClick={() => DeactiveBrand(item._id)} >Deactive</Button>&nbsp;
                                        </>

                                    ) : (

                                        <>
                                            <Button className="btn btn-success btn-sm" onClick={() => ActiveBrand(item._id)}>Active</Button>&nbsp;
                                        </>
                                    ))
                            }

                            <Button
                                className="btn btn-info btn-sm"
                                onClick={() => brandUpdateHandler(item)}
                            >
                                Edit
                            </Button>{" "}
                            <Button
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteBrandData(item._id)}
                            >
                                Delete
                            </Button>
                        </td>
                    </tr>
                ))}
                
            <Modal show={show} onHide={setShow}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form className="forms-sample" onSubmit={(event) => doSubmitBrand(event)}>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Brand Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={updateBrand.name}
                                name="name"
                                onChange={(event) => {
                                    onchangeEvent(event);
                                }}
                                id="exampleInputName1"
                                placeholder="Enter Brand Name"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-gradient-primary mr-2">
                            Submit
                        </button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default BrandList;
