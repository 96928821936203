import React, { useEffect, useState, useMemo } from 'react';
import Layout from "./Layout/Layout";
import loaderGif from "../assets/loader.gif";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { getTokenPost } from "../services/Token";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from '../Component/SearchInput';

const GreenPoint = () => {
    const [greenPointData, setGreenPointData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [userName, setUserName] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)

    let PageSize = 10;
    const fetchGreenPointData = async (searchTerm='') => {
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('get', `getGreenPointUserLog/${id}?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;

            if (dataResponse.success === 1) {
                // Extract user's name and green point data
                const { senderName, greenPointData } = dataResponse.data;

                // Set the fetched data in the state
                setUserName(senderName);
                setGreenPointData(greenPointData);
                setLoading(false);;
            }
        } catch (error) {
            console.error("Error fetching green point data:", error.message);
        }
    };

    useEffect(() => {
        fetchGreenPointData(searchTerm);
        setSearch(false)
        // eslint-disable-next-line 
    }, [Search]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return greenPointData.slice(firstPageIndex, lastPageIndex);
      }, [currentPage, greenPointData]);
      const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      };
    return (
        <Layout>
            <div className='content-wrapper'>
                <Row className='page-header'>
                    <h3 className="page-title">{userName} Green Point History </h3>
                    <nav aria-label="breadcrumb">
                        <List tag="ol" className='breadcrumb'>
                            <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Green Point List</li>
                        </List>
                    </nav>
                </Row>

                <Row>
                    <Col className='grid-margin stretch-card'>
                  <div className="container-fluid">

                        <Card>
                            <CardBody>
                            <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search By Name"
                      onClick ={() => setSearch(true)}
                      />
                                {/* <h4 className="card-title">Manage Green Points List</h4> */}
                                {loading ? (
                                    <img src={loaderGif} alt="Loading..." />
                                ) : 
                                (
                                    <div className="table-responsive">
                                    <Table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Sender Name</th>
                                                <th>Receiver Name</th>
                                                <th>Credit/Debit</th>
                                                <th>Points</th>
                                                <th>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.sNo}</td>
                                                    <td>{item.senderName}</td>
                                                    <td>{item.receiverName}</td>

                                                    <td>
                                                        {item.creditOrDebit == 0 ? (
                                                            <label className="badge badge-success">Credit</label>
                                                        ) : (
                                                            <label className="badge badge-warning">Debit</label>
                                                        )}
                                                    </td>
                                                    <td>{item.quantity ? !Number.isInteger(item.quantity) && Math.floor(item.quantity.toString().split(".")[1].length) > 4 ? item.quantity.toFixed(4) : item.quantity : 0 }</td>
                                                    <td>{item.createdAt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    </div>
                                )}
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={greenPointData.length}
                                  pageSize={PageSize}
                                  onPageChange={page => setCurrentPage(page)} />
                            </CardBody>
                        </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default GreenPoint;
