
import {
    ALL_BANNER,
    ADD_BANNER,
    EDIT_BANNER,
    DELETE_BANNER,
    SINGLE_BANNER,
    ALL_BANNER_MODEL
  } from "../Action/Banner";

  export default function allBanners(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      
      case ALL_BANNER:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload
        });
      
      case ALL_BANNER_MODEL:
          return Object.assign({}, state, {
            isFetching: action.isFetching,
            msg: '',
            modelStructor:action.payload
          });
      case EDIT_BANNER:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: '',
        });
      case ADD_BANNER:
        return Object.assign({}, state, {
          isSecess: action.isSucess,
            msg: ''
        });
        case SINGLE_BANNER:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
            });

        case DELETE_BANNER:
        return Object.assign({}, state, {
            isSucess: action.isSucess,
            msg: ''
        });
      default:
        return state;
    }
  }