import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";


const Layout =({children}) =>{
  return(
      <>
      <div className="container-scroller">
         <Header/>
         <div className="container-fluid page-body-wrapper">
            <Navigation/>
            <div className="main-panel" style={{ overflow: "auto"}}>
                <main style={{height: '100px'}}>{children} <Footer/></main> 
            </div>
         </div>
      </div>
      </>
  )
}

export default Layout;