import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useParams} from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";

let PageSize = 10;
const DriverCodPayment = () => {
    const { id } = useParams();
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [driverName, setDriverName] = useState("");
    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const getUserPayments = async () => {
        try {
            const postData = {
                hathme: {
                    driverId: id
                }
            };
            const config = getTokenPost('post', 'depositTransactionHistory', postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
           
            if (dataResponse.resCode == 1) {
                const payments = dataResponse.data.result;
                setUserPayments(payments);
                setDriverName(dataResponse.data.userName); // Updated variable name
            }
        } catch (error) {
            console.error("Error fetching driver payments:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUserPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userPayments.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userPayments]);
    const ApprovedAccount = async (transactionId,type) => {
        const result = await confirm("Are you sure! You want to Approve this Amount.", options);
        if (result) {
            updateStatus(transactionId,type);
        }
      }
      const RejectedAccount = async (transactionId,type) => {
        const result = await confirm("Are you sure! You want to Reject this Amount.", options);
        if (result) {
            updateStatus(transactionId,type);
        }
      }
    const updateStatus = async (transactionId,type)=>{
        try {
            const postData = { hathme: {transactionId: transactionId,type:type }};
            const config = getTokenPost('post', 'depositAmountAcceptRejected', postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            toast.success(dataResponse.resMsg);
            getUserPayments();
        } catch (error) {
            console.error("Error fetching driver payments:", error);
        }
    }
    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{driverName} Payment History Details </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Payment List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Payment List</h4> */}
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>Payment ID</th>
                                                    <th>Transaction ID</th>
                                                    <th>Amount</th>
                                                    <th>Type</th>
                                                    <th>status</th>
                                                    <th>Request Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {currentItems.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{++index}</td>
                                                        <td>{payment.paymentId}</td>
                                                        <td>{payment.transactionId}</td>
                                                        <td>{payment.amount}</td>
                                                        <td>{(payment.type == 1 ? 'Credit' : 'Debit')}</td>
                                                        <td>{(payment.status == 0 ? 'Pending' : (payment.status == 1 ? 'Approved' :'Rejected') )}</td>
                                                        <td>{payment.createdAt}</td>
                                                        <td>
                                                           {(payment.status == 0 ?
                                                            (
                                                                <>
                                                                    <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(payment.transactionId,1)}>Approve</Button>&nbsp;
                                                                    <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(payment.transactionId,2)}>Reject</Button>&nbsp;
                                                                </>
                                                            ) : 'N/A')

                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userPayments.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default DriverCodPayment;
