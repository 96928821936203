
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_SUCCESS,
  } from "../Action/Auth";
  
  let authenticated = false;
  const token = JSON.parse(localStorage.getItem('user'));
  if(token){
    authenticated=true
  }
  
  export default function auth(state = {
    isFetching: false,
    isAuthenticated: authenticated,
  }, action) {
    switch (action.type) {
      case LOGIN_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          isAuthenticated: true,
          errorMessage: action.payload.resMsg,
          resCode:action.payload.resCode,
        });
      case LOGIN_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          isAuthenticated: false,
          errorMessage: action.payload.resMsg,
          resCode:action.payload.resCode
        });
      case LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          isAuthenticated: false,
        });
      default:
        return state;
    }
  }
  