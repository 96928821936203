import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { ListGroupItem, ListGroup} from "reactstrap";

function ImageShowModal({show,setShow,ImgSrc}) { 
    const imgstyle = {
        maxWidth:'100%',
        borderRadius:'5px',
        boxShadow:'0px 4px 29px 11px',
        width:'100%',
        maxHeight:'450px'
    }
    return (
      <>
        <Modal show={show} onHide={setShow} >
            <Modal.Body style={{padding:'0px'}}>
                <LazyLoadImage src={ImgSrc} style={imgstyle}  alt={'profile'} />
            </Modal.Body>
        </Modal>
      </>
    );
  }
export default ImageShowModal;