import React from "react";
import propTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaTitle = ({ title, description }) => {
 
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={ description } />
        </Helmet>
    );
};

MetaTitle.propTypes = {
    title: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
}

export default MetaTitle;