import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List, Table, Input, Label } from "reactstrap";
import { getAllCategory } from "../Action/Category";
import { addModelStructure, editModelStructure, } from "../Action/ModelStructure";
import { getTokenPost } from "../services/Token";
import axios from "axios";
// import { confirm } from "react-confirm-box";

const CreateModelStructure = () => {
  const [updateModelId, setUpdateModelId] = useState({ id: 0 });

  const [Result, setResult] = useState({
    subcategoryRequire: 0,
    //---------------------
    categoryId: "",
    productName: '0',
    productPrice: '0',
    sellingPrice: '0',
    offerPrice:'0',
    description: '0',
    specialFeature: '0',
    brand: '0',
    color: '0',
    size: '0',
    type: '0',
    unit: '0',
    weight: '0',
    quantity: '0',
    bestBeforeDate: '0',
    Mrp: '0',
    packedType: '0',
    expiryDate: '0',
    purchasePrice: '0',
    batchNumber: '0',
    distributorName: '0',
    purchaseInvoiceNo: '0',
    purchaseInvoiceDate: '0',
    materialType: '0',
    aboutThisItem: '0',
    manufacturer: '0',
    disclaimer: '0',
    shelfLife: '0',
    fssaiLicense: '0',
    countryOfOrigin: '0',
    seller: '0',
    ingredients: '0',
    content: '0',
    productLicenseNo: '0',
    PreparationTime:'0',
    
    // require
    productRequire: 0,
    sellingPriceRequire: 0,
    productPriceRequire: 0,
    descriptionRequire: 0,
    specialFeatureRequire: 0,
    offerRequire: 0,
    brandRequire: 0,
    colorRequire: 0,
    sizeRequire: 0,
    unitRequire: 0,
    weightRequire: 0,
    quantityRequire: 0,
    bestBeforeDateRequire: 0,
    MrpRequire: 0,
    packedTypeRequire: 0,
    expiryDateRequire: 0,
    purchasePriceRequire: 0,
    batchNumberRequire: 0,
    distributorNameRequire: 0,
    purchaseInvoiceNoRequire: 0,
    purchaseInvoiceDateRequire: 0,
    materialTypeRequire: 0,
    aboutThisItemTypeRequire: 0,
    manufacturerRequire: 0,
    disclaimerRequire: 0,
    shelfLifeRequire: 0,
    fssaiLicenseRequire: 0,
    countryOfOriginRequire: 0,
    sellerRequire: 0,
    ingredientsRequire: 0,
    contentRequire: 0,
    productLicenseNoRequire: 0,
    PreparationTimeRequire:0,
    
    //type
    productNameType: 1,
    productPriceType: 1,
    sellingPriceType: 1,
    offerPriceType: 1,
    descriptionType: 1,
    specialFeatureType: 1,
    brandType: 1,
    colorType: 1,
    sizeType: 1,
    unitType: 1,
    weightType: 1,
    quantityType: 1,
    bestBeforeDateType: 1,
    MrpType: 1,
    packedTypeType: 1,
    expiryDateType: 1,
    purchasePriceType: 1,
    batchNumberType: 1,
    distributorNameType: 1,
    purchaseInvoiceNoType: 1,
    purchaseInvoiceDateType: 1,
    materialTypeType: 1,
    aboutThisItemType: 1,
    manufacturerType: 1,
    disclaimerType: 1,
    shelfLifeType: 1,
    fssaiLicenseType: 1,
    countryOfOriginType: 1,
    sellerType: 1,
    ingredientsType: 1,
    contentType: 1,
    productLicenseNoType: 1,
    PreparationTimeType:1,
    
  });
  const dispatch = useDispatch();
  const { payload } = useSelector((state) => state.category);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("model");

  useEffect(() => {
    if (id !== null) {
      updateModel(id);
    }
    dispatch(getAllCategory());
    // eslint-disable-next-line
  }, []);

  // Update Category Details
  const updateModel = async () => {
    const config = getTokenPost("get", `productModelStructureDetails/${id}`);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setResult(dataResponse.data);
      setUpdateModelId({ id: dataResponse.data._id });
    }
  };


  const modelTypeListStyle = {
    background: "#0caa9c",
    color: "#fff",
    textAlign: "center",
    padding: "1px",
    marginBottom: "15px",
  };
  const titleStyle = {
    fontSize: "22px",
    padding: "7px",
    fontWeight: "bold",
  };
  const dataTypeStyle = {
    width: "30px",
    height: "30px",
    margin: "auto",
    marginTop: "-14px",
  };
  const selectDropDown = {
    color: "#0c0c0c"
  };

  const onchangeEvent = (event) => {
    setResult({ ...Result, [event.target.name]: event.target.value });
  };
  let navigate = useNavigate();

  // const options = {
  //   labels: {
  //     confirmable: "Yes",
  //   }
  // }
  // const validateCategory = async () =>{
  //   if (!Result.categoryId) {
  //       await confirm("Please Select Atleast One Category?", options)
  //     return; 
  //   }
  // }


  const doSumbitCategory = async (event) => {
    event.preventDefault();
    if (updateModelId.id !== 0) {
      dispatch(editModelStructure(Result, id));
      navigate("/model-structure");
    } else {
      try {
        await dispatch(addModelStructure(Result));
        navigate("/add-model-structure");
      } catch (error) {
        console.error(error);
      }
    }
  };



  // const doSumbitCategory = (event) => {
  //   event.preventDefault();
  //   // validateCategory()
  //   if (updateModelId.id !== 0) {
  //     dispatch(editModelStructure(Result, id));
  //   } else {
  //     dispatch(addModelStructure(Result));

  //   }
  //   navigate("/model-structure");
  // };

  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">
              {updateModelId.id !== 0
                ? "Update Model Structure"
                : "Create Model Structure"}
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-md-12">
                      <Col className="col-md-12" style={modelTypeListStyle}>
                        <h1 style={titleStyle}>
                          {updateModelId.id !== 0
                            ? "Update Product Model Structure"
                            : "Design Product Model Structure"}
                        </h1>
                      </Col>
                      <form
                        className="forms-sample"
                        onSubmit={(event) => doSumbitCategory(event)}
                      >
                        {updateModelId.id !== 0 ? null : (
                          <div className="form-group">
                            <label htmlFor="exampleInputName1">
                              Category Name
                            </label>
                            <Input
                              id="exampleSelect"
                              className="form-control"
                              name="categoryId"
                              type="select"
                              onChange={(event) => {
                                onchangeEvent(event);
                              }}
                            >
                              <option value={0}>Select Category</option>
                              {payload.map((item, index) => (
                                <>
                                  <option value={item._id}>{item.name}</option>
                                </>
                              ))}
                            </Input>
                          </div>
                        )}

                        <Col>
                        <div className="table-responsive">
                          <Table>
                            <tr key={1}>
                              <th>
                                <Label>1</Label>
                              </th>
                              <th>
                                <Label>Product Title</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.productName === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productName"
                                > YES</Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.productName === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productName"
                                >
                                  No
                                </Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                <select
                                  id="productRequire"
                                  onChange={(event) => onchangeEvent(event)}
                                  className="form-control"
                                  name="productRequire"
                                  value={Result.productRequire}
                                  style={selectDropDown}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productNameType"
                                  value={Result.productNameType}
                                  style={selectDropDown}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={2}>
                              <th>
                                <Label>2</Label>
                              </th>
                              <th>
                                <Label>Product Price</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.productPrice === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productPrice"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.productPrice === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productPrice"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productPriceRequire"
                                  value={Result.productPriceRequire}
                                  style={selectDropDown}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productPriceType"
                                  value={Result.productPriceType}
                                  style={selectDropDown}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={3}>
                              <th>
                                <Label>3</Label>
                              </th>
                              <th>
                                <Label>Selling Price</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.sellingPrice === '1'}
                                  name="sellingPrice"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.sellingPrice === '0'}
                                  name="sellingPrice"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sellingPriceRequire"
                                  value={Result.sellingPriceRequire}
                                  style={selectDropDown}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sellingPriceType"
                                  value={Result.sellingPriceType}
                                  style={selectDropDown}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={4}>
                              <th>
                                <Label>4</Label>
                              </th>
                              <th>
                                <Label>Offer Price</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  onChange={(event) => onchangeEvent(event)}
                                  style={dataTypeStyle}
                                  checked={Result.offerPrice === '1'}
                                  defaultValue={1}
                                  name="offerPrice"
                                ></Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  onChange={(event) => onchangeEvent(event)}
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.offerPrice === '0'}
                                  name="offerPrice"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="offerRequire"
                                  value={Result.offerRequire}
                                  
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="offerPriceType"
                                  value={Result.offerPriceType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={5}>
                              <th>
                                <Label>5</Label>
                              </th>
                              <th>
                                <Label>Description</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.description === '1'}
                                  name="description"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  name="description"
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.description === '0'}
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                <select
                                  className="form-control"
                                  name="descriptionRequire"
                                  onChange={(event) => onchangeEvent(event)}
                                  value={Result.descriptionRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="descriptionType"
                                  value={Result.descriptionType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={6}>
                              <th>
                                <Label>6</Label>
                              </th>
                              <th>
                                <Label>Special Feature</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.specialFeature === '1'}
                                  name="specialFeature"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  onChange={(event) => onchangeEvent(event)}
                                  checked={Result.specialFeature === '0'}
                                  name="specialFeature"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="specialFeatureRequire"
                                  value={Result.specialFeatureRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="specialFeatureType"
                                  value={Result.specialFeatureType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={7}>
                              <th>
                                <Label>7</Label>
                              </th>
                              <th>
                                <Label>Add Brand</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.brand === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="brand"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.brand === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="brand"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="brandRequire"
                                  value={Result.brandRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="brandType"
                                  value={Result.brandType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={8}>
                              <th>
                                <Label>8</Label>
                              </th>
                              <th>
                                <Label>Add Color</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.color === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="color"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.color === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="color"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="colorRequire"
                                  value={Result.colorRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="colorType"
                                  value={Result.colorType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            <tr key={9}>
                              <th>
                                <Label>9</Label>
                              </th>
                              <th>
                                <Label>Add Size</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.size === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="size"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.size === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="size"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sizeRequire"
                                  value={Result.sizeRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sizeType"
                                  value={Result.sizeType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={10}>
                              <th>
                                <Label>10</Label>
                              </th>
                              <th>
                                <Label>Add Unit</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.unit === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="unit"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.unit === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="unit"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="unitRequire"
                                  value={Result.unitRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="unitType"
                                  value={Result.unitType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={11}>
                              <th>
                                <Label>11</Label>
                              </th>
                              <th>
                                <Label>Add Weight</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.weight === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="weight"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.weight === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="weight"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="weightRequire"
                                  value={Result.weightRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="weightType"
                                  value={Result.weightType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={12}>
                              <th>
                                <Label>12</Label>
                              </th>
                              <th>
                                <Label>Add Quantity</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.quantity === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="quantity"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.quantity === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="quantity"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="quantityRequire"
                                  value={Result.quantityRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="quantityType"
                                  value={Result.quantityType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={13}>
                              <th>
                                <Label>13</Label>
                              </th>
                              <th>
                                <Label>Add Best Before</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.bestBeforeDate === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="bestBeforeDate"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.bestBeforeDate === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="bestBeforeDate"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="bestBeforeDateRequire"
                                  value={Result.bestBeforeDateRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="bestBeforeDateType"
                                  value={Result.bestBeforeDateType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={14}>
                              <th>
                                <Label>14</Label>
                              </th>
                              <th>
                                <Label>Add Mrp</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.Mrp === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="Mrp"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.Mrp === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="Mrp"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="MrpRequire"
                                  value={Result.MrpRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="MrpType"
                                  value={Result.MrpType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={15}>
                              <th>
                                <Label>15</Label>
                              </th>
                              <th>
                                <Label>Add Packed Type</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.packedType === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="packedType"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.packedType === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="packedType"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="packedTypeRequire"
                                  value={Result.packedTypeRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="packedTypeType"
                                  value={Result.packedTypeType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={16}>
                              <th>
                                <Label>16</Label>
                              </th>
                              <th>
                                <Label>Add Expiry Date</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.expiryDate === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="expiryDate"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.expiryDate === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="expiryDate"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="expiryDateRequire"
                                  value={Result.expiryDateRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="expiryDateType"
                                  value={Result.expiryDateType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={17}>
                              <th>
                                <Label>17</Label>
                              </th>
                              <th>
                                <Label>Purchase Price</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.purchasePrice === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchasePrice"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.purchasePrice === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchasePrice"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchasePriceRequire"
                                  value={Result.purchasePriceRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchasePriceType"
                                  value={Result.purchasePriceType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={18}>
                              <th>
                                <Label>18</Label>
                              </th>
                              <th>
                                <Label>Add Batch Number</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.batchNumber === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="batchNumber"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.batchNumber === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="batchNumber"
                                ></Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="batchNumberRequire"
                                  value={Result.batchNumberRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="batchNumberType"
                                  value={Result.batchNumberType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={19}>
                              <th>
                                <Label>19</Label>
                              </th>
                              <th>
                                <Label>Add Distributor Name</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.distributorName === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="distributorName"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.distributorName === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="distributorName"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="distributorNameRequire"
                                  value={Result.distributorNameRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="distributorNameType"
                                  value={Result.distributorNameType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={20}>
                              <th>
                                <Label>20</Label>
                              </th>
                              <th>
                                <Label>Add Purchase InvoiceNo</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.purchaseInvoiceNo === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceNo"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.purchaseInvoiceNo === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceNo"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceNoRequire"
                                  value={Result.purchaseInvoiceNoRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceNoType"
                                  value={Result.purchaseInvoiceNoType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={21}>
                              <th>
                                <Label>21</Label>
                              </th>
                              <th>
                                <Label>Add Purchase Invoice Date</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.purchaseInvoiceDate === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceDate"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.purchaseInvoiceDate === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceDate"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceDateRequire"
                                  value={Result.purchaseInvoiceDateRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="purchaseInvoiceDateType"
                                  value={Result.purchaseInvoiceDateType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={22}>
                              <th>
                                <Label>22</Label>
                              </th>
                              <th>
                                <Label>Add Material Type</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.materialType === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="materialType"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.materialType === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="materialType"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="materialTypeRequire"
                                  value={Result.materialTypeRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="materialTypeType"
                                  value={Result.materialTypeType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={23}>
                              <th>
                                <Label>23</Label>
                              </th>
                              <th>
                                <Label>About This Item</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.aboutThisItem === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="aboutThisItem"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.aboutThisItem === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="aboutThisItem"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="aboutThisItemTypeRequire"
                                  value={Result.aboutThisItemTypeRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="aboutThisItemType"
                                  value={Result.aboutThisItemType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={24}>
                              <th>
                                <Label>24</Label>
                              </th>
                              <th>
                                <Label>Add Manufacturer</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.manufacturer === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="manufacturer"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.manufacturer === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="manufacturer"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="manufacturerRequire"
                                  value={Result.manufacturerRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="manufacturerType"
                                  value={Result.manufacturerType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={25}>
                              <th>
                                <Label>25</Label>
                              </th>
                              <th>
                                <Label>Add Shelf Life</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.shelfLife === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="shelfLife"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.shelfLife === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="shelfLife"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="shelfLifeRequire"
                                  value={Result.shelfLifeRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="shelfLifeType"
                                  value={Result.shelfLifeType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={26}>
                              <th>
                                <Label>26</Label>
                              </th>
                              <th>
                                <Label>Add Fssai License</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.fssaiLicense === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="fssaiLicense"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.fssaiLicense === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="fssaiLicense"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="fssaiLicenseRequire"
                                  value={Result.fssaiLicenseRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="fssaiLicenseType"
                                  value={Result.fssaiLicenseType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={27}>
                              <th>
                                <Label>27</Label>
                              </th>
                              <th>
                                <Label>Country Of Origin</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.countryOfOrigin === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="countryOfOrigin"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.countryOfOrigin === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="countryOfOrigin"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="countryOfOriginRequire"
                                  value={Result.countryOfOriginRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="countryOfOriginType"
                                  value={Result.countryOfOriginType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={28}>
                              <th>
                                <Label>28</Label>
                              </th>
                              <th>
                                <Label>Add Seller</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.seller === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="seller"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.seller === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="seller"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sellerRequire"
                                  value={Result.sellerRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="sellerType"
                                  value={Result.sellerType}
                                >
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={29}>
                              <th>
                                <Label>29</Label>
                              </th>
                              <th>
                                <Label>Add Ingredients</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.ingredients === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="ingredients"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.ingredients === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="ingredients"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="ingredientsRequire"
                                  value={Result.ingredientsRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="ingredientsType"
                                  value={Result.ingredientsType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={30}>
                              <th>
                                <Label>30</Label>
                              </th>
                              <th>
                                <Label>Add Content</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.content === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="content"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.content === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="content"
                                ></Input>
                              </td>

                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="contentRequire"
                                  value={Result.contentRequire}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>
                                </select>
                              </th>

                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="contentType"
                                  value={Result.contentType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>

                            <tr key={31}>
                              <th>
                                <Label>31</Label>
                              </th>
                              <th>
                                <Label>Add Disclaimer</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.disclaimer === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="disclaimer"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.disclaimer === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="disclaimer"
                                >
                                  No
                                </Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="disclaimerRequire"
                                  value={Result.disclaimerRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>

                                </select>
                              </th>
                              <th>
                                <select
                                  onChange={(event) => onchangeEvent(event)}
                                  className="form-control"
                                  name="disclaimerType"
                                  value={Result.disclaimerType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            {/* Update New Tag */}
                            <tr key={32}>
                              <th>
                                <Label>32</Label>
                              </th>
                              <th>
                                <Label>Product License No</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.productLicenseNo === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productLicenseNo"
                                > 
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.productLicenseNo === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productLicenseNo"
                                >
                                  No
                                </Input>
                              </td>
                              <td> 
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="productLicenseNoRequire"
                                  value={Result.productLicenseNoRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>

                                </select>
                              </th>
                              <th> 
                                <select
                                  onChange={(event) => onchangeEvent(event)}
                                  className="form-control"
                                  name="productLicenseNoType"
                                  value={Result.productLicenseNoType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>
                            {/* New Updates */}
                            <tr key={33}>
                              <th>
                                <Label>33</Label>
                              </th>
                              <th>
                                <Label>Preparation time</Label>
                              </th>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={1}
                                  checked={Result.PreparationTime === '1'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="PreparationTime"
                                >
                                  YES
                                </Input>
                              </td>
                              <td>
                                <Label>Yes</Label>
                              </td>
                              <td>
                                <Input
                                  type="radio"
                                  style={dataTypeStyle}
                                  defaultValue={0}
                                  checked={Result.PreparationTime === '0'}
                                  onChange={(event) => onchangeEvent(event)}
                                  name="PreparationTime"
                                >
                                  No
                                </Input>
                              </td>
                              <td>
                                <Label>No</Label>
                              </td>
                              <th>
                                {" "}
                                <select
                                  className="form-control"
                                  onChange={(event) => onchangeEvent(event)}
                                  name="PreparationTimeRequire"
                                  value={Result.PreparationTimeRequire}
                                >
                                  <option value="">Select Type</option>
                                  <option value={0}>Not Required</option>
                                  <option value={1}>Required</option>

                                </select>
                              </th>
                              <th>
                                <select
                                  onChange={(event) => onchangeEvent(event)}
                                  className="form-control"
                                  name="PreparationTimeType"
                                  value={Result.PreparationTimeType}
                                >
                                  {" "}
                                  <option value="">Select Type</option>
                                  <option value={1}>Text Box</option>
                                  <option value={2}>Decimal Number</option>
                                  <option value={3}>Date</option>
                                  <option value={4}>Time</option>
                                </select>
                              </th>
                            </tr>



                          </Table>
                          </div>
                        </Col>

                        <button
                          type="submit"
                          className="btn btn-gradient-primary mr-2"
                        >
                          {updateModelId.id !== 0 ? "Update" : "Submit"}
                        </button>
                        <Link to={"/model-structure"} className="btn btn-light">
                          Cancel
                        </Link>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default CreateModelStructure;
