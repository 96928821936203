import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost, getToken } from "../services/Token";

export const ALL_BANKS = "ALL_BANKS";
export const ADD_BANKS = "ADD_BANKS"
export const EDIT_BANKS = "EDIT_BANKS"
export const DELETE_BANKS = "DELETE_BANKS"

export function getBanksList(userId) {
    return async (dispatch) => {
        const postData = {
            "hathme":{
                "userId": userId
            }
        }
        const config = getTokenPost('post', 'getBankList', postData);
        const response = await axios(config);
        
        const bankList = response.data.hathme;
        const payload = bankList.data;
        if (bankList.resCode === 1) {
            dispatch({ type: ALL_BANKS, payload, isFetching: false });
        } else {
            dispatch({ type: ALL_BANKS, payload:[], isFetching: false });
            toast.error(payload.resMsg);
        }
    }
}

// export function banksAddResult(payloadData) {
//     return async (dispatch) => {
//         const postData = {
//             "hathme": payloadData
//         }
//         const config = getTokenPost('post', 'addBrand', postData);
//         const response = await axios(config);
//         const payload = response.data.hathme;
//         if (payload.success === 1) {
//             dispatch({
//                 type: ADD_BANKS,
//                 message: payload.resMsg,
//                 isSuccess: true,
//             });
//             toast.success(payload.resMsg);
//             dispatch(getBanksList());
//         } else if (payload.success === 0) {
//             toast.error(payload.resMsg);
//             dispatch({
//                 type: ADD_BANKS,
//                 message: payload.resMsg,
//                 isSuccess: false,
//             });
//         }
//     };
// }


export function activDeactivBanks(brandId, status) {
    return async (dispatch) => {
        const postData = {
            "hathme": {
                "brandId": brandId,
                "status": status
            }
        };
        const config = getTokenPost('post', 'activeDeactiveBrand', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: DELETE_BANKS,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
            dispatch(getBanksList());
        }
    };
}