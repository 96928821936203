
import {
    ADD_NEW_VEHICLE,
    DELETE_VEHICLE,
    EDIT_VEHICLE,
    ALL_VEHICLE,
    SINGLE_VEHICLE,
  } from "../Action/Vehicle";

  export default function allVehicleList(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      
      case ALL_VEHICLE:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload
        });
      case EDIT_VEHICLE:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: '',
        });
      case ADD_NEW_VEHICLE:
        return Object.assign({}, state, {
          isSecess: action.isSucess,
            msg: ''
        });
        case SINGLE_VEHICLE:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
            });
        case DELETE_VEHICLE:
        return Object.assign({}, state, {
            isSucess: action.isSucess,
            msg: ''
        });
      default:
        return state;
    }
  }