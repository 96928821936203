import React from "react";
import { Link } from "react-router-dom";
const DashboardCard = (props) => {
  const { name, result, className, icon, path, linkClass } = props;
  return (
    <Link
      // className="col-md-4 col-lg-2 col-xs-12"
      // className= {linkClass}
      style={{ textDecoration: "none" }}
      to={path}
    >
      <div
        className={`card ${className} card-img-holder text-white dashboard-card`}
        style={{ height: "70%", width: "100%"}}
      >
        <div className="card-body ">
          <img
            src="../../web/assets/images/dashboard/circle.svg"
            alt={"circleImage"}
            className="card-img-absolute"
          />
          <h5 className="font-weight-normal mb-3 ">
            {name}
            <i className={`mdi ${icon} mdi-24px float-right`}></i>
          </h5>
          <h2 className="mb-5 dashboard-card-font">{result}</h2>
        </div>
      </div>
    </Link>
  );
};

export default DashboardCard;
