import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriver } from "../Action/Driver";
import DriverList from "../Component/DriverList";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import Pagination from "../Paginate/Pagination";
import NotificationModal from "../Component/NotificationModal";

let PageSize = 10;
const Driver = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.allDrivers);
  const [Search, setSearch] = useState(false)
  let [searchTerm, setSearchTerm] = useState("");
  const [showPush, setPushShow] = useState(false);
  const [userId, setUserId] = useState('');
  const [pageNum, setPageNum] = useState()
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page');
  if(pageNum){
    queryParams.set('page', pageNum);
    window.history.replaceState(null, '', `${location.pathname}?${queryParams}`);
  }
 const [currentPage, setCurrentPage] = useState(page ? page : 1);
  useEffect(() => {
    // searchTerm =  searchTerm.trim('')
    setCurrentPage(page ? page : 1);
    dispatch(getAllDriver(searchTerm));
    setSearch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Search]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (location.pathname === "/driver") {
      setSearchTerm("");
      setSearch(true)
    }
  }, [location]);
  const sendNotification = (userId) =>{
    setUserId(userId);
    setPushShow(true);
  }

  const groupbyNotifications = (data) =>{
    setUserId(data);
    setPushShow(true);
  }

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Manage Driver List</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Driver List
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className="grid-margin stretch-card">
                  <Card>
                    <CardBody>
                      <div>
                      <Button style={{
                         float: "right"
                       }} className='btn btn-success btn-sm'onClick={() => setSearch(true)}>Search</Button> 
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search By Name,Email or Number"
                        style={{
                          width: "220px",
                          border: "1px solid #333",
                          borderRadius: "1px",
                          float: "right",
                          marginLeft: "10px",
                          height:"32px"
                        }}
                        onChange={handleSearchChange}
                        value={searchTerm}
                      ></input>
                      </div>
                      <Button className='btn btn-warning btn-sm' onClick={() => sendNotification(3)}>Send Notification</Button>&nbsp;
                      <Button className='btn btn-warning btn-sm' onClick={() => groupbyNotifications(0)}>GroupBy Notifications</Button>
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>S.No</th>
                            <th style={{ width: "10%" }}>Name</th>
                            <th style={{ width: "10%" }}>Email</th>
                            <th style={{ width: "10%" }}>Number</th>
                            <th style={{ width: "10%" }}>Balance</th>
                            <th style={{ width: "10%" }}>Status</th>
                            <th style={{ width: "10%" }}>Approve Status</th>
                            <th style={{ width: "10%" }}>Register Date</th>
                            <th style={{ width: "10%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <DriverList currentItems={currentTableData} currentPage= {currentPage} pageSize={PageSize}  setSelectedUserIds={setSelectedUserIds} selectedUserIds = {selectedUserIds}/>
                        </tbody>
                      </Table>
                      <Pagination
                        className="pagination-bar"
                        currentPage={Number(currentPage)}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) =>{ setCurrentPage(page); setPageNum(page)}}
                      />
                    </CardBody>
                    <NotificationModal show={showPush} setShow={setPushShow} userId={userId} type={3}  selectedUserIds={selectedUserIds}/>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default Driver;
