import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List, Button, Input } from "reactstrap";
import { getAllCategoryModel } from '../Action/Category';
import loaderGif from "../assets/loader.gif";
import { deleteModelStructure } from "../Action/ModelStructure";
import { confirm } from "react-confirm-box";

const ProductModelList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { modelStructor, isFetching } = useSelector((state) => state.category);
  const location = useLocation();

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }

  const deleteModel = async (id) => {
    const shouldDelete = await confirm("Are you sure you want to delete this product model?", options);
    if (shouldDelete) {
      dispatch(deleteModelStructure(id)).then(() => {
        dispatch(getAllCategoryModel());
      });
    }
  }

  const EditModelStructure = (item) => {
    navigate("/add-model-structure?model=" + item._id);
  }

  useEffect(() => {
    dispatch(getAllCategoryModel());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt={'logo'} />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Manage Product Model Structure</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Model Structure List
                    </li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className="grid-margin stretch-card">
                  <Card>
                    <CardBody>
                      <Link to={'/add-model-structure'} style={{ float: "right" }} className='btn btn-info btn-sm' >Add New Model</Link>
                    <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Category Name</th>
                            <th>Create Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modelStructor && modelStructor.map((item, index) => {
                            return (
                              <tr key={item._id}>
                                <td>{(index + 1)}</td>
                                <td>{item.category}</td>
                                <td>{item.createdDate}</td>
                                <td><Link to={'/model-structure-detail/' + item._id} className="btn btn-info btn-sm">View</Link>&nbsp;&nbsp;<Button className='btn btn-info btn-sm' onClick={() => { EditModelStructure(item) }}>Edit</Button>
                                </td>
                              </tr>
                            )
                          })
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )
      }
    </>
  );
};
export default ProductModelList;
