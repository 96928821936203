import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from '../Component/SearchInput';

let PageSize = 10;
const TransactionHistory = () => {
    const { id } = useParams();
    const [userTransactions, setUserTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [userName, setUserName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)

    const getUserTransactions = async (searchTerm='') => {
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('post', `getUserTransactions?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.success === 1) {
                const { userName, transactions } = dataResponse.data;
                // Set the fetched user's name and transactions in the state
                setUserName(userName);
                setUserTransactions(transactions);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user transactions:", error);
        }
    };

    useEffect(() => {
        getUserTransactions(searchTerm);
        setSearch(false)
        // eslint-disable-next-line
    }, [Search]);

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userTransactions.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userTransactions]);

    const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      };
    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{userName} Transaction Details </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Transactions List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Transaction List</h4> */}
                                           <SearchInput
                                             onChange={handleSearchChange}
                                             searchTerm ={searchTerm}
                                             placeholder = "Search By Name"
                                             onClick ={() => setSearch(true)}
                                                 />    
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped table-responsive">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Transaction ID</th>
                                                    <th style={{ width: "20%" }}>Payment ID</th>
                                                    <th style={{ width: "20%" }}>Amount</th>
                                                    <th style={{ width: "20%" }}>Credit/Debit</th>
                                                    <th style={{ width: "20%" }}>Status</th>
                                                    <th style={{ width: "20%" }}>Transaction Date</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {currentItems && currentItems.map((transaction) => (
                                                    
                                                    <tr >
                                                        <td>{transaction.transactionId}</td>
                                                        <td>{transaction.paymentId}</td>
                                                        <td>{transaction.amount}</td>

                                                        <td>
                                                            {transaction.creditOrDebit === 1 ? (
                                                                <label className="badge badge-success">Credit</label>
                                                            ) : (
                                                                <label className="badge badge-warning">Debit</label>
                                                            )}
                                                        </td>

                                                        <td>
                                                            {transaction.status === 0 ? (
                                                                <label className="badge badge-warning">Pending</label>
                                                            ) : transaction.status === 1 ? (
                                                                <label className="badge badge-success">Accepted</label>
                                                            ) : transaction.status === 2 ? (
                                                                <label className="badge badge-danger">Rejected</label>
                                                            ) : null}
                                                        </td>

                                                        <td>{transaction.createdAt}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userTransactions.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default TransactionHistory;
