import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirm } from "react-confirm-box";
import { rejectProduct, approvedProduct } from "../Action/Product";
import { Button } from "reactstrap";

const ProductList = ({ searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter, currentItems, currentPage, pageSize }) => {

  const dispatch = useDispatch();

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  const ApprovedAccount = async (productId) => {
    const result = await confirm("Are you sure! You want to Approve this product.", options);
    if (result) {
      dispatch(approvedProduct(searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter, productId));
    }
  }
  const RejectedAccount = async (productId) => {
    const result = await confirm("Are you sure! You want to Reject this product.", options);
    if (result) {
      dispatch(rejectProduct(searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter, productId));
    }
  }
  const CapitalizeFont = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <>
          <tr key={item._id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
            <td><Link to={'/merchant-details/' + item.merchantId} >{CapitalizeFont(item.merchantName)}</Link></td>
            <td>{item.name}</td>
            <td>{item.price}</td>
            <td>{item.quantity}</td>
            <td>
              {item.isProductApproved == 0 ? (
                <label className="badge badge-warning">Pending</label>
              ) : item.isProductApproved === 1 ? (
                <label className="badge badge-success">Approved</label>
              ) : (
                <label className="badge badge-danger">Rejected</label>
              )}
            </td>
            <td>{item.createdAt}</td>
            <td>
              {item.isProductApproved === 1 ? (
                <>
                  <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
                  <Link to={'/product-details/' + item._id} className="btn btn-info btn-sm">View</Link>
                  &nbsp;<Link to={'/edit-product?productId=' + item._id+"&merchentId="+item.merchantId+"&page="+currentPage} className="btn btn-info btn-sm">Edit</Link>
                </>) : (
                <>
                  {item.isProductApproved === 0 ? (
                    <>
                      <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>&nbsp;
                      <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>
                      &nbsp;<Link to={'/product-details/' + item._id} className="btn btn-info btn-sm">View</Link>

                    </>
                  ) : (
                    <>
                      {item.isProductApproved === 1 ? (
                        <>
                          <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>
                          &nbsp;<Link to={'/product-details/' + item._id} className="btn btn-info btn-sm">View</Link>
                        </>
                      ) : (
                        <>
                          <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>
                          &nbsp;<Link to={'/product-details/' + item._id} className="btn btn-info btn-sm">View</Link>
                        </>
                      )}
                     
                    </>
                  )}
                  &nbsp;<Link to={'/edit-product?productId=' + item._id+"&merchentId="+item.merchantId+"&page="+currentPage} className="btn btn-info btn-sm">Edit</Link>
                </>
              )}
            </td>
          </tr>
          </>
        ))}
    </>
  );
};

export default ProductList;
