import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout/Layout";
import { Col,Row,Card, CardBody,List,Form,Input,Label,Button } from "reactstrap";


const Merchant = () => {
 
  return (
    <>
        <Layout>
          <div className="content-wrapper">
            <Row className="page-header">
              <h3 className="page-title">Add New Merchant</h3>
              <nav aria-label="breadcrumb">
                <List tag="ol" className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to={"/dashboard"}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add New Merchant
                  </li>
                </List>
              </nav>
            </Row>
            <Row>
              <Col className="grid-margin stretch-card">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Add New Merchant</h4>
                    <Form className="forms-sample">
                        <Row>
                           <Col xs={12} md={6} lg={6} sm={6}>
                              <Col className="form-group">
                                 <Label htmlFor="firstName">First Name</Label>
                                 <Input  type="text" className="form-control" name="firstName" placeholder="Enter your name" />
                              </Col>
                           </Col>
                           <Col xs={12} md={6} lg={6} sm={6}>
                              <Col className="form-group">
                                 <Label htmlFor="email">Email ID</Label>
                                 <Input  type="email" className="form-control" name="email" placeholder="Enter email ID" />
                              </Col>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={12} md={6} lg={6} sm={6}>
                              <Col className="form-group">
                                 <Label htmlFor="firstName">First Name</Label>
                                 <Input  type="text" className="form-control" name="firstName" placeholder="Enter your name" />
                              </Col>
                           </Col>
                           <Col xs={12} md={6} lg={6} sm={6}>
                              <Col className="form-group">
                                 <Label htmlFor="email">Old Password</Label>
                                 <Input  type="email" className="form-control" name="email" placeholder="Enter email ID" />
                              </Col>
                           </Col>
                        </Row>
                      <Button type="submit" className="btn btn-gradient-primary mr-2">Register Merchant</Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
        </>
    );
};
export default Merchant;
