import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from "../Component/SearchInput";

let PageSize = 10;

const MerchantBusinessAmount = () => {
    const { id } = useParams();
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [userName, setUserName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)
    
    const getUserPayments = async (searchTerm='') => {
        try {
            const postData = {
                hathme: {
                    merchantId: id
                }
            };
            const config = getTokenPost('post', `merchantPaymentDetailById?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.success === 1) {
                const payments = dataResponse.data.result;
                setUserPayments(payments);
                setUserName(payments[0].userName);
            }
        } catch (error) {
            console.error("Error fetching merchant payments:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUserPayments(searchTerm);
        setSearch(false)
        // eslint-disable-next-line
    }, [Search]);

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userPayments.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userPayments]);
    const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      };
    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{userName} Payment History Details </h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Payment List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Payment List</h4> */}
                                    <SearchInput
                                     onChange={handleSearchChange}
                                     searchTerm ={searchTerm}
                                     placeholder = "Search By PaymentId"
                                     onClick ={() => setSearch(true)}/>
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Payment ID</th>
                                                    <th>Credit/Debit</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                    <th>Payment Date</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {currentItems.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{payment.paymentId}</td>
                                                        <td>
                                                            {payment.creditOrDebit === 1 ? (
                                                                <label className="badge badge-success">Credit</label>
                                                            ) : (
                                                                <label className="badge badge-warning">Debit</label>
                                                            )}
                                                        </td>
                                                        <td>{payment.amount}</td>

                                                        <td>
                                                            {payment.action === 1 ? (
                                                                <label className="">Wallet</label>
                                                            ) : payment.action === 2 ? (
                                                                <label className="">Place order</label>
                                                            ) : payment.action === 3 ? (
                                                                <label className="">Refund amount</label>
                                                            ) : payment.action === 7 ? (
                                                                <label className="">Green Point</label>
                                                            ) : null}
                                                        </td>

                                                        <td>{payment.createdAt}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userPayments.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default MerchantBusinessAmount;
