import { ALL_SIZE, ADD_SIZE, EDIT_SIZE, DELETE_SIZE } from "../Action/Size";

export default function allSize(state = {
  isFetching: true,
  isSuccess: false,
  payload: [],
  msg: "",
}, action) {
  switch (action.type) {
    case ALL_SIZE:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                msg: '',
                payload: action.payload
            });

        case ADD_SIZE:
            return Object.assign({}, state, {
                isSecess: action.isSucess,
                msg: ''
            });

        case EDIT_SIZE:
            return Object.assign({}, state, {
                isSucess: action.isSecess,
                msg: action.resMsg,
            });

        case DELETE_SIZE:
            return Object.assign({}, state, {
                isSucess: action.isSucess,
                msg: action.msg
            });
        default:
            return state;
}
}
