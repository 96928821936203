import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { deleteCategory } from '../Action/Category';
import ImageShowModal from "./ImageShowModal";
import { confirm } from "react-confirm-box";
import { deleteBanner, editBannerData } from "../Action/Banner";

const BannerList = ({ currentItems, currentPage, pageSize }) => {
    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");

    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
    }

    const dispatch = useDispatch();
    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No"
        }
    }

    const deleteBannerData = async (bannerId) => {
        const shouldDelete = await confirm("Are you sure you want to Delete this Banner?", options);
        if (shouldDelete) {
           dispatch(deleteBanner(bannerId));
        }
    }
    let navigate = useNavigate();
    const EditCategoryData = async (item) => {
        if(item.isActive == 1){
            const result = await confirm("Are you sure you want to Deactive", options);
            if(result){
                dispatch(editBannerData({isActive : `0`, id : item._id}))
            }
        }else{
            const result = await confirm("Are you sure you want to Active", options);
            if(result){
               dispatch(editBannerData( {isActive : `1`, id : item._id}))
            }

        }
    }
    const handleImageClick = (url) => {
        // Redirect to Google.com
        window.location.href = url;
      };
    return (
        <>
            {currentItems && currentItems.map((item, index) => (
                <tr key={item._id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                    <td>{item.title}</td>
                    <td>{item.bannerType == 4 ? 
                        <Link to={ `/merchant-details/${item.url}` }>{item.name}</Link>
                         : item.bannerType == 3 ?  item.name : <a href ={ item.url }>{item.url}</a>}</td>
                    <td >
                        {
                        // item.type == 1 ? 
                    // <a href={item.url ? item.url : ""} target="_blank" 
                    // rel="noopener noreferrer"
                    // >
                    item.bannerImage &&
                         <img src={item.bannerImage} alt="Banner Image"
                        //   onClick={() => showImage(item.image)} 
                          style={{ cursor: 'pointer',borderRadius:'10%', height: "130px", width: "130px"}}/>
                        //  </a>
                        //  :
                        //        <video controls style={{ height: "190px", width: "190px" }}>
                        //          <source src={item.bannerImage} type="video/mp4" />
                        //          Your browser does not support the video tag.
                        //        </video>
                    
                    }
                    </td>
                    <td>{item.type == 1 ? <label className="badge badge-info">App</label> : <label className="badge badge-success">Website</label>}</td> 
                    <td>{(item.isActive == 1 ? (<label className="badge badge-success">Active</label>) : (<label className="badge badge-danger">Deactive</label>))}</td>
                    <td>{item.createdAt}</td>
                    <td>
                       <Button className={(item.isActive == 1 ? ('btn btn-danger btn-sm') : ('btn btn-success btn-sm'))}  onClick={() => { EditCategoryData(item) }}>{(item.isActive == 1 ? 'inActive' : 'Active')}</Button>
                       &nbsp; 
                       {/* <Button className='btn btn-info btn-sm' onClick={() => { navigate("/add-banner?banner=" + item._id); }}>Edit</Button> */}
                       &nbsp;&nbsp;
                        <Button className='btn btn-danger btn-sm' onClick={() => { deleteBannerData(item._id)}}>Delete</Button>&nbsp;&nbsp;
                    </td>
                </tr>
            ))}
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />

        </>
    )
}

export default BannerList;