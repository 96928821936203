import React, { useState } from "react";
import { Button } from "reactstrap";
import { confirm } from "react-confirm-box";
import { useDispatch } from "react-redux";
import { approvedDeposit } from "../Action/Deposit";
import RejectDepositModel from "./RejectDepositModel";
import { Link } from "react-router-dom";


const DepositAmount = ({currentItems, currentPage, pageSize})=>{
    const [show, setShow] = useState(false);
    const [DataRequest, setDataRequest] = useState({title:'',depositId:''});
    const dispatch = useDispatch();
    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const ApprovedAccount = async (depositId) => {
        const result = await confirm("Are you sure! You want to Approve this deposit amount.", options);
        if (result) {
            dispatch(approvedDeposit(depositId));
        }
      }
      const RejectedAccount = async (depositId) => {
          const result = await confirm("Are you sure! You want to Reject this deposit amount.", options);
          if (result) {
            setDataRequest({title:"Reject Customer Deposit Amount",depositId:depositId});
              setShow(true);
            // dispatch(rejectDeposit(depositId,remark));
          }
      }
      // // 1 - customer, 2 - merchant, 3 - driver, 4 - staff
    return (
            <>
             {currentItems && currentItems.map((item,index) =>(
                <tr key={item._id}>
                   <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                    <td><Link to={ (item.userType == 1 ? '/user-details/': (item.userType == 2 ? '/merchant-details/' :'/driver-details/'))+ item.userId}> {item.name}</Link> </td>
                    {/* <td>{ (item.userType == 1 ? 'Customer' : (item.userType == 2 ? 'Merchant': (item.userType == 3 ? 'Driver':'Staff') )) }</td> */}
                    <td>{item.amount}</td>
                    <td>{ (item.modeOfPayment === "1" ? (<label className="badge badge-success">UPI</label>) :  (<label className="badge badge-success">NEFT</label>)) }</td>
                    <td>{item.referenceUtrNumber}</td>
                    <td>{ (item.status === "1" ? (<label className="badge badge-success">Approved</label>): (item.status === "0" ? (<label className="badge badge-info">Not Approved</label>): (<label className="badge badge-danger">Rejected</label>) )  ) }</td>
                    <td>{item.createdAt}</td>
                    <td>
                        { (item.status === "0" ? 
                          (<>
                          <Button className='btn btn-success btn-sm' onClick={() => ApprovedAccount(item._id)}>Approve</Button> 
                           &nbsp;&nbsp;<Button className='btn btn-danger btn-sm' onClick={() => RejectedAccount(item._id)}>Reject</Button>
                          </>) :''
                        )}
                       
                    </td>
                </tr>
            ))}
            <RejectDepositModel show={show} setShow={setShow} requestData={DataRequest} />
        </>
    )
}

export default DepositAmount;