import React,{ useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { approvedDriver } from "../Action/Driver";
import { useDispatch } from "react-redux";
import NotificationModal from "./NotificationModal";


const DriverList = ({currentItems, currentPage, pageSize,  selectedUserIds, setSelectedUserIds})=>{
    const dispatch = useDispatch();
    const [showPush, setPushShow] = useState(false);
    const [userId, setUserId] = useState('');
    const sendNotification = (userId) =>{
      setUserId(userId);
      setPushShow(true);
    }
    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const ApprovedAccount = async (driverId) => {
        const result = await confirm("Are you sure! You want to Approve this Driver.", options);
        if (result) {
            dispatch(approvedDriver(driverId,1));
        }
      }
      const RejectedAccount = async (driverId) => {
        const result = await confirm("Are you sure! You want to Reject this Driver.", options);
        if (result) {
          dispatch(approvedDriver(driverId,2));
        }
    }
    const handleDataSelection = (selectedItem) => {
      const isSelected = selectedUserIds.includes(selectedItem);
    
      if (isSelected) {
        setSelectedUserIds((prevSelectedData) =>
          prevSelectedData.filter((item) => item !== selectedItem)
        );
      } else {
        setSelectedUserIds((prevSelectedData) => [...prevSelectedData, selectedItem]);
      }
    };
    return (
            <>
             {currentItems && currentItems.map((item,index) =>(
                <tr key={item._id}>
                    <td>{((currentPage - 1) * pageSize + (index + 1) )}<input type="checkbox" onClick={() => handleDataSelection(item._id)}  checked={selectedUserIds.includes(item._id) ? true : false}/></td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{ !Number.isInteger(item.walletBalance) && Math.floor(item.walletBalance.toString().split(".")[1].length) > 2 ? item.walletBalance.toFixed(2) : item.walletBalance }</td>
                    <td>{ (item.isActive === 1 ? (<label className="badge badge-success">Active</label>): (<label className="badge badge-danger">Deactive</label>) ) }</td>
                    <td>{ (item.isProfileVerified === 1 ? (<label className="badge badge-success">Verified</label>): 
                       (item.isProfileVerified === 0 ? (<label className="badge badge-warning">Pending</label>) : (<label className="badge badge-danger">Rejected</label>) )
                     ) }</td>
                    <td>{item.createdAt}</td>
                    <td>
                        { (item.isProfileVerified === 0 ? 
                         (
                            <>
                            <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>&nbsp;
                            <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
                            </>
                          ):
                           (item.isProfileVerified === 1 ? 
                            (
                                <>
                                <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
                                </>
                              ) :  (
                                <>
                                <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>&nbsp;
                                </>
                              ))
                          )

                        }
                        <Link to={'/driver-details/'+item._id} className='btn btn-info btn-sm'>View</Link> &nbsp;
                        { item.isProfileVerified === 1 ? (<>
                          &nbsp;&nbsp;<Button className='btn btn-warning btn-sm' onClick={() => sendNotification(item._id)}> <i className="mdi mdi-bell menu-icon"></i></Button> 
                        </>) : ''}
                    </td>
                </tr>
            ))}
             <NotificationModal show={showPush} setShow={setPushShow} userId={userId} />
        </>
    )
}

export default DriverList;