import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import { toast } from "react-toastify";

const Profile = () => {
  const imageStyle = { width: "155px", height: "155px" };
  const [ProfileUpdate, setProfileUpdate] = useState({ name: '', email: '', mobile: '', profileImg: '' });
  const [UpdateStatus, setUpdateStatus] = useState(0);
  const [base64Image, setBase64Image] = useState(null)

  useEffect(() => {
    updateProfile();
    // eslint-disable-next-line 
  }, []);

  const updateProfile = async () => {
    const postData = {
      "hathme": ProfileUpdate
    }
    const config = getTokenPost('post', 'UpdateProfile', postData);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setProfileUpdate({ name: dataResponse.data.name, email: dataResponse.data.email, mobile: dataResponse.data.mobile, profileImg: '' });
      setBase64Image(dataResponse.data.profileImg)
      if (UpdateStatus === 1) {
        toast.success(dataResponse.resMsg);
        localStorage.setItem("user", JSON.stringify(dataResponse.data));
      }
    }
  }

  const eventHandle = (event) => {
    if (event.target.name === 'image') {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64Image(reader.result);
        let images = reader.result.split(",");
        setProfileUpdate({ ...ProfileUpdate, 'profileImg': images[1] });
      };
    } else {
      setProfileUpdate({ ...ProfileUpdate, [event.target.name]: event.target.value });
    }
  }

  const doSubmitRecords = (event) => {
    event.preventDefault();
    setUpdateStatus(1);
    updateProfile();
  }

  return (
    <>
      <Layout>
        <div className='content-wrapper'>
          <Row className='page-header'>
            <h3 className="page-title">Profile Update
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className='breadcrumb'>
                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Profile Update </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className='grid-margin stretch-card'>
              <Card>
                <CardBody>
                  <form className="forms-sample" onSubmit={(event) => doSubmitRecords(event)}>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input type="text" className="form-control" onChange={(event) => eventHandle(event)} name="name" placeholder="Enter name" value={ProfileUpdate.name} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Email</label>
                      <input type="email" className="form-control" value={ProfileUpdate.email} onChange={(event) => eventHandle(event)} name="email" placeholder="Enter email" readOnly />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Phone Number</label>
                      <input type="number" className="form-control" min = {0} step = "any" value={ProfileUpdate.mobile} onChange={(event) => eventHandle(event)} name="mobile" placeholder="Enter phone" />
                    </div>
                    <div className="form-group">
                      <label>Profile Image</label>
                      <input type="file" name="image" onChange={(event) => { eventHandle(event) }} className="form-control file-upload-info" placeholder="Upload Image" />
                      <img style={imageStyle} src={base64Image} alt="" />
                    </div>
                    <button type="submit" className="btn btn-gradient-primary mr-2">Update Profile</button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}
export default Profile;