import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandList, brandAddResult } from '../Action/Brand';
import BrandList from '../Component/BrandList';
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, Table, List, Button } from 'reactstrap';
import Form from '../Component/Form'
import Text from '../Component/Text';
import Modal from 'react-bootstrap/Modal';

let PageSize = 10;
const Brand = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.brand);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getBrandList());
  }, [dispatch]);

  // Add New Brand
  const [addNewBrand, setAddNewBrand] = useState({ name: '' });

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);

  const brandAdd = () => {
    setTitle('Add New Brand');
    setAddNewBrand({ name: '' });
    setShow(true);
  };

  const doSubmitBrand = (name) => {
    dispatch(brandAddResult(name));
    setShow(false);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  const initialValues = {
    name : ""
  }
  return (
    <Layout>
      <div className="content-wrapper">
        <Row className="page-header">
          <h3 className="page-title">Manage Brand List</h3>
          <nav aria-label="breadcrumb">
            <List tag="ol" className="breadcrumb">
              <li className="breadcrumb-item">
                {' '}
                <Link to={'/dashboard'}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Brand List
              </li>
            </List>
          </nav>
        </Row>
        <Row>
          <Col className="grid-margin stretch-card">
          <div className="container-fluid">
          <Row>
            <Col xs={12}>
            <Card>
              <CardBody>
                <Button
                  style={{ float: 'right' }}
                  className="btn btn-info btn-sm"
                  onClick={brandAdd}
                >
                  Add Brand
                </Button>
                {isFetching && payload.length === 0 ? (
                            <div className="table-responsive">

                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Add Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="4">No Records Found</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                ) : (
                  <>
                  <div className='table-responsive'>
                    <table className="table table-striped">
                      <thead>
                    
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Add Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <BrandList currentItems={currentTableData} />
                      </tbody>
                    </table>
                    </div>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={payload.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </>
                )}
              </CardBody>
            </Card>
                </Col>
            </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={setShow}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
          <Form
           enableReinitialize
           initialValues={initialValues}
           onSubmit={(event) => doSubmitBrand(event)}
          >
          <Modal.Body>
            <div className="form-group">
              <Text
              name = "name"
              label = 'Brand Name'
              placeholder = "Enter Brand Name"
              required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-gradient-primary mr-2">
              Submit
            </button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          </Form>
      </Modal>
    </Layout>
  );
};

export default Brand;
