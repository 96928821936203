import React, { useEffect, useState } from "react";
import { Link, useLocation,useNavigate} from 'react-router-dom';
import { useDispatch ,useSelector} from 'react-redux';
import Layout from "./Layout/Layout";
import queryString from 'query-string';
import { getProductModelStructure,addProductModel } from "../Action/AddProducts";
import { Col, Row, Card, CardBody, List,Input,Label,FormGroup,Form, } from "reactstrap";

const AddProduct = () => {
  const { merchentId } = queryString.parse(useLocation().search);
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.productModel);
  const [products, setProducts] = useState({
    categoryId:'',
    subcategory:0,
    subcategoryRequire:0,
    productName: 0,
    productRequire:0,
    productPrice: 0,
    priceRequire:  0,
    sellingPrice: 0,
    sellingPriceRequire: 0,
    offerPrice: 0,
    offerRequire: 0,
    unit: 0,
    unitRequire:  0,
    unitType: 0,
    weight: 0,
    weightRequire: 0,
    weightType: 0,
    quantity:0,
    quantityRequire:  0,
    quantityType:  0,
    bestBeforeDate:  0,
    bestBeforeDateRequire: 0,
    bestBeforeDateType:0,
    description: 0,
    descriptionRequire: 0,
    descriptionType:  0,
    specialFeature: 0,
    specialFeatureRequire:  0,
    brand: 0,
    brandRequire: 0,
    color: 0,
    colorRequire:0,
    size:  0,
    sizeRequire: 0,
    productNameType: 0,
    productPriceType: 0,
    sellingPriceType:  0,
    offerPriceType:0,
    specialFeatureType: 0,
    brandType:  0,
    colorType: 0,
    sizeType: 0,
    Mrp: 0,
    MrpType: 0,
    MrpRequire: 0,
    packedType: 0,
    packedTypeType:  0,
    packedTypeRequire: 0,
    expiryDate: 0,
    expiryDateType: 0,
    expiryDateRequire: 0,
    purchasePrice:0,
    purchasePriceType:0,
    purchasePriceRequire: 0,
    batchNumber:0,
    batchNumberType: 0,
    batchNumberRequire: 0,
    distributorName: 0,
    distributorNameType: 0,
    distributorNameRequire: 0,
    disclaimer: 0,
    disclaimerType: 0,
    disclaimerRequire: 0,
    purchaseInvoiceNo: 0,
    purchaseInvoiceNoType:  0,
    purchaseInvoiceNoRequire:  0,
    materialType:  0,
    materialTypeType: 0,
    materialTypeRequire: 0,
    aboutThisItem: 0,
    aboutThisItemType: 0,
    aboutThisItemTypeRequire:0,
    manufacturer:0,
    manufacturerType:0,
    manufacturerRequire:  0,
    shelfLife:  0,
    shelfLifeType:0,
    shelfLifeRequire: 0,
    fssaiLicense: 0,
    fssaiLicenseType: 0,
    fssaiLicenseRequire: 0,
    countryOfOrigin: 0,
    countryOfOriginType: 0,
    countryOfOriginRequire: 0,
    seller:0,
    sellerType: 0,
    sellerRequire:0,
    ingredients: 0,
    ingredientsType: 0,
    ingredientsRequire: 0,
    content: 0,
    contentType:  0,
    contentRequire: 0,
    licenseNo:0,
    licenseNoType:0,
    licenseNoRequire: 0,
    purchaseInvoiceDate:0,
    purchaseInvoiceDateType:  0,
    purchaseInvoiceDateRequire:0,
    preparationTime: 0,
    preparationTimeType:0,
    preparationTimeRequire: 0,
  });
  const [addProduct, setAddProduct] = useState({name:'',unit:'',weight:'',quantity:'',unitType:'',bestBefore:'',description:'',subCategoryId:'',price:'',sellingPrice:'',offerPrice:'',specialFeature:'',brand:'',color:'',size:'',Mrp:'',packedType:'',expiryDate:'',purchasePrice:'',batchNumber:'',distributorName:'',purchaseInvoiceNo:'', materialType:'',aboutThisItem:'', manufacturer:'',disclaimer:'',shelfLife:'', fssaiLicense:'', countryOfOrigin:'', seller:'', ingredients:'', content:'',licenseNo:'',purchaseInvoiceDate:'',preparationTime:''});
  const [subcategoryResult, setSubcategoryResult] = useState([]);
  const [brandResult, setBrandResult] = useState([]);
  const [colorResult, setColorResult] = useState([]);
  const [sizeResult, setSizeResult] = useState([]);
  const [UnitList, setUnitList] = useState([]);
  
  useEffect(() => {
    dispatch(getProductModelStructure(merchentId));
    setProducts(payload);
    setSubcategoryResult(payload.subcategoryResult);
    setBrandResult(payload.brandResult);
    setColorResult(payload.colorResult);
    setSizeResult(payload.sizeResult);
    setUnitList(payload.unitList);
  // eslint-disable-next-line
  },[isFetching]);

  const onchangeEvent = (event) => {
    setAddProduct({ ...addProduct, [event.target.name]: event.target.value.replace(/[^a-zA-Z.0-9 ]/g, '')  });
  }
  const [ProductImages, setProductImages] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(null);
let navigate = useNavigate();
const doSumbitCategory = (event) => {
  event.preventDefault();
  setAddProduct({ ...addProduct, purchasePrice: addProduct.price });
  const data = new FormData();
  if (isFileUploaded && isFileUploaded.length > 0) {
    for (let i = 0; i < isFileUploaded.length; i++) {
      data.append(`productImages`, isFileUploaded[i]);
    }
  }
  data.append('userId',merchentId);
  data.append('name',addProduct.name);
  data.append('unit',addProduct.unit);
  data.append('weight',addProduct.weight);
  data.append('quantity',addProduct.quantity);
  data.append('unitType',addProduct.unitType);
  data.append('bestBefore',addProduct.bestBefore);
  data.append('description',addProduct.description);
  data.append('subCategoryId',addProduct.subCategoryId);
  data.append('price',addProduct.price);
  data.append('sellingPrice',addProduct.sellingPrice);
  data.append('offerPrice',addProduct.offerPrice);
  data.append('specialFeature',addProduct.specialFeature);
  data.append('brand',addProduct.brand);
  data.append('color',addProduct.color);
  data.append('Mrp',addProduct.Mrp);
  data.append('packedType',addProduct.packedType);
  data.append('expiryDate',addProduct.expiryDate);
  data.append('purchasePrice',addProduct.purchasePrice);
  data.append('batchNumber',addProduct.batchNumber);
  data.append('distributorName',addProduct.distributorName);
  data.append('purchaseInvoiceNo',addProduct.purchaseInvoiceNo);
  data.append('materialType',addProduct.materialType);
  data.append('aboutThisItem',addProduct.aboutThisItem);
  data.append('manufacturer',addProduct.manufacturer);
  data.append('disclaimer',addProduct.disclaimer);
  data.append('shelfLife',addProduct.shelfLife);
  data.append('fssaiLicense',addProduct.fssaiLicense);
  data.append('seller',addProduct.seller);
  data.append('ingredients',addProduct.ingredients);
  data.append('content',addProduct.content);
  data.append('licenseNo',addProduct.licenseNo);
  data.append('purchaseInvoiceDate',addProduct.purchaseInvoiceDate);
  data.append('preparationTime',addProduct.preparationTime);
  data.append('countryOfOrigin',addProduct.countryOfOrigin);
  dispatch(addProductModel(data));
  navigate("/merchant-products-details/"+merchentId);
}
const formDataType = (type)=>{
     return (type == 1 ? 'text': (type == 2 ? 'number' : (type == 3 ? 'date' : 'time') ) )
}
const requiredType = (type)=>{
   if(type == 1){
    const attributes = {required: "required"};
    return attributes;
   } 
}
const handleFileChange = (e) => {
    setIsFileUploaded(e.target.files);
  };

  return (
    <>
      <Layout>
        <div className='content-wrapper'>
          <Row className='page-header'>
            <h3 className="page-title">Add New Product
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className='breadcrumb'>
                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add</li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className='grid-margin stretch-card'>
              <Card>
                <CardBody>
                <Form onSubmit={doSumbitCategory}>
                { products.subcategory == 1 ? (<>
                    <FormGroup>
                       <Label for="subcategory">Sub Category</Label>
                       <Input className="form-control" type="select" name="subCategoryId" onChange={(event) => { onchangeEvent(event) }} {...requiredType(1)}>
                         <option value={0} >Select Sub Category</option>
                         { subcategoryResult && subcategoryResult.map((item,index)=>(<option value={item._id} >{item.name}</option>)) }
                       </Input>
                    </FormGroup>
                    </>) :''}
                { products.productName == 1 ? (<>
                    <FormGroup>
                        <Label for="productName">Product Name</Label>
                        <Input
                        id="productName"
                        name="name"
                        placeholder="Enter product name"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.productNameType)}
                        {...requiredType(products.productRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.weight == 1 ? (<>
                    <FormGroup>
                        <Label for="weight">Weight</Label>
                        <Input
                        id="weight"
                        name="weight"
                        placeholder="Enter weight"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.weightType)}
                        min={products.weightType == 2 ? 0 : ''}
                        step ={products.weightType == 2 ? 'any' : ''}
                        {...requiredType(products.weightRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.unit == 1 ? (<>
                     <Row>
                       <Col md={6}>
                            <FormGroup>
                                <Label for="unit">Unit</Label>
                                <Input
                                id="unit"
                                name="unit"
                                placeholder="Enter unit"
                                onChange={(event) => { onchangeEvent(event) }}
                                min={products.unitType == 2 ? 0 : ''}
                                step ={products.unitType == 2 ? 'any' : ''}
                                type={formDataType(products.unitType)}
                                {...requiredType(products.unitRequire)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                            <Label for="unitType">Unit Type</Label>
                            <Input className="form-control" type="select" name="unitType" onChange={(event) => { onchangeEvent(event) }} {...requiredType(products.unitRequire)}>
                                <option value={0} >Unit Type</option>
                                { UnitList && UnitList.map((item,index)=>(<option value={item._id} >{item.unit}</option>)) }
                            </Input>
                            </FormGroup>
                        </Col>
                     </Row>
                      
                    </>) :''}
                   
                    { products.brand == 1 ? (<>
                    <FormGroup>
                        <Label for="brand">Brand Name</Label>
                        <Input className="form-control" type="select" name="brand" onChange={(event) => { onchangeEvent(event) }} {...requiredType(products.brandRequire)}>
                         <option value={0} >Select Brand</option>
                         { brandResult && brandResult.map((item,index)=>(<option value={item._id} >{item.name}</option>)) }
                       </Input>
                    </FormGroup>
                    </>) :''}
                    { products.color == 1 ? (<>
                    <FormGroup>
                        <Label for="color">Color Name</Label>
                         <Input className="form-control" type="select" name="color" onChange={(event) => { onchangeEvent(event) }} {...requiredType(products.colorRequire)}>
                         <option value={0} >Select Color</option>
                         { colorResult && colorResult.map((item,index)=>(<option value={item._id} >{item.name}</option>)) }
                       </Input>
                    </FormGroup>
                    </>) :''}
                    { products.size == 1 ? (<>
                    <FormGroup>
                        <Label for="size">Size Name</Label>
                        <Input className="form-control" type="select" name="size" onChange={(event) => { onchangeEvent(event) }} {...requiredType(products.sizeRequire)}>
                         <option value={0} >Select Size</option>
                         { sizeResult && sizeResult.map((item,index)=>(<option value={item._id} >{item.name}</option>)) }
                       </Input>
                    </FormGroup>
                    </>) :''}
                    
                    { products.quantity == 1 ? (<>
                    <FormGroup>
                        <Label for="quantity">Quantity</Label>
                        <Input
                        id="quantity"
                        name="quantity"
                        placeholder="Enter quantity"
                        onChange={(event) => { onchangeEvent(event) }}
                        min={products.quantityType == 2 ? 0 : ''}
                        step ={products.quantityType == 2 ? 'any' : ''}
                        type={formDataType(products.quantityType)}
                        {...requiredType(products.quantityRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   {/* {purchasePrice} */}
                    { products.productPrice == 1 ? (<>
                    <FormGroup>
                        <Label for="productPrice">Product Price</Label>
                        <Input
                        id="productPrice"
                        name="price"
                        placeholder="Enter product price"
                        min={products.productPriceType == 2 ? 0 : ''}
                        step ={products.productPriceType == 2 ? 'any' : ''}
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.productPriceType)}
                        {...requiredType(products.productRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.Mrp == 1 ? (<>
                    <FormGroup>
                        <Label for="Mrp">Product Mrp</Label>
                        <Input
                        id="Mrp"
                        name="Mrp"
                        min={products.MrpType == 2 ? 0 : ''}
                        step ={products.MrpType == 2 ? 'any' : ''}
                        placeholder="Enter product Mrp"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.MrpType)}
                        {...requiredType(products.MrpRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.sellingPrice == 1 ? (<>
                    <FormGroup>
                        <Label for="sellingPrice">Selling Price</Label>
                        <Input
                        id="sellingPrice"
                        name="sellingPrice"
                        min={products.sellingPriceType == 2 ? 0 : ''}
                        step ={products.sellingPriceType == 2 ? 'any' : ''}
                        placeholder="Enter selling price"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.sellingPriceType)}
                        {...requiredType(products.sellingPriceRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   

                    { products.offerPrice == 1 ? (<>
                    <FormGroup>
                        <Label for="offerPrice">Offer Price</Label>
                        <Input
                        id="offerPrice"
                        name="offerPrice"
                        min={products.offerPriceType == 2 ? 0 : ''}
                        step ={products.offerPriceType == 2 ? 'any' : ''}
                        placeholder="Enter offer price"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.offerPriceType)}
                        {...requiredType(products.offerRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.bestBeforeDate == 1 ? (<>
                    <FormGroup>
                        <Label for="bestBeforeDate">Best Before Date</Label>
                        <Input
                        id="bestBeforeDate"
                        name="bestBefore"
                        placeholder="Enter best before date"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.bestBeforeDateType)}
                        {...requiredType(products.bestBeforeDateRequire)}
                        />
                    </FormGroup>
                    </>) :''}


                    { products.packedType == 1 ? (<>
                    <FormGroup>
                        <Label for="packedType">Packed Type</Label>
                        <Input
                        id="packedType"
                        name="packedType"
                        placeholder="Enter packed type"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.packedTypeType)}
                        {...requiredType(products.packedTypeRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.purchaseInvoiceDate == 1 ? (<>
                    <FormGroup>
                        <Label for="purchaseInvoiceDate">Purchase Invoice Date</Label>
                        <Input
                        id="purchaseInvoiceDate"
                        name="purchaseInvoiceDate"
                        placeholder="Enter packed type"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.purchaseInvoiceDateType)}
                        {...requiredType(products.purchaseInvoiceDateRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.expiryDate == 1 ? (<>
                    <FormGroup>
                        <Label for="expiryDate">Expiry Date</Label>
                        <Input
                        id="expiryDate"
                        name="expiryDate"
                        placeholder="Enter expiry date"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.expiryDateType)}
                        {...requiredType(products.expiryDateRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.preparationTime == 1 ? (<>
                    <FormGroup>
                        <Label for="preparationTime">Preparation Time</Label>
                        <Input
                        id="preparationTime"
                        name="preparationTime"
                        placeholder="Enter preparation time"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.preparationTimeType)}
                        {...requiredType(products.preparationTimeRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.batchNumber == 1 ? (<>
                    <FormGroup>
                        <Label for="batchNumber">Batch Number</Label>
                        <Input
                        id="batchNumber"
                        name="batchNumber"
                        placeholder="Enter batch number"
                        onChange={(event) => { onchangeEvent(event) }}
                        min={products.batchNumberType == 2 ? 0 : ''}
                        step ={products.batchNumberType == 2 ? 'any' : ''}
                        type={formDataType(products.batchNumberType)}
                        {...requiredType(products.batchNumberRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                     
                    { products.distributorName == 1 ? (<>
                    <FormGroup>
                        <Label for="distributorName">Distributor Name</Label>
                        <Input
                        id="distributorName"
                        name="distributorName"
                        placeholder="Enter distributor name"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.distributorNameType)}
                        {...requiredType(products.distributorNameRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.purchaseInvoiceNo == 1 ? (<>
                    <FormGroup>
                        <Label for="purchaseInvoiceNo">Purchase Invoice No</Label>
                        <Input
                        id="purchaseInvoiceNo"
                        name="purchaseInvoiceNo"
                        placeholder="Enter purchase invoice No"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.purchaseInvoiceNoType)}
                        {...requiredType(products.purchaseInvoiceDateRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                     
                    { products.materialType == 1 ? (<>
                    <FormGroup>
                        <Label for="materialType">Material Type</Label>
                        <Input
                        id="materialType"
                        name="materialType"
                        placeholder="Enter material type"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.materialTypeType)}
                        {...requiredType(products.materialTypeRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.manufacturer == 1 ? (<>
                    <FormGroup>
                        <Label for="manufacturer">Manufacturer Name</Label>
                        <Input
                        id="manufacturer"
                        name="manufacturer"
                        placeholder="Enter manufacturer"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.manufacturerType)}
                        {...requiredType(products.manufacturerRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.licenseNo == 1 ? (<>
                    <FormGroup>
                        <Label for="licenseNo">License No</Label>
                        <Input
                        id="licenseNo"
                        name="licenseNo"
                        placeholder="Enter licenseNo"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.licenseNoType)}
                        {...requiredType(products.licenseNoRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.disclaimer == 1 ? (<>
                    <FormGroup>
                        <Label for="disclaimer">Disclaimer</Label>
                        <Input
                        id="disclaimer"
                        name="disclaimer"
                        placeholder="Enter disclaimer"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.disclaimerType)}
                        {...requiredType(products.disclaimerRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                     
                    { products.shelfLife == 1 ? (<>
                    <FormGroup>
                        <Label for="shelfLife">Shelf Life</Label>
                        <Input
                        id="shelfLife"
                        name="shelfLife"
                        placeholder="Enter shelf life"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.shelfLifeType)}
                        {...requiredType(products.shelfLifeRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.fssaiLicense == 1 ? (<>
                    <FormGroup>
                        <Label for="fssaiLicense">Fssai License</Label>
                        <Input
                        id="fssaiLicense"
                        name="fssaiLicense"
                        placeholder="Enter fssai license"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.fssaiLicenseType)}
                        {...requiredType(products.fssaiLicenseRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.countryOfOrigin == 1 ? (<>
                    <FormGroup>
                        <Label for="countryOfOrigin">Country Of Origin</Label>
                        <Input
                        id="countryOfOrigin"
                        name="countryOfOrigin"
                        placeholder="Enter countryOfOrigin"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.countryOfOriginType)}
                        {...requiredType(products.countryOfOriginRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.seller == 1 ? (<>
                    <FormGroup>
                        <Label for="seller">Seller Name</Label>
                        <Input
                        id="seller"
                        name="seller"
                        placeholder="Enter seller"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.sellerType)}
                        {...requiredType(products.sellerRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                   
                    { products.ingredients == 1 ? (<>
                    <FormGroup>
                        <Label for="ingredients">Ingredients</Label>
                        <Input
                        id="ingredients"
                        name="ingredients"
                        placeholder="Enter ingredients"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.ingredientsType)}
                        {...requiredType(products.ingredientsRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.content == 1 ? (<>
                    <FormGroup>
                        <Label for="content">Content</Label>
                        <Input
                        id="content"
                        name="content"
                        placeholder="Enter content"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.contentType)}
                        {...requiredType(products.contentRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    
                    { products.aboutThisItem == 1 ? (<>
                    <FormGroup>
                        <Label for="aboutThisItem">About This Item</Label>
                        <Input
                        id="aboutThisItem"
                        name="aboutThisItem"
                        placeholder="Enter about this item"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.aboutThisItemType)}
                        {...requiredType(products.aboutThisItemTypeRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.description == 1 ? (<>
                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input
                        id="description"
                        name="description"
                        placeholder="Enter description"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.descriptionType)}
                        {...requiredType(products.distributorNameRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    { products.specialFeature == 1 ? (<>
                    <FormGroup>
                        <Label for="specialFeature">Special Feature</Label>
                        <Input
                        id="specialFeature"
                        name="specialFeature"
                        placeholder="Enter special feature"
                        onChange={(event) => { onchangeEvent(event) }}
                        type={formDataType(products.specialFeatureType)}
                        {...requiredType(products.specialFeatureRequire)}
                        />
                    </FormGroup>
                    </>) :''}
                    <FormGroup>
                        <Label for="gallery">Product Images</Label>
                        <Input
                            id="product"
                            name="product"
                            type="file"
                            onChange={handleFileChange}
                            
                            multiple/>
                    </FormGroup>
                    <button className="btn btn-primary" type="submit">Add Product</button>
                </Form>  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default AddProduct;