
import {
    ALL_CATEGORY,
    ADD_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    SINGLE_CATEGORY,
    ALL_CATEGORY_MODEL
  } from "../Action/Category";

  export default function allCategory(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      
      case ALL_CATEGORY:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload
        });
      
      case ALL_CATEGORY_MODEL:
          return Object.assign({}, state, {
            isFetching: action.isFetching,
            msg: '',
            modelStructor:action.payload
          });
      case EDIT_CATEGORY:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: action.payload.resMsg,
        });
      case ADD_CATEGORY:
        return Object.assign({}, state, {
          isSecess: action.isSucess,
            msg: ''
        });
        case SINGLE_CATEGORY:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
            });

        case DELETE_CATEGORY:
        return Object.assign({}, state, {
            isSucess: action.isSucess,
            msg: action.msg
        });
      default:
        return state;
    }
  }