import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategory } from '../Action/Category';
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import CategoryListTool from '../Component/CategoryListTool';

let PageSize = 10;
const CategoryForm = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.category);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllCategory());
    // eslint-disable-next-line 
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt={'logo'} />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className='content-wrapper'>
              <Row className='page-header'>
                <h3 className="page-title">Manage Category Form
                </h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className='breadcrumb'>
                    <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Manage Category Form</li>
                  </List>
                </nav>
              </Row>
              <Row>
                <Col className='grid-margin stretch-card'>
                  <Card>
                    <CardBody>
                      <h4 className="card-title">Manage Category Form</h4>
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <CategoryListTool currentItems={currentTableData} />
                        </tbody>
                      </Table>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>)}
    </>
  )
}
export default CategoryForm;