import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost, getToken } from "../services/Token";

export const ALL_SIZE = "ALL_SIZE";
export const ADD_SIZE = "ADD_SIZE";
export const EDIT_SIZE = "EDIT_SIZE";
export const DELETE_SIZE = "DELETE_SIZE";

export function getSizeList() {
  return async (dispatch) => {
    const config = getToken('get', 'getSize')
    const response = await axios(config);
    const sizeList = response.data.hathme;
    const payload = sizeList.result;
    if (sizeList.success === 1) {
      dispatch({ type: ALL_SIZE, payload, isFetching: false });
    } else {
      dispatch({ type: ALL_SIZE, payload:[], isFetching: false });
      toast.error(payload.resMsg);
    }
  }
}

export function sizeAddResult(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    };
    const config = getTokenPost('post', 'addSize', postData); 
    const response = await axios(config);
    const payload = response.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: ADD_SIZE,
        message: payload.resMsg,
        isSuccess: true,
      });
      toast.success(payload.resMsg);
      dispatch(getSizeList());
    } else if (payload.success === 0) {
      toast.error(payload.resMsg);
      dispatch({
        type: ADD_SIZE,
        message: payload.resMsg,
        isSuccess: false,
      });
    }
  };
}

export function sizeUpdate(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    };
    const config = getTokenPost('put', 'updateSize', postData); 
    const response = await axios(config);
    const payload = response.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: EDIT_SIZE,
        message: payload.resMsg,
        isSuccess: true,
      });
      toast.success(payload.resMsg);
      dispatch(getSizeList());
    } else if (payload.success === 0) {
      toast.error(payload.resMsg);
      dispatch({
        type: EDIT_SIZE,
        message: payload.resMsg,
        isSuccess: false,
      });
    }
  };
}

export function deleteSize(sizeId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "sizeId": sizeId
      }
    };
    const config = getTokenPost('post', 'deleteSize', postData); 
    const response = await axios(config);
    const payload = response.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: DELETE_SIZE,
        message: payload.resMsg,
        isSuccess: true,
      });
      toast.success(payload.resMsg);
    }
    dispatch(getSizeList());
  };
}

export function activDeactivSize(sizeId, status) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "sizeId": sizeId,
        "status": status
      }
    };
    const config = getTokenPost('post', 'activeDeactiveSize', postData); 
    const response = await axios(config);
    const payload = response.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: DELETE_SIZE,
        message: payload.resMsg,
        isSuccess: true,
      });
      toast.success(payload.resMsg);
      dispatch(getSizeList());
    }
  };
}
