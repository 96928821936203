import { ALL_DOCUMENT, DELETE_DOCUMENT } from "../Action/Document";

export default function allDocument(state = {
    isFetching: true,
    isSucess: false,
    payload: []
}, action) {
    switch (action.type) {
        case ALL_DOCUMENT:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                msg: '',
                payload: action.payload

            });

        case DELETE_DOCUMENT:
            return Object.assign({}, state, {
                isSucess: action.isSucess,
                msg: action.msg,
            });

        default:
            return state;
    }
}
