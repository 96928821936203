import React, { useEffect, useState, useMemo } from 'react';
import { Link ,useParams} from 'react-router-dom';
import Layout from './Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getBanksList} from '../Action/Banks';
import BankList from '../Component/BankList';
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, Table, List, Button } from 'reactstrap';
// import loaderGif from '../assets/loader.gif';
import Modal from 'react-bootstrap/Modal';

let PageSize = 10;
const BanksList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.banks);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (id) {
        dispatch(getBanksList(id));
    }
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  return (
    <Layout>
      <div className="content-wrapper">
        <Row className="page-header">
          <h3 className="page-title">Banks Account List</h3>
          <nav aria-label="breadcrumb">
            <List tag="ol" className="breadcrumb">
              <li className="breadcrumb-item">
                {' '}
                <Link to={'/dashboard'}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Brand List
              </li>
            </List>
          </nav>
        </Row>
        <Row>
          <Col className="grid-margin stretch-card">
            <Card>
              <CardBody>
                   <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Bank Name</th>
                          <th>Account Number</th>
                          <th>Ifsc Code</th>
                          <th>Account Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <BankList currentItems={currentTableData} />
                      </tbody>
                    </Table>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={payload.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default BanksList;
