import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List, Table } from "reactstrap";
import { getTokenPost } from "../services/Token";
import axios from "axios";

const ModelStructureDetails = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState('');
  const getDetail = async () => {
    const config = getTokenPost("get", `productModelStructureDetails/${id}`);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setDetail(dataResponse.data);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">Model Structure Details</h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Model Structure Details
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <h4 className="card-title">Model Structure</h4>
                  {detail ? (
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Title</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>{detail.productName === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.productNameType === '1'
                              ? "Text Box"
                              : detail.productNameType === '2'
                                ? "Decimal Number"
                                : detail.productNameType === '3'
                                  ? "Date"
                                  : detail.productNameType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.productRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Product Price</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>2</td>
                          <td>{detail.productPrice === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.productPriceType === '1'
                              ? "Text Box"
                              : detail.productPriceType === '2'
                                ? "Decimal Number"
                                : detail.productPriceType === '3'
                                  ? "Date"
                                  : detail.productPriceType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.productPriceRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>
                        <tr>
                          <th>S.No</th>
                          <th>Selling Price</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>{detail.sellingPrice === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.sellingPriceType === '1'
                              ? "Text Box"
                              : detail.sellingPriceType === '2'
                                ? "Decimal Number"
                                : detail.sellingPriceType === '3'
                                  ? "Date"
                                  : detail.sellingPriceType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.sellingPriceRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Offer Price</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>{detail.offerPrice === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.offerPriceType === '1'
                              ? "Text Box"
                              : detail.offerPriceType === '2'
                                ? "Decimal Number"
                                : detail.offerPriceType === '3'
                                  ? "Date"
                                  : detail.offerPriceType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.offerRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Unit</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>5</td>
                          <td>{detail.unit === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.unitType === '1'
                              ? "Text Box"
                              : detail.unitType === '2'
                                ? "Decimal Number"
                                : detail.unitType === '3'
                                  ? "Date"
                                  : detail.unitType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.unitRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>


                        <tr>
                          <th>S.No</th>
                          <th>Weight</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>6</td>
                          <td>{detail.weight === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.weightType === '1'
                              ? "Text Box"
                              : detail.weightType === '2'
                                ? "Decimal Number"
                                : detail.weightType === '3'
                                  ? "Date"
                                  : detail.weightType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.weightRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>


                        <tr>
                          <th>S.No</th>
                          <th>Quantity</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>7</td>
                          <td>{detail.quantity === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.quantityType === '1'
                              ? "Text Box"
                              : detail.quantityType === '2'
                                ? "Decimal Number"
                                : detail.quantityType === '3'
                                  ? "Date"
                                  : detail.quantityType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.quantityRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Best Before Date</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>8</td>
                          <td>{detail.bestBeforeDate === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.bestBeforeDateType === '1'
                              ? "Text Box"
                              : detail.bestBeforeDateType === '2'
                                ? "Decimal Number"
                                : detail.bestBeforeDateType === '3'
                                  ? "Date"
                                  : detail.bestBeforeDateType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.bestBeforeDateRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Description</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>9</td>
                          <td>{detail.description === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.descriptionType === '1'
                              ? "Text Box"
                              : detail.descriptionType === '2'
                                ? "Decimal Number"
                                : detail.descriptionType === '3'
                                  ? "Date"
                                  : detail.descriptionType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.descriptionRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Special Feature</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>10</td>
                          <td>{detail.specialFeature === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.specialFeatureType === '1'
                              ? "Text Box"
                              : detail.specialFeatureType === '2'
                                ? "Decimal Number"
                                : detail.specialFeatureType === '3'
                                  ? "Date"
                                  : detail.specialFeatureType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.specialFeatureRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Brand</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>11</td>
                          <td>{detail.brand === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.brandType === '1'
                              ? "Text Box"
                              : detail.brandType === '2'
                                ? "Decimal Number"
                                : detail.brandType === '3'
                                  ? "Date"
                                  : detail.brandType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.brandRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Color</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>12</td>
                          <td>{detail.color === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.colorType === '1'
                              ? "Text Box"
                              : detail.colorType === '2'
                                ? "Decimal Number"
                                : detail.colorType === '3'
                                  ? "Date"
                                  : detail.colorType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.colorRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Size</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>13</td>
                          <td>{detail.size === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.sizeType === '1'
                              ? "Text Box"
                              : detail.sizeType === '2'
                                ? "Decimal Number"
                                : detail.sizeType === '3'
                                  ? "Date"
                                  : detail.sizeType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.sizeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Mrp</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>14</td>
                          <td>{detail.Mrp === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.MrpType === '1'
                              ? "Text Box"
                              : detail.MrpType === '2'
                                ? "Decimal Number"
                                : detail.MrpType === '3'
                                  ? "Date"
                                  : detail.MrpType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.MrpRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Packed Type</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>15</td>
                          <td>{detail.packedType === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.packedTypeType === '1'
                              ? "Text Box"
                              : detail.packedTypeType === '2'
                                ? "Decimal Number"
                                : detail.packedTypeType === '3'
                                  ? "Date"
                                  : detail.packedTypeType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.packedTypeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Expiry Date</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>16</td>
                          <td>{detail.expiryDate === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.expiryDateType === '1'
                              ? "Text Box"
                              : detail.expiryDateType === '2'
                                ? "Decimal Number"
                                : detail.expiryDateType === '3'
                                  ? "Date"
                                  : detail.expiryDateType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.expiryDateRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Purchase Price</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>17</td>
                          <td>{detail.purchasePrice === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.purchasePriceType === '1'
                              ? "Text Box"
                              : detail.purchasePriceType === '2'
                                ? "Decimal Number"
                                : detail.purchasePriceType === '3'
                                  ? "Date"
                                  : detail.purchasePriceType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.purchasePriceRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Batch No</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>18</td>
                          <td>{detail.batchNumber === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.batchNumberType === '1'
                              ? "Text Box"
                              : detail.batchNumberType === '2'
                                ? "Decimal Number"
                                : detail.batchNumberType === '3'
                                  ? "Date"
                                  : detail.batchNumberType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.batchNumberRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Distributor Name</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>19</td>
                          <td>{detail.distributorName === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.distributorNameType === '1'
                              ? "Text Box"
                              : detail.distributorNameType === '2'
                                ? "Decimal Number"
                                : detail.distributorNameType === '3'
                                  ? "Date"
                                  : detail.distributorNameType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.distributorNameRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Purchase Invoice No</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>20</td>
                          <td>{detail.purchaseInvoiceNo === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.purchaseInvoiceNoType === '1'
                              ? "Text Box"
                              : detail.purchaseInvoiceNoType === '2'
                                ? "Decimal Number"
                                : detail.purchaseInvoiceNoType === '3'
                                  ? "Date"
                                  : detail.purchaseInvoiceNoType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.purchaseInvoiceNoRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Purchase Invoice Date</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>21</td>
                          <td>{detail.purchaseInvoiceDate === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.purchaseInvoiceDateType === '1'
                              ? "Text Box"
                              : detail.purchaseInvoiceDateType === '2'
                                ? "Decimal Number"
                                : detail.purchaseInvoiceDateType === '3'
                                  ? "Date"
                                  : detail.purchaseInvoiceDateType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.purchaseInvoiceDateRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Material Type</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>22</td>
                          <td>{detail.materialType === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.materialTypeType === '1'
                              ? "Text Box"
                              : detail.materialTypeType === '2'
                                ? "Decimal Number"
                                : detail.materialTypeType === '3'
                                  ? "Date"
                                  : detail.materialTypeType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.materialTypeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>About This Item</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>23</td>
                          <td>{detail.aboutThisItem === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.aboutThisItemType === '1'
                              ? "Text Box"
                              : detail.aboutThisItemType === '2'
                                ? "Decimal Number"
                                : detail.aboutThisItemType === '3'
                                  ? "Date"
                                  : detail.aboutThisItemType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.aboutThisItemTypeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Manufacturer</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>24</td>
                          <td>{detail.manufacturer === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.manufacturerType === '1'
                              ? "Text Box"
                              : detail.manufacturerType === '2'
                                ? "Decimal Number"
                                : detail.manufacturerType === '3'
                                  ? "Date"
                                  : detail.manufacturerType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.manufacturerRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>disclaimer</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>25</td>
                          <td>{detail.disclaimer === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.disclaimerType === '1'
                              ? "Text Box"
                              : detail.disclaimerType === '2'
                                ? "Decimal Number"
                                : detail.disclaimerType === '3'
                                  ? "Date"
                                  : detail.disclaimerType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.disclaimerRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Shelf Life</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>26</td>
                          <td>{detail.shelfLife === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.shelfLifeType === '1'
                              ? "Text Box"
                              : detail.shelfLifeType === '2'
                                ? "Decimal Number"
                                : detail.shelfLifeType === '3'
                                  ? "Date"
                                  : detail.shelfLifeType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.shelfLifeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Fssai License</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>27</td>
                          <td>{detail.fssaiLicense === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.fssaiLicenseType === '1'
                              ? "Text Box"
                              : detail.fssaiLicenseType === '2'
                                ? "Decimal Number"
                                : detail.fssaiLicenseType === '3'
                                  ? "Date"
                                  : detail.fssaiLicenseType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.fssaiLicenseRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Country Of Origin</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>28</td>
                          <td>{detail.countryOfOrigin === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.countryOfOriginType === '1'
                              ? "Text Box"
                              : detail.countryOfOriginType === '2'
                                ? "Decimal Number"
                                : detail.countryOfOriginType === '3'
                                  ? "Date"
                                  : detail.countryOfOriginType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.countryOfOriginRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Seller</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>29</td>
                          <td>{detail.seller === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.sellerType === '1'
                              ? "Text Box"
                              : detail.sellerType === '2'
                                ? "Decimal Number"
                                : detail.sellerType === '3'
                                  ? "Date"
                                  : detail.sellerType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.sellerRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Ingredients</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>30</td>
                          <td>{detail.ingredients === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.ingredientsType === '1'
                              ? "Text Box"
                              : detail.ingredientsType === '2'
                                ? "Decimal Number"
                                : detail.ingredientsType === '3'
                                  ? "Date"
                                  : detail.ingredientsType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.ingredientsRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Content</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>31</td>
                          <td>{detail.content === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.contentType === '1'
                              ? "Text Box"
                              : detail.contentType === '2'
                                ? "Decimal Number"
                                : detail.contentType === '3'
                                  ? "Date"
                                  : detail.contentType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.contentRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>

                        <tr>
                          <th>S.No</th>
                          <th>Product License No</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>32</td>
                          <td>{detail.productLicenseNo === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.productLicenseNoType === '1'
                              ? "Text Box"
                              : detail.productLicenseNoType === '2'
                                ? "Decimal Number"
                                : detail.productLicenseNoType === '3'
                                  ? "Date"
                                  : detail.productLicenseNoType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.productLicenseNoRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>
                        <tr>
                          <th>S.No</th>
                          <th>Preparation Time</th>
                          <th>Type</th>
                          <th>Required</th>
                        </tr>

                        <tr>
                          <td>33</td>
                          <td>{detail.PreparationTime === '0' ? "No" : "Yes"}</td>
                          <td>
                            {detail.PreparationTimeType === '1'
                              ? "Text Box"
                              : detail.PreparationTimeType === '2'
                                ? "Decimal Number"
                                : detail.PreparationTimeType === '3'
                                  ? "Date"
                                  : detail.PreparationTimeType === '4'
                                    ? "Time"
                                    : ""}
                          </td>
                          <td>
                            {detail.PreparationTimeRequire === '0'
                              ? "No Required"
                              : "Required"}
                          </td>
                        </tr>


                      </tbody>
                    </Table>
                  ) : (
                    <p>Loading...</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default ModelStructureDetails;
