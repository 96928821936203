import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import ReactStars from "react-rating-stars-component";
import ImageShowModal from "../Component/ImageShowModal";

const ProductDetails = () => {
  const [ImageShow, setImageShow] = useState(false);
  const [ImgSrc, setImgSrc] = useState("");
  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  };
  const { productID } = useParams();
  const [product, setProduct] = useState({
    productId: "",
    name: "",
    description: "",
    unit: "",
    weight: "",
    recommended: "",
    price: "",
    rating: 0,
    inStock: 0,
    quantity: 0,
    imageOne: "",
    imageTwo: "",
    imageThree: "",
  });
  const getProduct = async () => {
    const postData = {
      hathme: {
        productId: productID,
      },
    };
    const config = getTokenPost("post", "ProductDetailById", postData);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setProduct(dataResponse.data);
    }
  };
  useEffect(() => {
    if (productID) {
      getProduct(productID);
    }
    // eslint-disable-next-line
  }, [productID]);
  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">Product Details</h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Product Details
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody className="p-3 table-responsive">
                  {/* <h4 className="card-title">Product Details</h4> */}
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Unit</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                        <th>In Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{product.name}</td>
                        <td>{product.unit}</td>
                        <td>{product.price}</td>
                        <td>{product.quantity}</td>
                        <td>{product.weight}</td>
                        <td>
                          {product.inStock === "1"
                            ? "In Stock"
                            : "out of stock"}
                        </td>
                      </tr>
                      <tr>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Unit Type</th>
                        <th>Rating</th>
                        <th>Best Before</th>
                        <th>Description</th>
                      </tr>
                      <tr>
                      <td>{product.category}</td>
                      <td>{product.subCategory ? product.subCategory : ""}</td>
                        <td>{product.unitType}</td>
                        <td>
                          { product.rating > 0 ? (<>
                            <ReactStars

                            count={5}
                            value={product.rating}
                            size={24}
                            edit={false}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                              />
                           </> ) : 'N/A' }
                        </td>
                        <td>{product.bestBefore}</td>
                        <td>{product.description}</td>
                      </tr>
                      <tr>
                        <th>Preparation time</th>
                        <th>Purchase Invoice Date</th>
                        <th>License No</th>
                        <th>Active Status</th>
                        <th>Selling Price</th>
                        <th>Offer Price</th>
                      </tr>
                      <tr>
                        <td>{product.preparationTime}</td>
                        {/* <td>
                            {product.status == 1
                              ? "Active"
                              : product.status == 2
                              ? "Not Active"
                              : product.status == 3
                              ? "Deleted"
                              : ""}
                          </td> */}
                        <td>{product.purchaseInvoiceDate}</td>
                        <td>{product.licenseNo}</td>
                        <td>
                          {product.status === "1"
                            ? "Active"
                            : product.status === "2"
                            ? "Inactive"
                            : product.status === "3"
                            ? "Deleted"
                            : ""}
                        </td>
                        <td>{product.sellingPrice}</td>
                        <td>{product.offerPrice}</td>
                      </tr>
                      <tr>
                        <th>Special Offer</th>
                        <th>Brand</th>
                        <th>Color</th>
                        <th>Size</th>
                        <th>Mrp</th>
                        <th>Packed Type</th>
                      </tr>
                      <tr>
                        <td>{product.specialFeature}</td>
                        <td>{product.brand}</td>
                        <td>{product.color}</td>
                        <td>{product.size}</td>
                        <td>{product.mrp}</td>
                        <td>{product.packedType}</td>
                      </tr>
                      <tr>
                        <th>Expiry Date</th>
                        <th>Purchase Price</th>
                        <th>Batch Number</th>
                        <th>Distributor Name</th>
                        <th>Purchase InvoiceNo</th>
                        <th>Material Type</th>
                      </tr>
                      <tr>
                        <td>{product.expiryDate}</td>
                        <td>{product.purchasePrice}</td>
                        <td>{product.batchNumber}</td>
                        <td>{product.distributorName}</td>
                        <td>{product.purchaseInvoiceNo}</td>
                        <td>{ product.materialType}</td>
                      </tr>
                      <tr>
                        <th>About This Item</th>
                        <th>Manufacturer</th>
                        <th>Disclaimer</th>
                        <th>Shelf Life</th>
                        <th>Ffssai License</th>
                        <th>Country Of Origin</th>
                      </tr>
                      <tr>
                        <td>{product.aboutThisItem}</td>
                        <td>{product.manufacturer}</td>
                        <td>{product.disclaimer}</td>
                        <td>{product.shelfLife}</td>
                        <td>{product.fssaiLicense}</td>
                        <td>{product.countryOfOrigin}</td>
                      </tr>
                      <tr>
                       
                        <th>Seller</th>
                        <th>Ingredients</th>
                        <th>Content</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        {/* <th>Is Product Approved</th> */}
                      </tr>
                      <tr>
                        
                        <td>{product.seller}</td>
                        <td>{product.ingredients}</td>
                        <td>{product.content}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {/* <td>{product.isProductApproved}</td> */}
                        {/* <td>
                          {product.isProductApproved === 0
                            ? "Pending"
                            : product.isProductApproved === 1
                            ? "Approved"
                            : product.isProductApproved === 3
                            ? "Rejected"
                            : ""}
                        </td> */}
                      </tr>
                    </tbody>
                  </Table><br></br>
                  <h4 className="card-title">Product Images</h4>
                  <Card>
                    <Row>
                      {
                        product.productImages && product.productImages.map((item,index)=>(
                         <>
                         <Col md={3}>
                          <img style={{width:'100%',height:'200px'}} src={item.imageUrl}/>
                        </Col>
                         </>
                        ))
                      }
                     
                    </Row>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
    </>
  );
};
export default ProductDetails;