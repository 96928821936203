import React , { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { deleteSubCategory } from '../Action/SubCategory';
import { subCategoryUpdateResult } from "../Action/SubCategory";
import { confirm } from "react-confirm-box";

const SubCategoryList = ({currentItems})=>{
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [title, settitle] = useState("");
    const handleClose = () => setShow(false);

    const [updateSubCategory, setUpdateubCategory] = useState({name:"",categoryId:'',subCategoryId:''})

    const subcategoryUpdate = (item)=>{
        settitle("Update Sub Category");
        setUpdateubCategory({name:item.name,categoryId:item.categoryId,subCategoryId:item._id});
        setShow(true);
    }

    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        } 
      }

    const deleteCategoryData = async(subCategoryId,categoryId)=>{
           const result = await confirm("Are you sure! You want to Delete.", options);
            if (result) {
                dispatch(deleteSubCategory(subCategoryId,categoryId));
            }
    }
    const doSumbitCategory = (event)=>{
        event.preventDefault();
        dispatch(subCategoryUpdateResult(updateSubCategory));
        setShow(false);
    }
    const onchangeEvent = (event)=>{
        setUpdateubCategory({...updateSubCategory, [event.target.name]: event.target.value});
    }
    return (
            <>
             {currentItems && currentItems.map((item) =>(
                <tr key={item._id}>
                <td>{item.name}</td>
                <td>{ (item.status === "1" ? (<label className="badge badge-success">Active</label>): (<label className="badge badge-danger">Deactive</label>) ) }</td>
                <td>{item.createdAt}</td>
                <td><Button className='btn btn-info btn-sm' onClick={()=>subcategoryUpdate(item)}>Edit</Button> <Button className='btn btn-danger btn-sm' onClick={()=>deleteCategoryData(item._id,item.categoryId)}>Delete</Button></td>
                </tr>
            ))}
                <Modal show={show} onHide={setShow}>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                            <form className="forms-sample" onSubmit={(event) => doSumbitCategory(event)}>
                            <Modal.Body>
                                <div className="form-group">
                                <label htmlFor="exampleInputName1">Sub Category Name</label>
                                <input type="text" className="form-control" value={updateSubCategory.name} name="name" onChange={(event)=>{onchangeEvent(event)}} id="exampleInputName1" placeholder="Sub Enter category Name" />
                                </div>
                            </Modal.Body>
                        <Modal.Footer>
                        <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
        </>
    )
}

export default SubCategoryList;