import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import { useDispatch } from "react-redux";
import { approveRejectUserKyc } from "../Action/User";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageShowModal from "../Component/ImageShowModal";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import axios from "axios";
import { confirm } from "react-confirm-box";
import ReactStars from "react-rating-stars-component";

const UserDetails = () => {
    
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        name: '',
        email: '',
        mobile: '',
        status: '',
        walletBalance: 0,
        profileImage: '',
        aadharCardBackPicture: '',
        aadharCardFrontPicture: '',
        aadharCardNumber: '',
        dateOfBirth: '',
        panCardNumber: '',
        panCardPicture: '',
        isProfileVerified: 0,
        redPoints: 0,
        greenPoints:0,
        uniqueID: '',
        userChatId: '',
        qrUrl: '',
        isActive:'',
        rating:0
    });
    const getSingleUser = async () => {
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('post', 'getUserDetails', postData);
            const response = await axios(config);
            const dataResponse = response.data.hathme;
            if (dataResponse.success === 1) {
                setUser(dataResponse.data);
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    useEffect(() => {
        getSingleUser();
        // eslint-disable-next-line
    }, []);

    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No"
        }
    }

    const approvedAccount = async (userId) => {
        const result = await confirm("Are you sure! You want to Approve this User Bank Detail.", options);
        if (result) {
            const promise1 = new Promise((resolve, reject) => {
                resolve( dispatch(approveRejectUserKyc(userId, 1)));
              });
            promise1.then(() => {
               dispatch(getSingleUser(userId));
            });
            // dispatch(getSingleUser(userId));
        }
    }

    const rejectedAccount = async (userId) => {
        const result = await confirm("Are you sure! You want to Reject this User Bank Detail.", options);
        if (result) {
            // await dispatch(approveRejectUserKyc(userId, 2))
            // dispatch(approveRejectUserKyc(userId, 2)).then( async () => {
            //     await dispatch(getSingleUser(userId));
            // });
            const promise1 = new Promise((resolve, reject) => {
                resolve(dispatch(approveRejectUserKyc(userId, 2)));
              });
              promise1.then((value) => {
                dispatch(getSingleUser(userId));
            });
        }
    }

    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");
    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
      }
    return (
        <>
            <Layout>
                <div className="content-wrapper">
                    <Row className="page-header">
                        <h3 className="page-title">{user.name} Detail</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    User Detail
                                </li>
                            </List>
                        </nav>
                    </Row>
                    <Row>
                        <Col className="grid-margin stretch-card">
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Manage User Details</h4> */}
                                    <Table className="table table-striped">
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Number</th>
                                                <th>Status</th>
                                                <th>Wallet Balance</th>
                                                <th>
                                                {/* Bank Details */}
                                                </th>
                                            </tr>
                                        <tbody>
                                            <tr>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.mobile}</td>
                                                <td>
                                                    {user.isActive === 1 ? (
                                                        <label className="badge badge-success">Active</label>
                                                    ) : (
                                                        <label className="badge badge-danger">Deactive</label>
                                                    )}
                                                </td>
                                                <td>{user.walletBalance}</td>
                                                <td>
                                                    {/* <Link  to={"/bank-list/" + user._id}><i className="mdi mdi-bank" style={{fontSize:"30px"}}></i></Link> */}
                                                    </td>
                                            </tr>
                                            <tr>
                                                <th>Unique ID</th>
                                                <th>Date Of Birth</th>
                                                <th>Red Points</th>
                                                <th>Green Points</th>
                                                <th>Profile Image</th>
                                                <th>
                                                    {/* QR Details */}
                                                    </th>
                                            </tr>
                                            <tr>
                                                <td>{user.uniqueID}</td>
                                                <td>{user.dateOfBirth}</td>
                                                <td>{user.redPoints}</td>
                                                <td>{user.greenPoints}</td>
                                                <td><LazyLoadImage src={user.profileImage}
                                                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                                alt={user.name}
                                                onClick={() => { showImage(user.profileImage) }}
                                                /></td>
                                                <td>
                                                    {/* <LazyLoadImage src={user.qrUrl}
                                                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                                alt={user.name}
                                                onClick={() => { showImage(user.qrUrl) }}
                                                /> */}
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <th>Rating</th>
                                            </tr>
                                            <tr>
                                                { user.rating > 0 ? (<>
                                                    <ReactStars
                                                    count={5}
                                                    size={24}
                                                    value={user.rating}
                                                    edit={false}
                                                    isHalf={false}
                                                    emptyIcon={<i className="far fa-star"></i>}
                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                    fullIcon={<i className="fa fa-star"></i>}
                                                    activeColor="#ffd700"
                                                />
                                                </> ) : '' }
                                                
                                            </tr>
                                        </tbody>

                                    </Table><br></br>
                                    {  user.aadharCardBackPicture != '' ? (
                                        <>
<h4 className="card-title">Documents Details</h4>
                                    <Table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Aadhar Card Number</th>
                                            <th>Aadhar Front</th>
                                            <th>Aadhar Back</th>
                                            <th>Pan Card Number</th>
                                            <th>Pan Card Picture</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tr>
                                                <td>{user.aadharCardNumber}</td>
                                                <td><LazyLoadImage src={user.aadharCardFrontPicture}
                                                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                                alt={user.aadharCardNumber}
                                                onClick={() => { showImage(user.aadharCardFrontPicture) }}
                                                /></td>
                                                <td><LazyLoadImage src={user.aadharCardBackPicture}
                                                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                                alt={user.aadharCardNumber}
                                                onClick={() => { showImage(user.aadharCardBackPicture) }}
                                                /></td>
                                                <td>{user.panCardNumber}</td>
                                                <td><LazyLoadImage src={user.panCardPicture}
                                                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                                                alt={user.panCardNumber}
                                                onClick={() => { showImage(user.panCardPicture) }}
                                                /></td>
                                                <td>
                                                <Button className={(user.isProfileVerified == 0 ? 'btn-sm btn btn-warning' : (user.isProfileVerified == 1 ? 'btn-sm btn btn-success' : 'btn-sm btn btn-danger') )} >{(user.isProfileVerified == 0 ? 'Under Process' : (user.isProfileVerified == 1 ? 'Approved' : 'Rejected') )}</Button>
                                                    
                                                </td>
                                                <td>
                                                    {(user.isProfileVerified === 0 ?
                                                        (
                                                            <>
                                                                <Button className="btn btn-success btn-sm" onClick={() => approvedAccount(user._id)}>Approve</Button>&nbsp;
                                                                <Button className="btn btn-danger btn-sm" onClick={() => rejectedAccount(user._id)}>Reject</Button>&nbsp;
                                                            </>
                                                        ) :
                                                        (user.isProfileVerified === 1 ?
                                                        (
                                                            <>
                                                            <Button className="btn btn-danger btn-sm" onClick={() => rejectedAccount(user._id)}>Reject</Button>&nbsp;
                                                            </>
                                                        ) : (
                                                            <>
                                                            <Button className="btn btn-success btn-sm" onClick={() => approvedAccount(user._id)}>Approve</Button>&nbsp;
                                                            </>
                                                        ))
                                                    )

                                                    }
                                                    </td>
                                            </tr>
                                        </Table><br/>
                                        </>
                                    ) : '' }
                                    <h4 className="card-title">Extra Details</h4>
                                    <Table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Video Details</th>
                                                <th>Green Points</th>
                                                <th>Red Points</th>
                                                <th>View Group</th>
                                                <th>Order History</th>
                                                <th>Transaction History</th>
                                            </tr>
                                        </thead>

                                        <td>
                                            <Link to={`/video-details/${user._id}`} className='btn btn-info btn-sm bg-gradient-success' >
                                                <i className="mdi mdi-youtube mdi-24px" style={{ color: 'white' }}></i>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/green-point-details/${user._id}`} className='btn btn-info btn-sm' style={{ backgroundColor: 'green' }}>
                                                <i className="mdi mdi-circle menu-icon" style={{ color: 'white' }}></i>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/red-point-details/${user._id}`} className='btn btn-info btn-sm' style={{ backgroundColor: 'red' }}>
                                                <i className="mdi mdi-circle menu-icon" style={{ color: 'white' }}></i>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/group-details/${user._id}`} className='btn btn-info btn-sm'>
                                                <i className="mdi mdi-account-group mdi-24px"></i>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/order-history/${user._id}`} className='btn btn-info btn-sm  bg-gradient-danger'>
                                                <i className="mdi mdi-file-document menu-icon mdi-24px"></i>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/transaction-history/${user._id}`} className='btn btn-info  bg-gradient-primary text-white mr-2 btn-sm'>
                                                <i className="mdi mdi-history menu-icon mdi-24px"></i>
                                            </Link>
                                        </td>
                                    </Table><br></br>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
        </>
    );
};

export default UserDetails;
