import React from "react";
import { Link } from "react-router-dom";
import paymentMethod from "../Action/paymentMethod";

const OrderList = ({ currentItems, currentPage, pageSize }) => {

    return (
        <>
            {currentItems && currentItems.map((item, index) => (
                <tr key={item.id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                    <td>{item.orderId}</td>
                    <td><Link to={'/merchant-details/' + item.merchantId}>{item.merchantName}</Link></td>
                    <td>{item.customerName}</td>
                    <td>
                        {/* <label className={(item.paymentMethod === 1 ? "badge badge-info" : (item.paymentMethod === 2 ? "badge badge-info" : "badge badge-info"))} >{(item.paymentMethod === 1 ? (<span>Wallet</span>) : (item.paymentMethod === 2 ? (<span>Online</span>) : (<span>COD</span>)))}</label> */}
                        <label className="badge badge-info">{paymentMethod(item.paymentMethod)}</label>
                    </td>                  
                    <td>
                        {item.orderStatus === '1' ? (
                            <label className="badge badge-warning">Pending</label>
                        ) : (
                            item.orderStatus === '2' ? (
                                <label className="badge badge-success">Accepted</label>
                            ) : (
                                item.orderStatus === '3' ? (
                                    item.cancelBy == 2 ? (
                                        <label className="badge badge-danger">Rejected</label>
                                    ) :
                                    (<label className="badge badge-danger">Cancel</label>)
                                ) : (
                                    item.orderStatus === '4' ? (
                                        <label className="badge badge-info">Driver reached pickup location</label>
                                    ) : (
                                        item.orderStatus === '5' ? (
                                            <label className="badge badge-info">Picked up</label>
                                        ) : (<label className="badge badge-success">Delivered</label>)
                                    )
                                )
                            )
                        )
                        }
                    </td>

                    <td>
                        {item.paymentStatus === 1 ? (
                            <label className="badge badge-success">Paid</label>
                        ) : (
                            <label className="badge badge-danger">Not Paid</label>
                        )}
                    </td>
                    <td>{item.finalAmount}</td>
                    <td>{item.createdAt}</td>
                    <td>
                        <Link to={'/order-details/' + item.id} className='btn btn-info btn-sm'>View</Link>&nbsp;
                    </td>
                </tr>
            ))}
        </>
    )
}

export default OrderList