import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import ImageShowModal from "../Component/ImageShowModal";

const GroupMembers = () => {
    const [ImageShow, setImageShow] = useState(false);
    const [ImgSrc, setImgSrc] = useState("");
    const [members, setMembers] = useState([]);

    const showImage = (getImag) => {
        setImgSrc(getImag);
        setImageShow(true);
    };

    const { groupId } = useParams();

    // Fetch group members' data
    const fetchGroupMembers = async () => {
        try {
            const postData = {
                hathme: {
                    groupId: groupId
                }
            };

            const config = getTokenPost("post", `getGroupMembers`, postData);
            const response = await axios(config);
            const membersData = response.data.data;

            setMembers(membersData);
        } catch (error) {
            console.error("Error fetching group members:", error.message);
        }
    };

    useEffect(() => {
        fetchGroupMembers();
        // eslint-disable-next-line 
    }, []);

    return (
        <>
            <Layout>
                <div className="content-wrapper">
                    <Row className="page-header">
                        <h3 className="page-title">Member Details</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Members Details
                                </li>
                            </List>
                        </nav>
                    </Row>
                    <Row>
                        <Col className="grid-margin stretch-card">
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Members Details</h4> */}
                                    <Table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Profile Image</th>
                                                <th>Joined</th>
                                                <th>Leave</th>
                                                <th>isAdmin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members.map(member => (
                                                <tr key={member._id}>
                                                    <td>{member.name}</td>
                                                    <td>
                                                        <img
                                                            src={member.profileImage}
                                                            alt={member.name}
                                                            onClick={() => showImage(member.profileImage)}
                                                            className="profile-image"
                                                        />
                                                    </td>
                                                    <td>{member.createdAt}</td>
                                                    <td>{member.leaveGroupStatus ? "Active" : "Inactive"}</td>
                                                    <td>{member.isAdminAllow ? "Yes" : "No"}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
            <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
        </>
    );
};

export default GroupMembers;

