import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button} from "reactstrap";
import { useDispatch } from 'react-redux';
import { confirm } from "react-confirm-box";
import { approvedMarchant, RejectMerchant } from '../Action/Merchant';
import ImageShowModal from "./ImageShowModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NotificationModal from "./NotificationModal";

const MerchantList = ({ currentItems,currentPage, pageSize, selectedUserIds, setSelectedUserIds  }) => {
  const [ImageShow, setImageShow] = useState(false);
  const [ImgSrc, setImgSrc] = useState("");
  const [showPush, setPushShow] = useState(false);
  const [userId, setUserId] = useState('');
  const [marchentIdForConfirmation, setMarchentIdForConfirmation] = useState(null);
  const [GetPageIndex, setCurrentPage] = useState(currentPage);
  useEffect(() => {
    if(currentPage == 1){
      currentPage = 0;
    }else{
      currentPage = currentPage * 10;
    }
    setCurrentPage(currentPage);
  },[currentPage]);
  const dispatch = useDispatch();
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  const showImage = (getImag) => {
    setImgSrc(getImag);
    setImageShow(true);
  }
  const sendNotification = (userId) =>{
    setUserId(userId);
    setPushShow(true);
  }
  
  const customRender = {
    render: (message, onConfirm, onCancel) => {
      return (
        <>

          <div className="rounded w-auto position-fixed bg-white" style={{ top: "20%", left : '40%', zIndex: 1000, padding: '20px', boxShadow: '10px'}}>
            <div className="p-2">
              <div className="py-2">

            <h6> Replace with {message} </h6>
              </div>

            <div className="d-flex justify-content-between w-25">
            <button className="btn btn-success btn-sm" onClick={onConfirm}> Yes </button>
            <button className="btn btn-danger btn-sm" onClick={onCancel}> No </button>
            </div>
            </div>
          </div>
        </>
      );
    }
  };

  const ApprovedAccount = async ( marchentId) => {
    const result = await confirm("Are you sure! You want to Approved this Account.", options);
    if (result) {
      dispatch(approvedMarchant(marchentId));
    }
   // toggleConfirmationModal(marchentId);
  };
  const RejectedAccount = async (marchentId) => {
    const result = await confirm("Are you sure! You want to Reject this Account.", options);
    if (result) {
      dispatch(RejectMerchant(marchentId));
    }

    //toggleConfirmationModal(marchentId);
  };

  // eslint-disable-next-line 
  // const handleReject = async () => {
  //   dispatch(RejectMerchant(marchentIdForConfirmation));
  // };
  const handleDataSelection = (selectedItem) => {
    const isSelected = selectedUserIds.includes(selectedItem);
  
    if (isSelected) {
      setSelectedUserIds((prevSelectedData) =>
        prevSelectedData.filter((item) => item !== selectedItem)
      );
    } else {
      setSelectedUserIds((prevSelectedData) => [...prevSelectedData, selectedItem]);
    }
  };
  return (
    <>
      {currentItems &&
        currentItems.map((item,index) => (
          <tr key={item._id}>
            <td>{((currentPage - 1) * pageSize + (index + 1) )}<input type="checkbox" onClick={() => handleDataSelection(item._id)}  checked={selectedUserIds.includes(item._id) ? true : false}/></td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.mobile}</td>
            <td>{item.businessName}</td>
            <td>{item.categories}</td>
            <td>{item.adminCommission}%</td>
            <td>
              {item.isActive === 1 ? (
                <label className="badge badge-success">Active</label>
              ) : (
                <label className="badge badge-danger">Deactive</label>
              )}
            </td>
            <td>
              {item.isProfileVerified === 1 ? (
                <label className="badge badge-success">Approved</label>
              ) : item.isProfileVerified === 2 ? (
                <label className="badge badge-danger">Rejected</label>
              ) : (
                <label className="badge badge-warning">Pending</label>
              )}
            </td>
            <td >
              <LazyLoadImage src={item.profileImage}
                style={{ height: "60px", width: "60px", cursor: 'pointer' }}
                alt={item.name}
                onClick={() => { showImage(item.profileImage) }}
              />
            </td>
            <td>{item.createdAt}</td>
            <td>
              {item.isProfileVerified == 1 ? (
                <>
              <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
              <Link to={"/merchant-details/" + item._id+"?page="+currentPage} className="btn btn-info btn-sm">View</Link>
                &nbsp;&nbsp;<Button className='btn btn-warning btn-sm' onClick={() => sendNotification(item._id)}> <i className="mdi mdi-bell menu-icon"></i></Button> 
                </>):item.isProfileVerified == 0 ? (
                  <>
                  <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>&nbsp;
                  <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
                  <Link to={"/merchant-details/" + item._id+"?page="+currentPage} className="btn btn-info btn-sm">View</Link>
                  &nbsp;&nbsp;<Button className='btn btn-warning btn-sm' onClick={() => sendNotification(item._id)}> <i className="mdi mdi-bell menu-icon"></i></Button> 

                  </>
                ) : ( <>
                  <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(item._id)}>Approve</Button>&nbsp;
                  <Button className="btn btn-danger btn-sm" onClick={() => RejectedAccount(item._id)}>Reject</Button>&nbsp;
                  <Link to={"/merchant-details/" + item._id+"?page="+currentPage} className="btn btn-info btn-sm">View</Link>
              </>) }
            </td>
          </tr>
        ))}
      
      <ImageShowModal show={ImageShow} setShow={setImageShow} ImgSrc={ImgSrc} />
      <NotificationModal show={showPush} setShow={setPushShow} userId={userId} />

      {/* Confirmation Modal */}
    </>
  );
};

export default MerchantList;

