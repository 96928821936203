import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost, getToken } from "../services/Token";
export const SEND_PUSH = "SEND_PUSH";

export function sendPushNotification(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('post', 'pushNotification', postData);
        const response = await axios(config);
        const payload = response.data.hathme;
        if (payload.success === 1) {
            toast.success(payload.resMsg);
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
        }
    };
}

export function sendToAllPushNotification(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('post', 'pushAllNotification', postData);
        const response = await axios(config);
        const payload = response.data.hathme;
        if (payload.success === 1) {
            toast.success(payload.resMsg);
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
        }
    };
}

export function groupByNotifications(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('post', 'groupByNotifications', postData);
        const response = await axios(config);
        const payload = response.data.hathme;
        if (payload.success === 1) {
            toast.success(payload.resMsg);
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
        }
    };
}