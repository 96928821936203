import React from "react";
import { Link } from "react-router-dom";

const NavigationCard = (props) => {
    const {className, name, redirect, icon} = props
    return (
        <li className={className}>
        <Link to={redirect} className="nav-link">
          <span className="menu-title">{name}</span>
          <i className={`mdi ${icon} menu-icon`}></i>
        </Link>
      </li>
    )
}
export default NavigationCard