import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost,getToken } from "../services/Token";

// export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
// export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
// export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const ALL_SETTING = "ALL_SETTING";
export const SINGLE_SETTING = "SINGLE_SETTING";

export function getSettingList() {
  return async (dispatch, getState) => {
    const config = getToken('get','getProjectSetting');
    const responsive = await axios(config);
    const settingList = responsive.data.hathme;
    const payload =  [];
    if (settingList.success === 1) {
      const payload = settingList.data;
      dispatch({ type: ALL_SETTING, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_SETTING,payload:[] ,isFetching: false });
      toast.error(settingList.resMsg);
    }
  }
} 

export function getSettingDetails(settingId) {
  return async (dispatch) => {
    const postData = {
      "hathme":{settingId:settingId}
    } 
    const config = getTokenPost('post','getProjectSettingDetails',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success === 1) {
      dispatch({
        type: SINGLE_SETTING,
        message: payload.resMsg,
        isSucess: false,
      });
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}

// export function subCategoryUpdateResult(payloadData) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme":payloadData
//     } 
//     const config = getTokenPost('post','UpdateSubCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success === 1) {
//       dispatch({
//         type: EDIT_SUB_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//       dispatch(getSubCategoryList({categoryId:payloadData.categoryId}));
//     }else if(payload.success === 0){
//       toast.error(payload.resMsg);
//     }
//   };
// }


// export function deleteSubCategory(subCategoryId,categoryId) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme": {
//         "subCategoryId":subCategoryId
//       }
//     } 
//     const config = getTokenPost('post','DeleteSubCategory',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success === 1) {
//       dispatch({
//         type: DELETE_SUB_CATEGORY,
//         message: payload.resMsg,
//         isSucess: false,
//       });
//       toast.success(payload.resMsg);
//     }
//     dispatch(getSubCategoryList({categoryId:categoryId}));
//   };
// }

