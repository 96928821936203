import {
  ALL_USER,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  SINGLE_USER,
  KYC_USER
} from "../Action/User";

export default function allUser(state = {
  isFetching: true,
  isSucess: false,
  payload: [],
}, action) {
  switch (action.type) {
    case ALL_USER:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        msg: '',
        payload: action.payload

      });
    case EDIT_USER:
      return Object.assign({}, state, {
        isSucess: action.isSecess,
        msg: action.payload.resMsg,
      });
    case ADD_USER:
      return Object.assign({}, state, {
        isSecess: action.isSucess,
        msg: ''
      });

      case SINGLE_USER:
        if (!action.payload) {
        }
        return Object.assign({}, state, {
          isStatus: action.isFetching,
          userDetails: action.payload,
        });
      
        // case SINGLE_USER:
        //   return Object.assign({}, state, {
        //     isFetching: false,
        //     msg: '',
        //   });
    case DELETE_USER:
      return Object.assign({}, state, {
        isSucess: action.isSucess,
        msg: action.msg
      });
    case KYC_USER:
      return Object.assign({}, state, {
        isSucess: action.isSucess,
        msg: action.msg
      });
      
    default:
      return state;
  }
}