import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "REGISTER_SUCCESS";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const SINGLE_CATEGORY = "SINGLE_CATEGORY";
export const ALL_CATEGORY_MODEL = "ALL_CATEGORY_MODEL";

export function getAllCategory(searchTerm) {
  return async (dispatch, getState) => {
    const config = getToken('get','GetAllCategory', searchTerm);
    const responsive = await axios(config);
    const categoriesList = responsive.data.hathme;
    const payload = categoriesList.data;
    if (categoriesList.success === 1) {
      dispatch({ type: ALL_CATEGORY, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_CATEGORY, isFetching: false});
    }
  }
}
export function getAllCategoryModel(searchTerm) {
  return async (dispatch, getState) => {
    const config = getToken('get','productModelStructureList', searchTerm);
    const responsive = await axios(config);
    const modelData = responsive.data.hathme;
    const payload = modelData.data;
    if (modelData.success === 1) {
      dispatch({ type: ALL_CATEGORY_MODEL, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_CATEGORY_MODEL, isFetching: false});
    }
  }
}

export function addCategory(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post','addCategory',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}

export function deleteCategory(id,status) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "categoryId":id,
        status:status
      }
    } 
    const config = getTokenPost('post','deleteCategory',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCategory());
    }
  };
}


export function editCategory(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post','updateCategory',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_CATEGORY,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCategory());
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
    }
  };
}


// export function getSingleCategory(id) {
//   return async (dispatch) => {
//     const responsive = await axios.get(`${BaseURL}/GetSingleCategory/:${id}`);
//     const categoriesList = responsive.data.hathme;
//     const payload = categoriesList.data;
//     if (categoriesList.resCode == 1) {
//       dispatch({ type: SINGLE_CATEGORY, payload, isFetching: false });
//     }
//   };
// }




 // inbuilt-notification
//  toast.warning('Danger')
//  // inbuilt-notification
//  toast.success('successful')
//  // inbuilt-notification
//  toast.info('GeeksForGeeks')
//  // inbuilt-notification
//  toast.error('Runtime error')
//  // default notification
//  toast('Hello Geeks')