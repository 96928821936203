import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import { Link, useParams } from 'react-router-dom';
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import axios from 'axios';
import { getTokenPost } from '../services/Token';

let PageSize = 10;

const MerchantOrders = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
    const [merchantOrders, setMerchantOrders] = useState([]);
    const [userName, setUserName] = useState("");

    const { id } = useParams();

    const statusOptions = [
        { value: '', label: 'Status Filter' },
        { value: '1', label: 'Pending' },
        { value: '2', label: 'Accepted' },
        { value: '3', label: 'Cancel' },
        { value: '4', label: 'Driver reached pickup location' },
        { value: '5', label: 'Picked up' },
        { value: '6', label: 'Delivered' },
    ];

    // Function to fetch merchant orders
    const fetchMerchantOrders = async () => {
        try {
            const postData = {
                hathme: {
                    merchantId: id
                }
            };

            const config = getTokenPost("post", `orderListByMerchantId`, postData);
            const response = await axios(config);
            const orderData = response.data.hathme.data;
            const merchantName = orderData[0]?.userName || ""; // Extract the userName from the first order

            if (Array.isArray(orderData) && orderData.length > 0) {
                setMerchantOrders(orderData);
                setUserName(merchantName); // Set the userName

            } else {
                setMerchantOrders([]); // empty array if no orders are found
                setUserName(""); // Set the userName to an empty string
            }

        } catch (error) {
            console.error("Error fetching merchant orders:", error.message);
        }
        setLoading(false);
    };

    const currentItems = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        // Filter orders based on selectedStatusFilter
        const filteredOrders = selectedStatusFilter
            ? merchantOrders.filter((order) => order.orderStatus.toString() === selectedStatusFilter)
            : merchantOrders;

        return filteredOrders.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, merchantOrders, selectedStatusFilter]);

    useEffect(() => {
        fetchMerchantOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{userName} Merchant Orders</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Order List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    {/* <h4 className="card-title">Merchant Order List</h4> */}
                                    <div className="float-right col-md-3">
                                        <select
                                            className="form-control"
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                color: "black",
                                                border: "1px solid #333",
                                                borderRadius: "5px",
                                            }}
                                            value={selectedStatusFilter}
                                            onChange={(e) => setSelectedStatusFilter(e.target.value)}
                                        >
                                            {statusOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Payment Method</th>
                                                    <th>Status</th>
                                                    <th>Payment Status</th>
                                                    <th>Total Amount</th>
                                                    <th>Order Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((order) => (
                                                    <tr key={order.orderId}>
                                                        <td>{order.orderId}</td>

                                                        <td><label className={(order.paymentMethod === 1 ? "badge badge-info" : (order.paymentMethod === 2 ? "badge badge-info" : "badge badge-info"))} >{(order.paymentMethod === 1 ? (<span>Wallet</span>) : (order.paymentMethod === 2 ? (<span>Online</span>) : (<span>COD</span>)))}</label></td>

                                                        <td>
                                                            {order.orderStatus === '1' ? (
                                                                <label className="badge badge-warning">Pending</label>
                                                            ) : (
                                                                order.orderStatus === '2' ? (
                                                                    <label className="badge badge-success">Accepted</label>
                                                                ) : (
                                                                    order.orderStatus === '3' ? (
                                                                        <label className="badge badge-danger">Cancel</label>
                                                                    ) : (
                                                                        order.orderStatus === '4' ? (
                                                                            <label className="badge badge-info">Driver reached pickup location</label>
                                                                        ) : (
                                                                            order.orderStatus === '5' ? (
                                                                                <label className="badge badge-info">Picked up</label>
                                                                            ) : (<label className="badge badge-success">Delivered</label>)
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            }
                                                        </td>

                                                        <td>
                                                            {order.paymentStatus === 1 ? (
                                                                <label className="badge badge-success">Paid</label>
                                                            ) : (
                                                                <label className="badge badge-danger">Not Paid</label>
                                                            )}
                                                        </td>

                                                        <td>{order.finalAmount}</td>
                                                        <td>{order.createdAt}</td>

                                                        <td>
                                                            <Link to={'/order-details/' + order.order_Id} className="btn btn-info btn-sm">View</Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}

                                    {selectedStatusFilter === "" ? (
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={merchantOrders.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default MerchantOrders;
