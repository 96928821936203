import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";
export const APPROVE_DEPOSIT = "APPROVE_DEPOSIT";
export const REJECTED_DEPOSIT = "REJECTED_DEPOSIT";
// export const EDIT_DRIVER = "EDIT_DRIVER";
export const ALL_DEPOSIT = "ALL_DEPOSIT";
// export const SINGLE_DRIVER = "SINGLE_DRIVER";
export function approvedDeposit(depositId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "depositId":depositId
      }
    } 
    const config = getTokenPost('post','approveDepositAmount',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: APPROVE_DEPOSIT,
        message: payload.resMsg,
        isSucess: false,});
      toast.success(payload.resMsg);
      dispatch(getAllDeposit());
    }
  };
}

export function rejectDeposit(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    } 
    const config = getTokenPost('post','rejectDepositAmount',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: REJECTED_DEPOSIT,
        message: payload.resMsg,
        isSucess: false,});
      toast.success(payload.resMsg);
      dispatch(getAllDeposit());
    }
  };
}

export function getAllDeposit(searchTerm='', selectedStatusFilter='') {
  return async (dispatch, getState) => {
    const config = getToken('get',`listDepositAmount?searchTerm=${searchTerm}&status=${selectedStatusFilter}`);
    const responsive = await axios(config);
    const depositAmount = responsive.data.hathme;
    const payload = depositAmount.data;
    if (depositAmount.success == 1) {
      dispatch({ type: ALL_DEPOSIT, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_DEPOSIT, isFetching: false,payload:[] });
    }
  };
}