import { toast } from "react-toastify";
import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";
export const APPROVE_WITHDRAWAL = "APPROVE_WITHDRAWAL";
export const REJECTED_WITHDRAWAL = "REJECTED_WITHDRAWAL";
// export const EDIT_DRIVER = "EDIT_DRIVER";
export const ALL_WITHDRAWAL = "ALL_WITHDRAWAL";
// export const SINGLE_DRIVER = "SINGLE_DRIVER";
// export function approvedDeposit(depositId) {
//   return async (dispatch) => {
//     const postData = {
//       "hathme": {
//         "depositId":depositId
//       }
//     } 
//     const config = getTokenPost('post','approveDepositAmount',postData);
//     const responsive = await axios(config);
//     const payload  = responsive.data.hathme;
//     if (payload.success == 1) {
//       dispatch({
//         type: APPROVE_DEPOSIT,
//         message: payload.resMsg,
//         isSucess: false,});
//       toast.success(payload.resMsg);
//       dispatch(getAllDeposit());
//     }
//   };
// }

export function rejectWithdrawal(payloadData,type) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post','withdrawal-rejected',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: REJECTED_WITHDRAWAL,
        message: payload.resMsg,
        isSucess: false,});
      toast.success(payload.resMsg);
      if(type == 1){
        dispatch(getAllWithdrawal());
      }else{
        dispatch(getAllDriverWithdrawal());
      }
    }
  };
}

export function approvedWithdrawal(payloadData,type) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post','withdrawal-approved',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: REJECTED_WITHDRAWAL,
        message: payload.resMsg,
        isSucess: false,});
      toast.success(payload.resMsg);
      if(type == 1){
        dispatch(getAllWithdrawal());
      }else{
        dispatch(getAllDriverWithdrawal());
      }
    }
  };
}

export function getAllWithdrawal(search='') {
  return async (dispatch, getState) => {
    const config = getToken('get','withdrawals?search='+search);
    const responsive = await axios(config);
    const WithdrawalAmount = responsive.data.hathme;
    const payload = WithdrawalAmount.data;
    if (WithdrawalAmount.resCode == 1) {
      dispatch({ type: ALL_WITHDRAWAL, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_WITHDRAWAL, isFetching: false,payload:[] });
    }
  };
}

export function getAllDriverWithdrawal(search='') {
  return async (dispatch, getState) => {
    const config = getToken('get','driver-withdrawal?search='+search);
    const responsive = await axios(config);
    const WithdrawalAmount = responsive.data.hathme;
    const payload = WithdrawalAmount.data;
    if (WithdrawalAmount.resCode == 1) {
      dispatch({ type: ALL_WITHDRAWAL, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_WITHDRAWAL, isFetching: false,payload:[] });
    }
  };
}