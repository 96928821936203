import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";
import { toast } from "react-toastify";
export const ALL_COUPON = "ALL_COUPON";
export const ADD_COUPON = "ADD_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";



export function getAllCoupon(searchTerm) {
  return async (dispatch) => {
    const config = getToken('get','getAllCoupon', searchTerm);
    const responsive = await axios(config);
    const couponList = responsive.data.hathme;
    const payload = couponList.data;
    if (couponList.success == 1) {
      dispatch({ type: ALL_COUPON, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_COUPON, isFetching: false,payload:[] });
    }
  };
}

export function addCoupon(payloadData) {
  
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('post','addCoupon',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({
        type: ADD_COUPON,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCoupon());
      return payload.resCode
    }else if(payload.resCode === 0){
      toast.error(payload.resMsg);
      return payload.resCode
    }
  };
}


export function editCoupon(payloadData,id) {
  return async (dispatch) => {
    const postData = {
      "hathme":payloadData
    } 
    const config = getTokenPost('put',`updateCoupon/${id}`,postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      toast.success(payload.resMsg);
      dispatch({
        type: EDIT_COUPON,
        message: payload.resMsg,
        isSucess: false,
      });
      dispatch(getAllCoupon());
      return payload.success
    }else if(payload.success === 0){
      toast.error(payload.resMsg);
      return payload.success
    }
  };
}


export function deleteCoupon(id) {
  return async (dispatch) => {

    const config = getTokenPost('post',`deleteCoupon/${id}`);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_COUPON,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllCoupon());
    }
  };
}
