import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { getTokenPost } from "../services/Token";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import SearchInput from '../Component/SearchInput';
import axios from "axios";

const GroupDetails = () => {
    const [groupList, setGroupList] = useState([]);
    const { id } = useParams();
    const [userName, setUserName] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [Search, setSearch] = useState(false)
    let PageSize = 10;
    const fetchUserGroupList = async (searchTerm='') => {
        try {
            const postData = {
                hathme: {
                    userId: id
                }
            };
            const config = getTokenPost('post', `getUserGroups?searchTerm=${searchTerm}`, postData);
            const response = await axios(config);
            const groupResponse = response.data.hathme;

            if (groupResponse.resCode === 0) {
                // User has not created any groups
                setGroupList([]);
            } else {
                // User has created groups
                const { userName, data } = groupResponse; // Destructure userName and data

                setUserName(userName); // Set userName state
                setGroupList(data); // Set groupList state
            }
        } catch (error) {
            console.error("Error fetching user group list:", error);
        }
    };

    useEffect(() => {
        fetchUserGroupList(searchTerm)
        setSearch(false)
        // eslint-disable-next-line 
    }, [Search]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return groupList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage, groupList]);
    
      const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      }

    return (
        <>
            <Layout>
                <div className="content-wrapper">
                    <Row className="page-header">
                        <h3 className="page-title">{userName} Group Detail</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Group Detail
                                </li>
                            </List>
                        </nav>
                    </Row>
                    <Row>
                        <Col className="grid-margin stretch-card">
                            <Card>
                                <CardBody>
                                <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search By Group Name"
                      onClick ={() => setSearch(true)}
                      />
                                    {/* <h4 className="card-title">Manage Group Details</h4> */}
                            <div className="table-responsive">
                                   
                                    <Table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Group Name</th>
                                                <th>Created</th>
                                                <th>Admin</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {currentTableData.map(group => (
                                                <tr key={group._id}>
                                                    <td>{group.name}</td>
                                                    <td>{group.createdAt}</td>
                                                    <td>{group.creator}</td>

                                                    <td>
                                                        <Link to={`/group-members/${group._id}`} className='btn btn-info btn-sm'>View Members</Link>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>

                                    </Table>
                                    </div>
                                    <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={groupList.length}
                                  pageSize={PageSize}
                                  onPageChange={page => setCurrentPage(page)} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default GroupDetails;
