import React from 'react';
import Text from "./Text";

const Number = (props) => {
    const {name, label, required, placeholder, onChange, error} = props
    return (
        <Text name = {name} label = {label} required={required} error = {error} type = "number" min = {0} step = "any" placeholder = {placeholder} onChange = {onChange}/>
    )
}

export default Number