import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { colorUpdate, deleteColor, activDeactivColor } from "../Action/Color"; // Import appropriate color-related actions
import { confirm } from "react-confirm-box";

const ColorList = ({ currentItems }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const handleClose = () => setShow(false);

    const [updateColor, setUpdateColor] = useState({ name: "", colorId: "" });

    const colorUpdateHandler = (item) => {
        setTitle("Update Color");
        setUpdateColor({ name: item.name, colorId: item._id });
        setShow(true);
    };

    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No",
        },
    };

    const activateColor = async (colorId) => {
        const result = await confirm("Are you sure you want to activate this color?", options);
        if (result) {
            dispatch(activDeactivColor(colorId, 1)); // Update with the correct action for activating a color
        }
    };

    const deactivateColor = async (colorId) => {
        const result = await confirm("Are you sure you want to deactivate this color?", options);
        if (result) {
            dispatch(activDeactivColor(colorId, 2)); // Update with the correct action for deactivating a color
        }
    };

    const deleteColorData = async (colorId) => {
        const result = await confirm(
            "Are you sure you want to delete this color?",
            options
        );
        if (result) {
            dispatch(deleteColor(colorId)); // Update with the correct action for deleting a color
        }
    };

    const doSubmitColor = (event) => {
        event.preventDefault();
        dispatch(colorUpdate(updateColor)); // Update with the correct action for updating a color
        setShow(false);
    };

    const onChangeEvent = (event) => {
        setUpdateColor({ ...updateColor, [event.target.name]: event.target.value });
    };

    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>
                            {item.status === "1" ? (
                                <label className="badge badge-success">Active</label>
                            ) : (
                                <label className="badge badge-danger">Inactive</label>
                            )}

                        </td>
                        <td>{item.createdAt}</td>
                        <td>
                            {item.status === '1' ? (
                                <>
                                    <Button className="btn btn-danger btn-sm" onClick={() => deactivateColor(item._id)}>Deactivate</Button>&nbsp;
                                </>
                            ) : (
                                <>
                                    <Button className="btn btn-success btn-sm" onClick={() => activateColor(item._id)}>Activate</Button>&nbsp;
                                </>
                            )}

                            <Button
                                className="btn btn-info btn-sm"
                                onClick={() => colorUpdateHandler(item)}
                            >
                                Edit
                            </Button>{" "}
                            <Button
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteColorData(item._id)}
                            >
                                Delete
                            </Button>
                        </td>
                    </tr>
                ))}
                
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form className="forms-sample" onSubmit={(event) => doSubmitColor(event)}>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Color Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={updateColor.name}
                                name="name"
                                onChange={(event) => onChangeEvent(event)}
                                id="exampleInputName1"
                                placeholder="Enter Color Name"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-gradient-primary mr-2">
                            Submit
                        </button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default ColorList;
