import React from "react";
import { Link } from "react-router-dom";

const BankList = ({ currentItems }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <tr key={item._id}>
            <td>{(index + 1)}</td>
            <td>{item.name}</td>
            <td>{item.bankName}</td>
            <td>{item.accountNumber}</td>
            <td>{item.ifsc}</td>
            <td>
              {item.accountType === '1' ? (
                  'Saving'
              ) : (
                  'Current'
              )}
            </td>
          </tr>
        ))}
    </>
  );
};

export default BankList;


