import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { sizeAddResult, getSizeList } from '../Action/Size'; 
import SizeList from '../Component/SizeList'; 
import Pagination from '../Paginate/Pagination';
import { Col, Row, Card, CardBody, Table, List, Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import Form from '../Component/Form';
import Text from '../Component/Text';

let PageSize = 10;

const Size = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.size); 
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getSizeList()); // Use 'getSizeList' action for sizes
  }, [dispatch]);

  // Add New Size

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);

  const sizeAdd = () => {
    setTitle('Add New Size');
    setShow(true);
  };

  const doSubmitSize = (event) => {
    dispatch(sizeAddResult(event)); 
    setShow(false);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  const initialValues = {
    name : ""
  }
  return (
    <Layout>
      <div className="content-wrapper">
        <Row className="page-header">
          <h3 className="page-title">Manage Size List</h3>
          <nav aria-label="breadcrumb">
            <List tag="ol" className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/dashboard'}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Size List
              </li>
            </List>
          </nav>
        </Row>
        <Row>
          <Col className="grid-margin stretch-card">
            <Card>
              <CardBody>
                <Button
                  style={{ float: 'right' }}
                  className="btn btn-info btn-sm"
                  onClick={sizeAdd} // Use 'sizeAdd' function
                >
                  Add Size
                </Button>
                {isFetching && payload.length === 0 ? (
                            <div className="table-responsive">
                            <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Add Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="4">No Records Found</td>
                      </tr>
                    </tbody>
                  </Table>
                  </div>
                ) : (
                  <>
                            <div className="table-responsive">

                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Add Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <SizeList currentItems={currentTableData} /> 
                      </tbody>
                    </Table>
                    </div>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={payload.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {/* <form className="forms-sample" onSubmit={(event) => doSubmitSize(event)}> */}
        <Form
                   enableReinitialize
                   initialValues={initialValues}
                   onSubmit={(event) => doSubmitSize(event)}
        >

          <Modal.Body>
            <div className="form-group">
              {/* <label htmlFor="exampleInputName1">Size Name</label>
              <input
                type="text"
                className="form-control"
                value={addNewSize.name}
                name="name"
                onChange={(event) => onChangeEvent(event)}
                id="exampleInputName1"
                placeholder="Enter Size Name"
              /> */}
              <Text
              name = "name"
              label = "Size Name"
              placeholder="Enter Size Name"
              required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-gradient-primary mr-2">
              Submit
            </button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Size;
