export default function paymentMethod(type){
    //1 => Wallet,   2 => Online,  3 => COD,   4=> GP, 5=>wallet+GP,6=>wallet+Online,7=>GP+Online, 8=>Wallet + GP+ Online
    switch(type) {
        case 1:
          return 'Wallet';
          break;
        case 2:
          return 'Online';
          break;
        case 3:
            return 'COD';
            break;
        case 4:
            return 'GP';
          break;
        case 5:
            return 'wallet+GP';
          break;
          case 6:
            return 'wallet+Online';
            break;
        case 7:
            return 'GP+Online';
          break;
        case 8:
            return 'Wallet + GP+ Online';
            break;
        default:
            return 'N/A';
      }
}