import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost,getToken } from "../services/Token";

export const ADD_NEW_VEHICLE = "ADD_NEW_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const EDIT_VEHICLE = "EDIT_VEHICLE";
export const ALL_VEHICLE = "ALL_VEHICLE";
export const SINGLE_VEHICLE = "SINGLE_VEHICLE";

export function getVehicleList(searchTerm) {
  return async (dispatch, getState) => {
    const config = getToken('get','vehicle-list', searchTerm);
    const responsive = await axios(config);
    const Vehicle = responsive.data.hathme;
    const payload = Vehicle.data;
    if (Vehicle.resCode == 1) {
      dispatch({ type: ALL_VEHICLE, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_VEHICLE,payload:[], isFetching: false});
    }
  }
}

export function addVehicle(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post', 'vehicle-create', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({type: ADD_NEW_VEHICLE,message: payload.resMsg, isSucess: false,});
      toast.success(payload.resMsg);
      dispatch(getVehicleList());
    } else if (payload.resCode == 0) {
      toast.error(payload.resMsg);
    }
  };
}

export function updateVehicleResult(payloadData) {
  return async (dispatch) => {
    const postData = {
      "hathme": payloadData
    }
    const config = getTokenPost('post', 'vehicle-update', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({type: EDIT_VEHICLE,
        message: payload.resMsg,
        isSucess: false,});
      toast.success(payload.resMsg);
      dispatch(getVehicleList());
    } else if (payload.resCode == 0) {
      dispatch({type: EDIT_VEHICLE,
        message: payload.resMsg,
        isSucess: false,});
      toast.error(payload.resMsg);
    }
  };
}


export function deleteVehicle(vehicleId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "vehicleId": vehicleId
      }
    }
    const config = getTokenPost('post', 'vehicle-delete', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.resCode == 1) {
      dispatch({
        type: DELETE_VEHICLE,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
    }
    dispatch(getVehicleList());
  };
}

