import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

function Protected(props){
    const { Component } = props
    const navigate = useNavigate();
    let loginStatus = false;
    const user = JSON.parse(localStorage.getItem('user')) || [];
    if(user != null && Object.keys(user).length > 0){
      loginStatus = true;
    }
    useEffect(()=>{
        if(!loginStatus){
            toast.success("Logout successfully");
            navigate('/');
        }
         // eslint-disable-next-line 
    },[]);
    return(
        <div>
            <Component />
        </div>
    )
}
export default Protected;