import React, { useEffect, useState, useMemo } from 'react';
import loaderGif from "../assets/loader.gif";
import Layout from "./Layout/Layout";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import Pagination from '../Paginate/Pagination';
import axios from 'axios';
import { getTokenPost } from "../services/Token";
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";

let PageSize = 10;

const MerchantProducts = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
    const [merchantProducts, setMerchantProducts] = useState({});
    const { id } = useParams();

    // Function to fetch merchant products
    const fetchMerchantProducts = async () => {
        try {
            const postData = {
                hathme: {
                    merchantId: id
                }
            };
            const config = getTokenPost("post", 'productListByMerchantId', postData);
            const response = await axios(config);
            if (response.data && response.data.hathme) {
                const { data } = response.data.hathme;
                if (typeof data === 'object' && data.allProducts) {
                    setMerchantProducts(data);
                } else {
                    setMerchantProducts({});
                }
            } else {
                setMerchantProducts({}); // Initialize as an empty object if data is not present
            }

        } catch (error) {
            console.error("Error fetching merchant products:", error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchMerchantProducts();
        // eslint-disable-next-line
    }, []);

    const filteredData = useMemo(() => {
        if (Array.isArray(merchantProducts.allProducts)) {
            return merchantProducts.allProducts.filter((product) => {
                switch (selectedStatusFilter) {
                    case 'pending':
                        return product.isProductApproved === 0;
                    case 'approved':
                        return product.isProductApproved === 1;
                    case 'rejected':
                        return product.isProductApproved === 2;
                    default:
                        return true; // Show all when no filter is applied
                }
            });
        } else {
            return []; // Return an empty array if merchantProducts.allProducts is not defined
        }
    }, [selectedStatusFilter, merchantProducts]);

    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const ApprovedAccount = async (productId) => {
        const result = await confirm("Are you sure! You want to Approve this product.", options);
        if (result) {
            const postData = {
                "hathme": {
                  "productId": productId
                }
              }
            const config = getTokenPost('post', 'approveProduct', postData);
            const responsive = await axios(config);
            const payload = responsive.data.hathme;
            if (payload.success == 1) {
              toast.success(payload.resMsg);
              fetchMerchantProducts()
            }
        }
      }
      const RejectedAccount = async (productId) => {
        const result = await confirm("Are you sure! You want to Reject this product.", options);
        if (result) {
        const postData = {
            "hathme": {
              "productId": productId
            }
          }
        const config = getTokenPost('post', 'rejectProduct', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success == 1) {
          toast.success(payload.resMsg);
          fetchMerchantProducts()
        }
    }
      }
    return (
        <>
            <Layout>
                <div className='content-wrapper'>
                    <Row className='page-header'>
                        <h3 className="page-title">{merchantProducts.merchantName} Merchant Products</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className='breadcrumb'>
                                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Product List</li>
                            </List>
                        </nav>
                    </Row>

                    <Row>
                        <Col className='grid-margin stretch-card'>
                            <Card>
                                <CardBody>
                                    <Link style={{float:'right'}} to={"/add-product?merchentId="+id} className="btn btn-info btn-sm">Add New Product</Link>
                                    {/* status based filter */}
                                    <div className="float-right  col-md-3">
                                        <select
                                            className="form-control"
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                color: "black",
                                                border: "1px solid #333",
                                                borderRadius: "5px",
                                                marginLeft: 10
                                            }}
                                            onChange={(e) => setSelectedStatusFilter(e.target.value)}
                                            value={selectedStatusFilter}
                                        >
                                            <option value="">Status Filter</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="rejected">Rejected</option>
                                        </select>
                                    </div>

                                    {loading ? (
                                        <img src={loaderGif} alt="Loading..." />
                                    ) : (
                                        <Table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Product Name</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Register Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {filteredData.map((product, index) => (
                                                    <tr key={product._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{product.name}</td>
                                                        <td>{product.price}</td>
                                                        <td>{product.quantity}</td>
                                                        <td>
                                                            {product.isProductApproved === 0 ? (
                                                                <label className="badge badge-warning">Pending</label>
                                                            ) : product.isProductApproved === 1 ? (
                                                                <label className="badge badge-success">Approved</label>
                                                            ) : (
                                                                <label className="badge badge-danger">Rejected</label>
                                                            )}
                                                        </td>
                                                        <td>{product.createdAt}</td>
                                                        <td>
                                                            {product.isProductApproved == 0 ? (
                                                                <>
                                                                <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(product._id)} >Approved</Button>
                                                                <Button className="btn btn-danger btn-sm" onClick={ () =>RejectedAccount(product._id)} >Rejected</Button>
                                                                </>
                                                            ): product.isProductApproved == 1 ? (
                                                                <Button className="btn btn-danger btn-sm" onClick= { () =>RejectedAccount(product._id)} >Rejected</Button>
                                                            ) :                                                                 <Button className="btn btn-success btn-sm" onClick={() => ApprovedAccount(product._id)} >Approved</Button>
                                                        }
                                                           &nbsp;&nbsp; <Link to={'/product-details/' + product._id} className="btn btn-info btn-sm">View</Link>
                                                            &nbsp;&nbsp;<Link to={'/edit-product?productId=' + product._id+"&merchentId="+product.merchantId} className="btn btn-info btn-sm">Edit</Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </Table>
                                    )}

                                    {Array.isArray(merchantProducts.allProducts) && merchantProducts.allProducts.length > 0 && (
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={merchantProducts.allProducts.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default MerchantProducts;
