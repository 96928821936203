import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import MetaTitle from "./Layout/MetaTitle";
import axios from "axios";
import { getToken } from "../services/Token";
// import icons from "../../public/web/assets/images/dashboard/circle.svg";
import MyMap from "./Map";
import MonthlyPaymentGraph from "./Graph";
import { Col, Row, Card, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import DashboardCard from "../Component/DashboardCard";

const Dashboard = () => {
  const date = new Date();
  const presentYear = date.getFullYear();
  const [result, setResult] = useState({
    userCount: 0,
    merchantCount: 0,
    productCount: 0,
    categoryCount: 0,
    orderCount: 0,
    driverCount: 0,
    totalDepositAmount: 0,
    totalBusinessAmount: 0,
  });
  const [merchantData, setMerchantData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [latestOrders, setLatestOrders] = useState([]);
  const [year, setYear] = useState(presentYear);

  useEffect(() => {
    getDashboard();
    getMerchantData();
    getPaymentData();
  }, []);

  const getDashboard = async () => {
    const config = getToken("get", "dashboard");
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setResult(dataResponse.data);
    }
  };

  const getMerchantData = async () => {
    try {
      const config = getToken("get", "allMerchantData");
      const response = await axios(config);
      const dataResponse = response.data.hathme;
      if (dataResponse.success === 1) {
        setMerchantData(response.data.hathme.data);
      }
    } catch (error) {
      console.error("Error fetching merchant data:", error.message);
    }
  };

  const getPaymentData = async () => {
    try {
      const config = getToken("get", "allPaymentData");
      const response = await axios(config);
      const dataResponse = response.data.hathme;
      if (dataResponse.success === 1) {
        const paymentData = dataResponse.data;
        setPaymentData(paymentData);
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  };

  // Function to fetch the top 10 latest orders
  const fetchLatestOrders = async () => {
    try {
      const config = getToken("get", "getTop10LatestOrders");
      const response = await axios(config);
      const dataResponse = response.data.hathme.data;
      setLatestOrders(dataResponse);
    } catch (error) {
      console.error("Error fetching latest orders:", error.message);
    }
  };

  useEffect(() => {
    fetchLatestOrders();
  }, []);

  const uniqueYears = [
    ...new Set(paymentData.map((item) => item.month.split("-")[0])),
  ];

  return (
    <>
      <Layout>
        <MetaTitle
          title={"Welcome to HATHME Dashboard"}
          description={"Welcome to HATHME Dashboard"}
        ></MetaTitle>
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-home"></i>
              </span> Dashboard
            </h3>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
              </ul>
            </nav>
          </div>
          <div className="">
          <div className="dashboard-grid ">
            <DashboardCard
              className="bg-gradient-danger"
              name="Total Users"
              icon="mdi-account-group"
              result={result.userCount}
              path = {"/user"}
            />
            <DashboardCard
              className="bg-gradient-info"
              name="Total Merchants"
              icon="mdi-store-24-hour"
              result={result.merchantCount}
              path = {"/merchant"}
            />
            <DashboardCard
              className="bg-gradient-danger"
              name="Total Drivers"
              icon="mdi-bike"
              result={result.driverCount}
              path = {"/driver"}
            />
            <DashboardCard
              className="bg-gradient-success"
              name="Total Orders"
              icon="mdi-bookmark-outline"
              result={result.orderCount}
              path = {"/order"}
            />
            <DashboardCard
              className="bg-gradient-info"
              name="Total Products"
              icon="mdi-basket-fill"
              result={result.productCount}
              path = {"/product"}
            />
            <DashboardCard
              className="bg-gradient-success"
              name="Total Category"
              icon="mdi-shape-plus"
              result={result.categoryCount}
              path = {"/category-list"}
            />
            <DashboardCard
              className="bg-gradient-info"
              name="Total Deposit Amount"
              icon="mdi-currency-inr"
              result={result.totalDepositAmount}
              path = {"/deposit"}
            />
            <DashboardCard
              className="bg-gradient-danger"
              name="Driver Withdrawals"
              icon="mdi-currency-inr"
              result={result.driverWithDrawlAmount}
              path = {"/driver-withdrawal"}
              />
            <DashboardCard
              className="bg-gradient-danger"
              name="Merchant Withdrawals"
              icon="mdi-currency-inr"
              result={result.merchantWithDrawlAmount}
              path = {"/merchant-withdrawal"}
            />
            <DashboardCard
              className="bg-gradient-success"
              name="Total Business"
              icon="mdi-currency-inr"
              result={result.totalBusinessAmount}
            />
                </div>
          


          </div>
          {/* </div> */}
          <div className="">
            <div className="d-flex justify-content-between">
            <div className="col-md-6">
              <h4 className="font-weight-normal mb-3">All Store </h4>
              <MyMap merchantData={merchantData} />
            </div>

            <div className="">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="font-weight-normal mb-3">
                    Collection of the payment report
                  </h4>
                </div>
                <div >
                  <select
                    onChange={(event) => setYear(event.target.value)}
                    name="purchaseInvoiceDateRequire"
                    value={year}
                  >
                    <option value="">Select Type</option>
                    {uniqueYears.map((year) => {
                      return <option value={year}>{year}</option>;
                    })}
                  </select>
                </div>
              </div>
              <MonthlyPaymentGraph
                paymentData={paymentData}
                presentYear={year}
              />
            </div>
            </div>
          </div>

          <br />
          <Row className="page-header">
            <h3 className="page-title">Latest Orders</h3>
          </Row>

          <Row>
            <Col className="grid-margin stretch-card">
              <div className="container-fluid">
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody className="p-2">
                      <div className="table-responsive">
                        <Table className="table table-striped table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>#Order ID</th>
                              <th>Merchant Name</th>
                              <th>Customer Name</th>
                              <th>Payment Method</th>
                              <th>Status</th>
                              <th>Payment Status</th>
                              <th>Total Amount</th>
                              <th>Order Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {latestOrders.map((order, index) => (
                              <tr key={order.orderId}>
                                <td>{index + 1}</td>

                                <td>
                                  <Link to={`/order-details/${order.id}`}>
                                    #{order.orderId}
                                  </Link>
                                </td>
                                <td>
                                <Link to={`/merchant-details/${order.merchantId}`}>
                                  {order.merchantName}
                                    </Link>
                                  </td>
                                <td>
                                  <>
                                    <Link to={`/user-details/${order.customerId}`}>
                                      {order.customerName}
                                    </Link>
                                  </>
                                </td>

                                <td>
                                  <label
                                    className={
                                      order.paymentMethod === 1
                                        ? "badge badge-info"
                                        : order.paymentMethod === 2
                                        ? "badge badge-info"
                                        : "badge badge-info"
                                    }
                                  >
                                    {order.paymentMethod === 1 ? (
                                      <span>Wallet</span>
                                    ) : order.paymentMethod === 2 ? (
                                      <span>Online</span>
                                    ) : order.paymentMethod === 3 ? (
                                      <span>Online</span>
                                    ) : (
                                      <span> Wallet + Online</span>
                                    )}
                                  </label>
                                </td>

                                <td>
                                  {order.orderStatus === "1" ? (
                                    <label className="badge badge-warning">
                                      Pending
                                    </label>
                                  ) : order.orderStatus === "2" ? (
                                    <label className="badge badge-success">
                                      Accepted
                                    </label>
                                  ) : order.orderStatus === "3" ? (
                                    <label className="badge badge-danger">
                                      Cancel
                                    </label>
                                  ) : order.orderStatus === "4" ? (
                                    <label className="badge badge-info">
                                      Driver reached pickup location
                                    </label>
                                  ) : order.orderStatus === "5" ? (
                                    <label className="badge badge-info">
                                      Picked up
                                    </label>
                                  ) : (
                                    <label className="badge badge-success">
                                      Delivered
                                    </label>
                                  )}
                                </td>
                                <td>
                                  {order.paymentStatus === 1 ? (
                                    <label className="badge badge-success">
                                      Paid
                                    </label>
                                  ) : (
                                    <label className="badge badge-danger">
                                      Not Paid
                                    </label>
                                  )}
                                </td>
                                <td>{order.finalAmount}</td>
                                <td>{order.createdAt}</td>
                                <td>
                                  <Link
                                    to={`/order-details/${order.id}`}
                                    className="btn btn-info btn-sm"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};
export default Dashboard;
