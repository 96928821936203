import { ALL_BRAND, ADD_BRAND, EDIT_BRAND, DELETE_BRAND } from "../Action/Brand";

export default function allBrand(state = {
    isFetching: true,
    isSucess: false,
    payload: []
}, action) {
    switch (action.type) {
        case ALL_BRAND:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                msg: '',
                payload: action.payload

            });

        case ADD_BRAND:
            return Object.assign({}, state, {
                isSecess: action.isSucess,
                msg: ''
            });

        case EDIT_BRAND:
            return Object.assign({}, state, {
                isSucess: action.isSecess,
                msg: action.resMsg,
            });

        case DELETE_BRAND:
            return Object.assign({}, state, {
                isSucess: action.isSucess,
                msg: action.msg
            });
        default:
            return state;
    }
}
