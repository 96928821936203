import React, { useState }  from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, FormGroup, Label, Col, Input} from "reactstrap";
import { useDispatch } from "react-redux";
import {rejectDeposit } from "../Action/Deposit";


function RejectDepositModel({show,setShow,requestData}) { 
    const handleClose = () => setShow(false);
    const modalTitle = {
        background:"#043057",
        color:"#fff"
    }
    const dispatch = useDispatch();
    const [remark, setRemark] = useState("");
    const saveRemark = ()=>{
        dispatch(rejectDeposit({depositId: requestData.depositId,remark : remark }));
        setRemark('')
        handleClose()
    }
    const changeCreds = (event) => {
      setRemark(event.target.value);
    };
    return (
      <>
        <Modal show={show} onHide={setShow}>
          <Modal.Header style={modalTitle}>
             <Modal.Title >{requestData.title}</Modal.Title>
          </Modal.Header>
           <Modal.Body  style={{background:"#fff"}}>
            <Form>
                <FormGroup row>
                    <Label for="remark" sm={2}>Remark</Label>
                    <Col sm={10}>
                        <Input type="textarea" name="remark"  onChange={(event) => changeCreds(event)} value={remark} id="remark" placeholder='Enter remark deposit..' />
                    </Col>
                </FormGroup>
            </Form>
            </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" className='btn btn-danger btn-sm' onClick={handleClose}>Close</Button>
          <Button variant="secondary" className='btn btn-info btn-sm' onClick={saveRemark} >Save Remark</Button>
        </Modal.Footer>
        </Modal>
      </>
    );
  }
export default RejectDepositModel;