import React, { useEffect, useState, useMemo } from "react";
import loaderGif from "../assets/loader.gif";
import { Link, useLocation } from "react-router-dom";
import Layout from "./Layout/Layout";
import { getAllOrder } from "../Action/Order";
import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  List,
  Input,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import OrderList from "../Component/OrderList";
import Pagination from "../Paginate/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
let PageSize = 10;
const Order = () => {
  const dispatch = useDispatch();
  const { payload, isFetching } = useSelector((state) => state.order);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [search, setSearch] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [pageNum, setPageNum] = useState();
  const [startDate, endDate] = dateRange;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get("page");
  if (pageNum) {
    queryParams.set("page", pageNum);
    queryParams.set("selectedStatusFilter", selectedStatusFilter);
    queryParams.set("searchTerm", searchTerm);
    queryParams.set("dateRange", dateRange);

    window.history.replaceState(
      null,
      "",
      `${location.pathname}?${queryParams}`
    );
  }
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  useEffect(() => {
    setCurrentPage(
      searchTerm || dateRange[0] || dateRange[1] || selectedStatusFilter || statusType
        ? 1
        : page
        ? page
        : 1
    );
    dispatch(getAllOrder(dateRange, searchTerm, selectedStatusFilter, statusType));
    setSearch(false);
  }, [dateRange, search, selectedStatusFilter, statusType]);

  const currentItems = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payload.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payload]);

  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusType = (e) => {
    setStatusType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (location.pathname === "/order") {
      setSearchTerm("");
      setSelectedStatusFilter("");
      setDateRange([null, null]);
      setSearch(true);
    }
  }, [location]);

  return (
    <>
      {isFetching ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderGif} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <div className="content-wrapper">
              <Row className="page-header">
                <h3 className="page-title">Manage Orders</h3>
                <nav aria-label="breadcrumb">
                  <List tag="ol" className="breadcrumb">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Orders List
                    </li>
                  </List>
                </nav>
              </Row>

              <Row>
                <Col className="grid-margin stretch-card">
                  <Card>
                    <CardBody >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex h-auto">
                          <div className="h-auto">
                            <select
                              className="form-control border-dark rounded mx-3 px-3"
                              style={{
                                backgroundColor: "#f5f5f5",
                              }}
                              onChange={handleStatusFilterChange}
                              value={selectedStatusFilter}
                            >
                              <option value="">PaymentMode Filter</option>
                              <option value="1">Wallet</option>
                              <option value="2">Online</option>
                              <option value="3">COD</option>
                              <option value="4">GP</option>
                              <option value="5">wallet+GP</option>
                              <option value="6">wallet+Online</option>
                              <option value="7">GP+Online</option>
                              <option value="8">Wallet + GP+ Online</option>
                            </select>
                          </div>
                          <div className="h-auto px-1">
                            <select
                              className="form-control border-dark rounded mx-3 px-3"
                              style={{
                                backgroundColor: "#f5f5f5",
                              }}
                              onChange={handleStatusType}
                              value={statusType}
                            >
                              <option value="">Status Filter</option>
                              <option value="1">pending</option>
                              <option value="2">Accept</option>
                              <option value="3">cancel</option>
                              <option value="4">
                                driver reached pickup location
                              </option>
                              <option value="5">picked up</option>
                              <option value="6">delivered</option>
                              {/* <option value="7">GP+Online</option>
                              <option value="8">Wallet + GP+ Online</option> */}
                            </select>
                          </div>
                          <div className="pl-3 h-auto">
                            <DatePicker
                              className="form-control border-dark rounded"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              placeholderText="Date Filter"
                              isClearable={true}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            style={{
                              float: "right",
                            }}
                            className="btn btn-success btn-sm"
                            onClick={() => setSearch(true)}
                          >
                            Search
                          </Button>
                          <Input
                            className="form-control border-dark rounded float-right w-auto"
                            type="text"
                            placeholder="Search by order"
                            style={{
                              height: "32px",
                            }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className=" table-responsive">

                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S No</th>
                            <th>Order ID</th>
                            <th>Merchant Name</th>
                            <th>Customer Name</th>
                            <th>Payment Method</th>
                            <th>Status</th>
                            <th>Payment Status</th>
                            <th>Total Amount</th>
                            <th>Order Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <OrderList currentItems={currentItems} currentPage = {currentPage} pageSize = {PageSize}></OrderList>
                        </tbody>
                      </Table>
                      </div>

                      <Pagination
                        className="pagination-bar"
                        currentPage={Number(currentPage)}
                        totalCount={payload.length}
                        pageSize={PageSize}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          setPageNum(page);
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
export default Order;
