import {
  APPROVE_WITHDRAWAL,REJECTED_WITHDRAWAL,ALL_WITHDRAWAL
  } from "../Action/Withdrawal";
  
  export default function allWithdrawalList(
    state = {
      isFetching: true,
      isSucess: false,
      payload: [],
    },
    action
  ) {
    switch (action.type) {
      
      case ALL_WITHDRAWAL:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: "",
          payload: action.payload,
        });
    //   case EDIT_DRIVER:
    //     return Object.assign({}, state, {
    //       isSucess: action.isSecess,
    //       msg: action.payload.resMsg,
    //     });
    //   case ADD_DRIVER:
    //     return Object.assign({}, state, {
    //       isSuccess: action.isSucess,
    //       msg: "",
    //     });
    //   case SINGLE_DRIVER:
    //     return Object.assign({}, state, {
    //       isStatus: action.isFetching,
    //       marchantDetails: action.payload,
    //     });
  
      case APPROVE_WITHDRAWAL:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          msg: action.msg,
        });
        case REJECTED_WITHDRAWAL:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          msg: action.msg,
        });
  
      default:
        return state;
    }
  }
  