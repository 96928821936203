import axios from "axios";
import { getToken,getTokenPost } from "../services/Token";
import { toast } from "react-toastify";
export const ADD_DRIVER = "ADD_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const EDIT_DRIVER = "EDIT_DRIVER";
export const ALL_DRIVER = "ALL_DRIVER";
export const SINGLE_DRIVER = "SINGLE_DRIVER";
export const UPDATE_DRIVER_DETAIL = "UPDATE_DRIVER_DETAIL";


export function getSingleDriver(driverId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "driverId":driverId
      }
    } 
    const config = getTokenPost('post','driverDetailById',postData);
    const responsive = await axios(config);
    const Merchant  = responsive.data.hathme;
    const payload = Merchant.data;
    if (Merchant.success == 1) {
      dispatch({ type: SINGLE_DRIVER, payload, isFetching: false });
    }
  };
}

export function getAllDriver(searchTerm) {
  return async (dispatch, getState) => {
    const config = getToken('get','DriverList',searchTerm);
    const responsive = await axios(config);
    const MerchantList = responsive.data.hathme;
    const payload = MerchantList.data;
    if (MerchantList.success == 1) {
      dispatch({ type: ALL_DRIVER, payload, isFetching: false });
    }else{
      dispatch({ type: ALL_DRIVER, isFetching: false });
    }
  };
}


export function approvedDriver(driverId,status) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "driverId":driverId,
        "status":status
      }
    } 
    const config = getTokenPost('post','approveRejectDriver',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: DELETE_DRIVER,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllDriver());
    }
  };
}

export function approvedDriverBankDetail(driverId,status) {

  return async (dispatch) => {
    const postData = {
      "hathme": {
        "driverId":driverId,
        "status":status
      }
    } 
    const config = getTokenPost('post','approveRejectDriverBankDetail',postData);
    const responsive = await axios(config);
    const payload  = responsive.data.hathme;

    if (payload.success == 1) {
      dispatch({
        message: payload.resMsg,
        type: UPDATE_DRIVER_DETAIL,
        // message: payload.resMsg,
        // payload,
        isSucess: false,
      });
        toast.success(payload.resMsg);
        // dispatch(getSingleDriver());
    }
  };
}
