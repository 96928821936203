import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { useDispatch } from "react-redux";
import { rejectWithdrawal,approvedWithdrawal } from "../Action/Withdrawal";
import RejectDepositModel from "./RejectDepositModel";


const WithdrawalDriverAmount = ({currentItems, currentPage, pageSize})=>{
    const [show, setShow] = useState(false);
    const [DataRequest, setDataRequest] = useState({title:'',withdrawalId:''});
    const dispatch = useDispatch();
    const options = {
        labels: {
          confirmable: "Yes",
          cancellable: "No"
        }
      }
    const ApprovedAccount = async (withdrawalId) => {
        const result = await confirm("Are you sure! You want to Approve this amount.", options);
        if (result) {
            dispatch(approvedWithdrawal({withdrawalId:withdrawalId},2));
        }
      }
      const RejectedWithdrawal = async (withdrawalId) => {
          const result = await confirm("Are you sure! You want to Reject this amount.", options);
          if (result) {
            dispatch(rejectWithdrawal({withdrawalId:withdrawalId},2));
          }
      }
      // // 1 - customer, 2 - merchant, 3 - driver, 4 - staff
    return (
            <>
             {currentItems && currentItems.map((item,index) =>(
                <tr key={item._id}>
                   <td>{((currentPage - 1) * pageSize + (index + 1) )}</td>
                    <td><Link to={"/user-details/"+item.userId}>{ item.name}</Link></td>
                    <td><Link to={"/transaction-history/"+item.userId}>{ !Number.isInteger(item.walletBalance) && Math.floor(item.walletBalance.toString().split(".")[1].length) > 4 ? item.walletBalance.toFixed(4) : item.walletBalance }</Link></td>
                    <td>{item.toPayAmount}</td>
                    <td>{item.utrNumber}</td>
                    <td>{item.bankName}</td>
                    <td>{item.accountNumber}</td>
                    <td>{item.ifsc}</td>
                    <td>{ (item.status == 1 ? (<label className="badge badge-success">Approved</label>): (item.status == 0 ? (<label className="badge badge-info">Pending</label>): (<label className="badge badge-danger">Rejected</label>) )  ) }</td>
                    <td>{item.createdAt}</td>
                    <td>
                        { (item.status == 0 ? 
                          (<>
                          <Button className='btn btn-success btn-sm' onClick={() => ApprovedAccount(item._id)}>Approve</Button> 
                           &nbsp;&nbsp;<Button className='btn btn-danger btn-sm' onClick={() => RejectedWithdrawal(item._id)}>Reject</Button>
                          </>) :(
                            <>
                           
                            </>
                          )
                        )}
                       
                    </td>
                </tr>
            ))}
            <RejectDepositModel show={show} setShow={setShow} requestData={DataRequest} />
        </>
    )
}

export default WithdrawalDriverAmount;