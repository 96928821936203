import { Line } from 'react-chartjs-2';
import { Chart, LinearScale, TimeScale, CategoryScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';

Chart.register(LinearScale, TimeScale, CategoryScale, PointElement, LineElement, Title, Tooltip);

const MonthlyPaymentGraph = ({ paymentData, presentYear }) => {
    if (!paymentData || paymentData.length === 0) {
        return <p>No payment data available.</p>;
    }

    // Generate an array of all months from January to December
    const allMonths = [];
    // const date = new Date()
    // const presentYear = date.getFullYear()
    for (let year = presentYear; year <= presentYear; year++) {
        for (let month = 1; month <= 12; month++) {
            const monthStr = `${year}-${month.toString().padStart(2, '0')}`;
            allMonths.push(monthStr);
        }
    }

    // Sort the paymentData by month in ascending order
    const paymentDataSorted = paymentData.sort((a, b) => {
        return a.month.localeCompare(b.month);
    });

    // Extract months and amounts from the sorted paymentData
    const months = allMonths.map((month) => {
        const [year, monthIndex] = month.split('-');
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        return `${monthNames[parseInt(monthIndex, 10) - 1]} ${year}`;
    });
    const amounts = allMonths.map((month) => {
        const matchingData = paymentDataSorted.find((item) => item.month === month);
        return matchingData ? matchingData.totalAmount : 0;
    });

    // Define the data for the chart
    const data = {
        labels: months,
        datasets: [
            {
                label: 'Payment Received',
                data: amounts,
                fill: false,
                borderColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 2,

            },
        ],
    };

    // Define chart options
    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Month',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Amount Received',
                },
            },
        },
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Line data={data} options={options} height={400} width={500} />
        </div>
    );
};

export default MonthlyPaymentGraph;



