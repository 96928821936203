import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { getToken, getTokenPost } from "../services/Token";
import { Col, Row, Card, CardBody, Table, List, Button } from "reactstrap";
import { confirm } from "react-confirm-box";
import axios from "axios";
import VideoShowModal from "../Component/VideoShowModal";
import Pagination from '../Paginate/Pagination';
import SearchInput from "../Component/SearchInput";

const VideoDetails = () => {
    const [videoShow, setVideoShow] = useState(false);
    const [vidSrc, setVidSrc] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState(false);

    let PageSize = 10;

    const showVideo = (videoSrc) => {
        setVidSrc(videoSrc);
        setVideoShow(true);
    }

    const { id } = useParams();

    const [videos, setVideos] = useState([]);

    const getVideos = async (searchTerm='') => {
        try {
            const config = getToken('GET', `getAdminVideoList?requestedUserId=${id}&searchTerm=${searchTerm}`);
            const response = await axios(config);
            const dataResponse = response.data.hathme.data;
            setVideos(dataResponse);
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const updateVideoStatus = async (videoId, status) => {
        try {
            const options = {
                labels: {
                    confirmable: "Yes",
                    cancellable: "No"
                }
            };

            let action;
            if (status === 'Approved') {
                action = 'Approve'
            } else if (status === 'Rejected') {
                action = 'Reject'
            }

            const result = await confirm(`Are you sure you want to ${action} this Video.`, options);

            if (result) {
                const requestData = {
                    hathme: {
                        videoId: videoId,
                        status: status
                    }
                };
                const config = getTokenPost('patch', 'updateVideoStatus', requestData);
                const response = await axios(config);
                const dataResponse = response.data.hathme;

                if (dataResponse.resCode === 1) {
                    // Update the videos list with the new status
                    setVideos(prevVideos => prevVideos.map(video => {
                        if (video._id === videoId) {
                            return { ...video, status: status };
                        }
                        return video;
                    }));
                } else {
                }
            } else {
            }
        } catch (error) {
            console.error("Error updating video status:", error);
        }
    };
    const handleSearchChange = (e) => {
        const result = e.target.value.replace(/[^a-z0-9@. ]/gi, '');
        setSearchTerm(result);
      };

    useEffect(() => {
        getVideos(searchTerm);
        setSearch(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return videos.slice(firstPageIndex, lastPageIndex);
      }, [currentPage, videos]);
    return (
        <>
            <Layout>
                <div className="content-wrapper">
                    <Row className="page-header">
                        <h3 className="page-title">Video Detail</h3>
                        <nav aria-label="breadcrumb">
                            <List tag="ol" className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Video Detail
                                </li>
                            </List>
                        </nav>
                    </Row>
                    <Row>
                        <Col className="grid-margin stretch-card">
                            <Card>
                                <CardBody>
                      <SearchInput
                      onChange={handleSearchChange}
                      searchTerm ={searchTerm}
                      placeholder = "Search by Video Name"
                      onClick ={() => setSearch(true)}
                      />
                                    {/* <h4 className="card-title">Videos Details</h4> */}
                                    <Table className="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th >SNo</th>
                                                <th style={{ width: "20%" }}>Video Thumbnail</th>
                                                <th style={{ width: "15%" }}>Total Views</th>
                                                <th style={{ width: "15%" }}>Total comments</th>
                                                <th style={{ width: "15%" }}>Total Like</th>
                                                <th style={{ width: "15%" }}>Total Dislike</th>
                                                <th style={{ width: "15%" }}>Total Shares</th>
                                                <th style={{ width: "15%" }}>Status</th>
                                                <th style={{ width: "15%" }}>Created</th>
                                                <th style={{ width: "15%" }}>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData && currentTableData.length > 0 ? (
                                                currentTableData.map((video) => (
                                                    <tr key={video.id}>
                                                                <td>{video.index}</td>
                                                        <td>
                                                            <div>
                                                            <img
                                                                style={{ cursor: 'pointer', height: "30%" , width : "30%", borderRadius: "10px" }}
                                                                onClick={() => showVideo(video.videoURL)}
                                                                src={video.videoThumbnail}
                                                                alt={video.name}
                                                            />
                                                            </div>
                                                        </td>
                                                        <td>{video.viewCount}</td>
                                                        <td>{video.VideoComments}</td>
                                                        <td>{video.totalLikes}</td>
                                                        <td>{video.totalDislikes}</td>
                                                        <td>{video.totalShares}</td>
                                                        <td>
                                                        {/* {video.status === '1' ? (
                                                            <label className="badge badge-success">Active</label>
                                                        ) : (
                                                            <label className="badge badge-danger">Deleted</label>
                                                        )} */}
                                                        {video.status == 1 ? <label className="badge badge-success">Active</label> : video.status == 2 ? <label className="badge badge-danger">Deleted</label>  : video.status == 3 ? <label className="badge badge-success">Approved</label> : <label className="badge badge-danger">Rejected</label>  }
                                                    </td>
                                                        <td>{video.createdAt}</td>
                                                        {/* <td>
                                                            <>
                                                            
                                                                <Button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => updateVideoStatus(video._id, '3')}
                                                                >
                                                                    Approve
                                                                </Button>&nbsp;
                                                                <Button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => updateVideoStatus(video._id, "4")}
                                                                >
                                                                    Reject
                                                                </Button>
                                                            </>
                                                        </td> */}
                                                        <td>
                                                            {video.status == 3 ? (
                                                                 <Button
                                                                 className="btn btn-danger btn-sm"
                                                                 onClick={() => updateVideoStatus(video._id, "4")}
                                                             >
                                                                 Reject
                                                             </Button>
                                                            ) : video.status == 4 ?(
                                                                <Button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => updateVideoStatus(video._id, '3')}
                                                                >
                                                                    Approve
                                                                </Button>
                                                            ): (
                                                                <>
                                                            
                                                                <Button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => updateVideoStatus(video._id, '3')}
                                                                >
                                                                    Approve
                                                                </Button>&nbsp;
                                                                <Button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => updateVideoStatus(video._id, "4")}
                                                                >
                                                                    Reject
                                                                </Button>
                                                            </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="12">No videos available</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </Table>
                                    <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={videos.length}
                                  pageSize={PageSize}
                                  onPageChange={page => setCurrentPage(page)} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
            <VideoShowModal show={videoShow} setShow={setVideoShow} VidSrc={vidSrc} />
        </>
    );
};

export default VideoDetails;
