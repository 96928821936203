import React, { useState, useEffect } from "react";
import { loginUser } from "../Action/Auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import isAuthenticated from "../services/authService";
// import banners from '../../src/assets/banners.jpg';

const Login = () => {
  // admin@gmail.com
  //Pass@123
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
    type:0
  });
  const [status, setPasswordStatus] = useState(true);
  const {isFetching, resCode,errorMessage } = useSelector((state) => state.auth);
  const doLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser({ password: state.password, email: state.email,type:(status ? 0 :1) }));
  };
  let navigate = useNavigate();

  let loginStatus = false;
  const user = JSON.parse(localStorage.getItem("user")) || [];
  if (user != null && Object.keys(user).length > 0) {
    loginStatus = true;
  }
  useEffect(() => {
       if(resCode == 2){
        setPasswordStatus(false);
       }
    if (resCode == 1) {
      toast.success("Login successfully");
      navigate("/dashboard");
    } else if (resCode == 0) {
      toast.error("Invalid credentials");
      navigate("/");
    }
    if (loginStatus) {
      navigate("/dashboard");
    }
  },[resCode]);
  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  // const myStyle={
  //       backgroundImage:"url('../../src/assets/banners.jpg')",
  //         height:'100vh',
  //         marginTop:'-70px',
  //         fontSize:'50px',
  //         backgroundSize: 'cover',
  //         backgroundRepeat: 'no-repeat',
  //     };

  return (
    <>
      <div className="container-scroller" >
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <h1> Welcome to Hathme Login</h1>
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={(event) => doLogin(event)}>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg"
                        value={state.email}
                        onChange={(event) => changeCreds(event)}
                        placeholder="email"
                      />
                    </div>
                    <div className="form-group" style={{display: (status ? 'none':'block') }}>
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg"
                        value={state.password}
                        onChange={(event) => changeCreds(event)}
                        placeholder="Password"
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
