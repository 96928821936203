import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Auth/Login";
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import Merchant from "./Pages/Merchant";
import Order from "./Pages/Order";
import OrderDetails from "./Pages/OrderDetails";
import Protected from "./Component/Protected";
import Category from "./Pages/Category";
import Product from "./Pages/Product";
import MerchantDetails from "./Pages/MerchantDetails";
import SubCategory from "./Pages/SubCategory";
import ProductDetails from "./Pages/ProductDetails";
import AddCategory from "./Pages/AddCategory";
import ChangePassword from "./Pages/ChangePassword";
import { ToastContainer } from "react-toastify";
import Profile from "./Pages/Profile";
import AddMerchant from "./Pages/AddMerchant";
import Driver from "./Pages/Driver";
import DepositList from "./Pages/DepositList";
import MerchantWithdrawal from "./Pages/MerchantWithdrawal";
import DriverDetails from "./Pages/DriverDetails";
import SettingList from "./Pages/SettingList";
import Setting from "./Pages/Setting";
import ProductModelList from "./Pages/ProductModelList";
import CreateModelStructure from "./Pages/CreateModelStructure";
import ModelStructureDetail from "./Pages/ModelStructureDetails";
import UserDetails from "./Pages/UserDetails";
import VideoDetails from "./Pages/VideoDetails";
import GroupDetails from "./Pages/GroupDetails";
import GroupMembers from "./Pages/GroupMembers";
import OrderHistory from "./Pages/OrderHistory";
import TransactionHistory from "./Pages/TransactionHistory";
import GreenPoint from "./Pages/GreenPoint";
import RedPoint from "./Pages/RedPoint";
import MerchantProducts from "./Pages/MerchantProducts";
import MerchantOrders from "./Pages/MerchantOrders";
import DriverOrders from "./Pages/DriverOrders";
import MerchantBusinessAmount from "./Pages/MerchantBusinessAmount";
import DriverOrdersPayment from "./Pages/DriverOrdersPayment";
import Documents from "./Pages/Documents";
import "react-toastify/dist/ReactToastify.css";
import CategoryForm from "./Pages/ManageCategoryForm";
import AddCategoryForm from "./Pages/AddCategoryForm";
import CouponList from "./Pages/Coupon";
import AddCoupon from "./Pages/AddCoupon";
import CouponDetails from "./Pages/CouponDetail";
import Brand from "./Pages/Brand";
import Color from "./Pages/Color";
import Size from "./Pages/Size";
import BankList from "./Pages/BanksList";
import DriverWithdrawal from "./Pages/DriverWithdrawal";
import VehicleList from "./Pages/VehicleList";
import AddProduct from "./Pages/AddProduct";
import UpdateProduct from "./Pages/UpdateProduct";
import DriverCodPayment from "./Pages/DriverCodPayment";
import DriverSendMoney from "./Pages/DriverSendMoney";
import Banner from "./Pages/Banner";
import AddBanner from "./Pages/AddBanner";

const App = () => {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={<Protected Component={Dashboard} />}
          />
          <Route path="/user" element={<Protected Component={User} />} />
          <Route
            path="/merchant"
            element={<Protected Component={Merchant} />}
          />
          <Route path="/order" element={<Protected Component={Order} />} />
          <Route
            path="/category-list"
            element={<Protected Component={Category} />}
          />
          <Route
            path="/category-form"
            element={<Protected Component={CategoryForm} />}
          />

          <Route path="/product" element={<Protected Component={Product} />} />
          <Route path="/merchant-details/:id" element={<Protected Component={MerchantDetails} />} />
          <Route path="/add-category" element={<Protected Component={AddCategory}></Protected>} />
          <Route path="/category-form/:id" element={<Protected Component={AddCategoryForm}></Protected>} />
          <Route path="/order-details/:id" element={<Protected Component={OrderDetails}></Protected>} />
          <Route path="/product-details/:productID" element={<Protected Component={ProductDetails}></Protected>} />
          <Route path="/manage-subcategory-list" element={<Protected Component={SubCategory}></Protected>} />
          <Route path="/change-password" element={<Protected Component={ChangePassword}></Protected>} />
          <Route path="/profile" element={<Protected Component={Profile}></Protected>} />
          <Route path="/add-new-merchant" element={<Protected Component={AddMerchant}></Protected>} />
          <Route path="/deposit" element={<Protected Component={DepositList}></Protected>} />
          <Route path="/merchant-withdrawal" element={<Protected Component={MerchantWithdrawal}></Protected>} />
          <Route path="/driver-withdrawal" element={<Protected Component={DriverWithdrawal}></Protected>} />
          <Route path="/coupon" element={<Protected Component={CouponList}></Protected>} />
          <Route path="/add-coupon" element={<Protected Component={AddCoupon}></Protected>} />
          <Route path="/coupon-detail/:id" element={<Protected Component={CouponDetails}></Protected>} />
          <Route path="/driver" element={<Protected Component={Driver}></Protected>} />
          <Route path="/driver-details/:id" element={<Protected Component={DriverDetails}></Protected>} />
          <Route path="/setting" element={<Protected Component={SettingList}></Protected>} />
          <Route path="/setting-details/:settingId" element={<Protected Component={Setting}></Protected>} />
          <Route path="/model-structure" element={<Protected Component={ProductModelList}></Protected>} />
          <Route path="/add-model-structure" element={<Protected Component={CreateModelStructure}></Protected>} />
          <Route path="/model-structure-detail/:id" element={<Protected Component={ModelStructureDetail}></Protected>} />
          <Route path="/user-details/:id" element={<Protected Component={UserDetails} />} />
          <Route path="/video-details/:id" element={<Protected Component={VideoDetails} />} />
          <Route path="/group-details/:id" element={<Protected Component={GroupDetails} />} />
          <Route path="/group-members/:groupId" element={<Protected Component={GroupMembers} />} />
          <Route path="/order-history/:id" element={<Protected Component={OrderHistory} />} />
          <Route path="/transaction-history/:id" element={<Protected Component={TransactionHistory} />} />
          <Route path="/green-point-details/:id" element={<Protected Component={GreenPoint} />} />
          <Route path="/red-point-details/:id" element={<Protected Component={RedPoint} />} />
          <Route path="/merchant-products-details/:id" element={<Protected Component={MerchantProducts} />} />
          <Route path="/merchant-orders-details/:id" element={<Protected Component={MerchantOrders} />} />
          <Route path="/driver-order-details/:id" element={<Protected Component={DriverOrders} />} />
          <Route path="/merchant-payment-history/:id" element={<Protected Component={MerchantBusinessAmount} />} />
          <Route path="/driver-payment-details/:id" element={<Protected Component={DriverOrdersPayment} />} />
          <Route path="/driver-cod-payment/:id" element={<Protected Component={DriverCodPayment} />} />
          <Route path="/document" element={<Protected Component={Documents} />} />
          <Route path="/brand" element={<Protected Component={Brand} />} />
          <Route path="/color" element={<Protected Component={Color} />} />
          <Route path="/size" element={<Protected Component={Size} />} />
          <Route path="/bank-list/:id" element={<Protected Component={BankList} />} />
          <Route path="/merchant-bank-list/:id" element={<Protected Component={BankList} />} />
          <Route path="/vehicle-list" element={<Protected Component={VehicleList} />} />
          <Route path="/add-product" element={<Protected Component={AddProduct} />} />
          <Route path="/edit-product" element={<Protected Component={UpdateProduct} />} />
          <Route path="/driver-send-money" element={<Protected Component={DriverSendMoney} />} />
          <Route path="/banner" element={<Protected Component={Banner} />} />          
          <Route path="/add-banner" element={<Protected Component={AddBanner} />} />          
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;
