import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";
import thunk from "redux-thunk";
import auth from "./reducers/auth";
import user from "./reducers/user";
import allMerchant from "./reducers/merchant";
import allCategory from "./reducers/category";
import allOrder from "./reducers/order";
import {allProduct ,getProductModel} from "./reducers/product";
import allSubCategory from "./reducers/subcategory";
import allDriver from "./reducers/driver"
import allDepositList from "./reducers/deposit";
import allSettingDetails from "./reducers/setting";
import allCoupon from "./reducers/coupon";
import allDocument from "./reducers/document";
import allBrand from "./reducers/brand";
import allColor from "./reducers/color";
import allSize from "./reducers/size";
import allBanks from "./reducers/bank";
import allWithdrawalList from "./reducers/withdrawal";
import allVehicleList from "./reducers/vehiclel";
import allBanners from "./reducers/banner";
// import Cookie from 'js-cookie';

const reducer = combineReducers({
  auth: auth,
  user: user,
  merchant: allMerchant,
  order: allOrder,
  category: allCategory,
  product: allProduct,
  subCategory: allSubCategory,
  allDrivers: allDriver,
  allDepositList: allDepositList,
  settingDetails: allSettingDetails,
  coupon : allCoupon,
  allDocuments: allDocument,
  brand: allBrand,
  color: allColor,
  size: allSize,
  banks:allBanks,
  withdrawalList: allWithdrawalList,
  allVehicleList:allVehicleList,
  productModel:getProductModel,
  banner: allBanners
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
export default store;
