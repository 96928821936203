import axios from "axios";
import { BaseURL } from "./BaseURL";
import { toast } from "react-toastify";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_STATUS = "LOGIN_STATUS";

export function receiveLogin(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
}
export function receiveEmailStatus(payload) {
  return {
    type: LOGIN_STATUS,
    payload,
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

// function requestLogout() {
//   return {
//     type: LOGOUT_REQUEST,
//   };
// }

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    // dispatch(requestLogout());
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify([]));
    dispatch(receiveLogout());

    // return (
    //
    // )
  };
}

export function loginUser(creds) {
  return async (dispatch) => {
    const response = await axios.post(`${BaseURL}/login`, creds);
    let res = response.data;
    if (res.hathme.resCode === 1) {
      localStorage.setItem("user", JSON.stringify(res.hathme.data));
      dispatch(receiveLogin(res.hathme));
      // dispatch(loginError("Yoy are logedin"));
    }else if(res.hathme.resCode === 2){
      dispatch({ type: LOGIN_FAILURE,payload:res.hathme, isFetching: false });
      //dispatch(receiveLogin(res.hathme));
    } else {
      toast.error(res.hathme.resMsg);
      dispatch({ type: LOGIN_FAILURE,payload:res.hathme, isFetching: false });
    }
    //
    // if (creds.email.length > 0 && creds.password.length > 0) {
    //   localStorage.setItem('authenticated', true)
    // } else {
    //   dispatch(loginError('Something was wrong. Try again'));
    // }
  };
}
