
import {
    ADD_SUB_CATEGORY,
    ALL_SUB_CATEGORY,
    DELETE_SUB_CATEGORY,
    EDIT_SUB_CATEGORY,
    SINGLE_SUB_CATEGORY
  } from "../Action/SubCategory";

  export default function allSubCategory(state = {
    isFetching: true,
    isSucess:false,
    payload:[]
  }, action) {
    switch (action.type) {
      case ALL_SUB_CATEGORY:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: '',
          payload:action.payload

        });
      case EDIT_SUB_CATEGORY:
        return Object.assign({}, state, {
          isSucess:action.isSecess,
          msg: action.resMsg,
        });
      case ADD_SUB_CATEGORY:
        return Object.assign({}, state, {
          isSecess: action.isSucess,
            msg: ''
        });
        case SINGLE_SUB_CATEGORY:
            return Object.assign({}, state, {
              isFetching: false,
              msg: '',
            });

        case DELETE_SUB_CATEGORY:
        return Object.assign({}, state, {
            isSucess: action.isSucess,
            msg: action.msg
        });
      default:
        return state;
    }
  }
  