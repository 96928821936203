import React, { useEffect, useState,  } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, List, Button } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";
import Form from "../Component/Form";
import Text from "../Component/Text";
import Select from "../Component/Select";
import { getAllCategory } from "../Action/Category";
import { onlineMerchants } from "../Action/Merchant";
import { toast } from "react-toastify";
import { addBanner, editBannerData } from "../Action/Banner";
import SearchSelect from "../Component/SearchSelect";

const AddBanner = () => {
  const [Image, setImage] = useState(null);
  const [bannerDetails, setBannerDetails] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [type, setType] = useState();
  const { merchant, category, isFetchingMerchant, isFetchingCategory } = useSelector((state) => ({
    merchant: state.merchant.payload,
    category: state.category.payload,
    isFetchingMerchant: state.merchant.isFetching,
    isFetchingCategory: state.category.isFetching,
  })); 
  const [bannerType, setBannerType] = useState(1);
const [merchantId, setMerchantId] = useState();
  const search = useLocation().search;
  let navigate = useNavigate();
  const bannerId = new URLSearchParams(search).get("banner");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(onlineMerchants());

    if (bannerId) {
      getBannerDetail();
    }
    // eslint-disable-next-line
  }, [bannerType]);
  const getBannerDetail = async () => {
    const config = getTokenPost("get", `bannerDetailsById?id=${bannerId}`);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.resCode === 1) {
      setType(dataResponse.data.type);
      setBannerDetails(dataResponse.data);
      setBannerType(dataResponse.data.bannerType);
    }
  };
  const onSubmit = async (values) => {
    if (bannerId) {
      try {
        const data = new FormData();
        data.append("title", values.title);
        data.append("url", values.url);
        data.append("id", bannerId);
        data.append("type", type ? type : bannerDetails.type);
        data.append("bannerType", values.bannerType );
        if (Image) {
          data.append(`file`, Image[0]);
        }
        dispatch(
          editBannerData(data, (res) => {
            if (res === 1) navigate("/banner");
          })
        );
      } catch (err) {
        toast.error(err);
      }
    } else {
      try {
        const data = new FormData();
        data.append("title", values.title);
        if(bannerType == 3){
          data.append("url", values.categories);
        }else if(bannerType == 4){
          data.append("url", merchantId);
        }else if(values && values.url){
          data.append("url", values.url)
        }
        data.append("type", type);
        data.append("bannerType", values.bannerType );

        if(Image){
          data.append(`file`, Image[0]);
        }
        dispatch(
          addBanner(data, (res) => {
            if (res === 1) navigate("/banner");
          })
        );
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const initialValues = {
    title: bannerDetails.title ? bannerDetails.title : "",
    url: bannerDetails.url ? bannerDetails.url : "",
    type: bannerDetails.type ? bannerDetails.type : "",
    bannerType: bannerDetails.bannerType ? bannerDetails.bannerType : 1,
    merchants: bannerDetails.url ? bannerDetails.url :"",
    categories: bannerDetails.url? bannerDetails.url :"",
  };
  const dataTypeStyle = {
    width: "30px",
    height: "30px",
    margin: "auto",
    marginTop: "-14px",
  };

  const bannerTypeOptions = [
    {label : "Image", value : 1},
    // {label : "Video", value : 2},
    {label : "Category", value : 3},
    {label : "Merchant", value : 4}
  ]

  const handleCategories = () => {
    const ActiveCategories = []
    if(category){
      category.map(data => {
      if(data.isActive == 1){
        ActiveCategories.push({label : data.name, value: data._id})
      }
      })
      return ActiveCategories
    }
  }
  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">{bannerId ? "Edit Banner" : "Add New Banner"}</h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  {" "}
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {bannerId ? "Edit Banner" : "Add New Banner"}
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <Form
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                  >
                    <Text name="title" label="Title" required />
                         <Select
                            label="Banner Type"
                            name="bannerType"
                            placeholder="Select Banner Type"
                            handleChange={(e) => setBannerType(e.target.value)}
                            required={true}
                            options={bannerTypeOptions}
                          />
                     {bannerType == 1 &&   <Text name="url" label="Url" type="url" />}
                     {bannerType == 4 &&
                      // <Select
                      // label="Merchants"
                      // name="merchants"
                      // placeholder="Select Merchant"
                      // // handleChange={(e) => setBannerType(e.target.value)}
                      // required={true}
                      // options={merchant}
                      // /> } 
                    <SearchSelect
                    label="Merchants"
                    name="merchants"
                    placeholder="Select Merchant"
                    setMerchantId = {setMerchantId}
                    required={true}
                    options={merchant}
                    /> } 
                    {bannerType == 3 &&
                    <Select
                    label="Categories"
                    name="categories"
                    placeholder="Select Category"
                    // handleChange={(e) => setBannerType(e.target.value)}
                    required={true}
                    options={handleCategories()}
                    />
                    }

                      <div className="row pt-3">
                        <div class="col-lg-1 col-md-5 col-sm-6 col-xs-6">
                          <label htmlFor="app">
                            <input
                              type="radio"
                              className="form-control"
                              value={1}
                              name="type"
                              style={dataTypeStyle}
                              onChange={(e) => setType(e.target.value)}
                              checked={type == 1 ? "checked" : ""}
                            />
                            App
                          </label>
                        </div>
                        <div class="col-lg-1 col-md-5 col-sm-6 col-xs-6">
                          <label htmlFor="website">
                            <input
                              type="radio"
                              className="form-control"
                              value={2}
                              name="type"
                              style={dataTypeStyle}
                              onChange={(e) => setType(e.target.value)}
                              checked={type == 2 ? "checked" : ""}
                            />
                            Website
                          </label>
                        </div>
                      </div>
                    {/* </div> */}

                    <Text
                      type="file"
                      name="image"
                      label="Upload the Image"
                      onChange={(e) => setImage(e.target.files)}
                      disabled={
                        type ? false : bannerDetails.type ? false : true
                      }
                      accept={
                        // type == 1 ? 
                        "image/*" 
                        // : "video/mp4,video/x-m4v,video/*"
                      }
                      required={  bannerDetails.bannerImage ? false : true }
                    />
                  { bannerDetails.bannerImage && <img
                        src={bannerDetails.bannerImage}
                        alt="BannerImage"
                        style={{ height: "20%", width: "20%" }}
                      />}
                    {/* {bannerDetails.type == 1 ? (
                      <img
                        src={bannerDetails.bannerImage}
                        alt="BannerImage"
                        style={{ height: "20%", width: "20%" }}
                      />
                    ) : (
                      bannerDetails.bannerImage && (
                        <video controls style={{ height: "20%", width: "20%" }}>
                          <source
                            src={bannerDetails.bannerImage}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )
                    )} */}
                    <div className="pt-2">
                      <Button type="submit" className="btn-info btn-sm">
                        {" "}
                        {bannerId ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default AddBanner;