import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocument } from "../Action/Document";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import loaderGif from "../assets/loader.gif";
import DocumentList from "../Component/DocumentList";
import Pagination from '../Paginate/Pagination';

let PageSize = 10;
const Documents = () => {
    const dispatch = useDispatch();
    const { payload, isFetching } = useSelector((state) => state.allDocuments);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(getAllDocument());
    }, [dispatch]);

    const currentItems = useMemo(() => {
        if (!payload) {
            return []; // Return an empty array if payload is undefined
        }

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return payload.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, payload]);
    return (
        <>
            {isFetching ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img src={loaderGif} alt='logo' />
                    </div>
                </>
            ) : (
                <>
                    <Layout>
                        <div className='content-wrapper'>
                            <Row className='page-header'>
                                <h3 className="page-title">Manage Documents List
                                </h3>
                                <nav aria-label="breadcrumb">
                                    <List tag="ol" className='breadcrumb'>
                                        <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"> Documents List</li>
                                    </List>
                                </nav>
                            </Row>

                            <Row>
                                <Col className='grid-margin stretch-card'>
                                    <Card>

                                        <CardBody>
                                            {/* <h4 className="card-title">Manage Orders List</h4> */}
                                            <Table className="table table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Bussiness Name</th>
                                                        <th>Mobile</th>
                                                        <th>Document Number</th>
                                                        <th>Document Type</th>
                                                        <th>Back Image</th>
                                                        <th>Front Image</th>
                                                        <th>Upload Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <DocumentList currentItems={currentItems}></DocumentList>
                                                </tbody>
                                            </Table>
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={payload ? payload.length : 0}
                                                pageSize={PageSize}
                                                onPageChange={page => setCurrentPage(page)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Layout>
                </>
            )}
        </>
    );
};

export default Documents;

