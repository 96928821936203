import React, { useState ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";
import { ListGroupItem, ListGroup,Input} from "reactstrap";
import {sendPushNotification, sendToAllPushNotification, groupByNotifications} from "../Action/Push";

function NotificationModal(props) {
   const {show,setShow,userId,type='',selectedUserIds} = props
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const [data , setData] = useState({userId:'',title:'',message:''});
    const onchangeEvent = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    }
      useEffect(() => {
        setData({ ...data, userId:userId});
      },[userId]);
    const notificationSend = () => {
        if(type == ''){
          dispatch(sendPushNotification(data));
        }else if(selectedUserIds && userId == 0){
          dispatch(groupByNotifications({title:data.title,message:data.message, userIds: selectedUserIds}));
        }else{
          dispatch(sendToAllPushNotification({userType:type,title:data.title,message:data.message}));
        }
        setShow(false)
    }
    return (
      <>
        <Modal show={show} onHide={setShow}>
          <Modal.Header>
            <Modal.Title>Send Notification</Modal.Title>
          </Modal.Header>
           <Modal.Body>
           <ListGroup>
                <ListGroupItem>Title: 
                    <Input
                        id="title"
                        name="title"
                        placeholder="Notification title"
                        onChange={(event) => { onchangeEvent(event) }}
                        type="text"
                    /> 
                </ListGroupItem>
                <ListGroupItem>Message: 
                    <Input
                        id="message"
                        name="message"
                        placeholder="Enter message"
                        onChange={(event) => { onchangeEvent(event) }}
                        type="textarea"
                    /> 
                </ListGroupItem>
                </ListGroup>
            </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="secondary" className='btn btn-info' onClick={notificationSend}>Send Notification</Button>
        </Modal.Footer>
        </Modal>
      </>
    );
  }
export default NotificationModal;