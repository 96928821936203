import { toast } from "react-toastify";
import axios from "axios";
import { getTokenPost, getToken } from "../services/Token";

export const ALL_BRAND = "ALL_BRAND ";
export const ADD_BRAND = "ADD_BRAND"
export const EDIT_BRAND = "EDIT_BRAND"
export const DELETE_BRAND = "DELETE_BRAND"

export function getBrandList() {
    return async (dispatch) => {
        const config = getToken('get', 'getBrands');
        const responsive = await axios(config);
        const brandList = responsive.data.hathme;
        const payload = brandList.brands;
        if (brandList.success === 1) {
            dispatch({ type: ALL_BRAND, payload, isFetching: false });
        } else {
            dispatch({ type: ALL_BRAND, payload:[], isFetching: false });
            toast.error(payload.resMsg);
        }
    }
}

export function brandAddResult(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('post', 'addBrand', postData);
        const response = await axios(config);
        const payload = response.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: ADD_BRAND,
                message: payload.resMsg,
                isSuccess: true,
            });
            toast.success(payload.resMsg);
            dispatch(getBrandList());
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
            dispatch({
                type: ADD_BRAND,
                message: payload.resMsg,
                isSuccess: false,
            });
        }
    };
}

export function brandUpdate(payloadData) {
    return async (dispatch) => {
        const postData = {
            "hathme": payloadData
        }
        const config = getTokenPost('put', 'updateBrand', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: EDIT_BRAND,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
            dispatch(getBrandList());
        } else if (payload.success === 0) {
            toast.error(payload.resMsg);
        }
    };
}

export function deleteBrand(brandId) {
    return async (dispatch) => {
        const postData = {
            "hathme": {
                "brandId": brandId
            }
        }
        const config = getTokenPost('post', 'deleteBrand', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: DELETE_BRAND,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
        }
        dispatch(getBrandList());
    };
}

export function activDeactivBrand(brandId, status) {
    return async (dispatch) => {
        const postData = {
            "hathme": {
                "brandId": brandId,
                "status": status
            }
        };
        const config = getTokenPost('post', 'activeDeactiveBrand', postData);
        const responsive = await axios(config);
        const payload = responsive.data.hathme;
        if (payload.success === 1) {
            dispatch({
                type: DELETE_BRAND,
                message: payload.resMsg,
                isSucess: false,
            });
            toast.success(payload.resMsg);
            dispatch(getBrandList());
        }
    };
}