import { toast } from "react-toastify";
// import { showAlert } from "./alert.js"
import axios from "axios";
import { BaseURL } from "../Action/BaseURL";
import { getToken, getTokenPost } from "../services/Token";

export const ADD_MERCHANT = "ADD_MERCHANT";
export const APPROVE_PRODUCT = "APPROVE_PRODUCT";
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const ALL_PRODUCT = "ALL_PRODUCT";
export const PENDING_PRODUCT = "PENDING_PRODUCT";
export const SINGLE_MERCHANT = "SINGLE_MERCHANT";

// export function addMerchant(payload) {
//   console.log(payload, "payload is coming");
//   return async (dispatch, getState) => {
//     const responsive = await axios.post(`${BaseURL}/AddCategory`, payload);

//     console.log(responsive.data.hathme);
//     if (responsive.data.hathme.resCode == 1) {
//       dispatch({ type: ADD_MERCHANT, payload, isSucess: true });
//       toast.success("Successfully updated");
//       dispatch(getAllMerchant());
//     }
//   };
// }

export function approvedProduct( searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter, productId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "productId": productId
      }
    }
    const config = getTokenPost('post', 'approveProduct', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: APPROVE_PRODUCT,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllProduct( searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter,));
    }
  };
}

export function rejectProduct( searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter, productId) {
  return async (dispatch) => {
    const postData = {
      "hathme": {
        "productId": productId
      }
    }
    const config = getTokenPost('post', 'rejectProduct', postData);
    const responsive = await axios(config);
    const payload = responsive.data.hathme;
    if (payload.success == 1) {
      dispatch({
        type: APPROVE_PRODUCT,
        message: payload.resMsg,
        isSucess: false,
      });
      toast.success(payload.resMsg);
      dispatch(getAllProduct( searchTerm, selectedCategory, selectedSubCategory, selectedStatusFilter,));
    }
  };
}
// export function editCategory(data) {
//   let payload = data;

//   let id = payload.hathme.id;

//   return async (dispatch, getState) => {
//     // await axios.PUT(`${baseUrl}/updateCategory`)
//     const responsive = await axios.put(`${BaseURL}/updateCategory/${id}`, data);

//     console.log(responsive);
//     const categoriesList = responsive.data.hathme;
//     const payload = categoriesList.data;
//     if (categoriesList.resCode == 1) {
//       dispatch({ type: EDIT_MERCHANT, payload, isSucess: true });
//       dispatch(getAllMerchant());
//     }
//   };
// }

// export function getSingleMerchant(id) {
//   return async (dispatch) => {
//     const responsive = await axios.get(`${BaseURL}/GetSingleMerchant/:${id}`);
//     const categoriesList = responsive.data.hathme;
//     const payload = categoriesList.data;
//     if (categoriesList.resCode == 1) {
//       dispatch({ type: SINGLE_MERCHANT, payload, isFetching: false });
//     }
//   };
// }


export function getAllProduct(searchTerm='', Category='', SubCategory='', selectedStatusFilter='') {
  return async (dispatch, getState) => {
   const config = getToken('get',`getAllProduct?searchTerm=${searchTerm}&Category=${Category}&subCategory=${SubCategory}&status=${selectedStatusFilter}`);
    let responsive = await axios(config);
    const ProductList = responsive.data.hathme;
    const payload = ProductList.data;
    if (ProductList.success === 1) {
      dispatch({ type: ALL_PRODUCT, payload, isFetching: false });
    } else {
      dispatch({ type: ALL_PRODUCT,payload:[], isFetching: false });
    }
  };
}

export function getPendingProduct(payload) {
  return async (dispatch, getState) => {
    const user = (await JSON.parse(localStorage.getItem("user"))) || [];
    let token = user.token;
    const config = {
      method: "get",
      url: `${BaseURL}/getPendingProduct`,
      headers: {
        deviceType: 3,
        appVersion: 1,
        apiVersion: 1,
        deviceId: "deviceId",
        languageCode: "en",
        loginRegion: "IN",
        token: token,
      },
    };
    let responsive = await axios(config);
    const ProductList = responsive.data.hathme;
    const payload = ProductList.data;
    if (ProductList.resCode === 1) {
      dispatch({ type: PENDING_PRODUCT, payload, isFetching: false });
    }
  };
}
