import {
  ALL_MERCHANT,
  ADD_MERCHANT,
  EDIT_MERCHANT,
  DELETE_MERCHANT,
  SINGLE_MERCHANT,
} from "../Action/Merchant";

export default function allMerchant(
  state = {
    isFetching: true,
    isSucess: false,
    payload: [],
  },
  action
) {
  switch (action.type) {
    
    case ALL_MERCHANT:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        msg: "",
        payload: action.payload,
      });
    case EDIT_MERCHANT:
      return Object.assign({}, state, {
        isSucess: action.isSecess,
        msg: action.payload.resMsg,
      });
    case ADD_MERCHANT:
      return Object.assign({}, state, {
        isSuccess: action.isSucess,
        msg: "",
      });
    case SINGLE_MERCHANT:
      return Object.assign({}, state, {
        isStatus: action.isFetching,
        marchantDetails: action.payload,
      });

    case DELETE_MERCHANT:
      return Object.assign({}, state, {
        isSucess: action.isSucess,
        msg: action.msg,
      });

    default:
      return state;
  }
}
