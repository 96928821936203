import { toast } from 'react-toastify';
// import { showAlert } from "./alert.js"
import axios from 'axios';
import { getToken,getTokenPost } from "../services/Token";
export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_SUCCESS';
export const EDIT_ORDER = 'EDIT_ORDER';
export const ALL_ORDER = 'ALL_ORDER';
export const SINGLE_ORDER = "SINGLE_ORDER";
export const ORDER_ASSIGN_DRIVER = 'ORDER_ASSIGN_DRIVER';
export const ORDER_CANCELLED = 'ORDER_CANCELLED';

export function getAllOrder( dateRange='', searchTerm='', selectedStatusFilter='', statusType='') {
    return async (dispatch, getState) => {
        const config = getToken('get',`GetAllOrder?dateRange=${dateRange}&searchTerm=${searchTerm}&statusFilter=${selectedStatusFilter}&statusType=${statusType}`);
        const responsive = await axios(config)
        const OrderList = responsive.data.hathme;
        const payload = OrderList.data;
        if (OrderList.success === 1) {
            dispatch({ type: ALL_ORDER, payload, isFetching: false })
        }else{
          dispatch({ type: ALL_ORDER, isFetching: false });
        }
    }

}

export function getSingleOrder(orderId) {
    return async (dispatch) => {
      const postData = {
        "hathme": {
          "orderId":orderId
        }
      } 
      const config = getTokenPost('post','orderDetailById',postData);
      const responsive = await axios(config);
      const Merchant  = responsive.data.hathme;
      const payload = Merchant.data;
      if (Merchant.success == 1) {
        dispatch({ type: SINGLE_ORDER, payload, isFetching: false });
      }
    };
  }
  export function driverAssing(driverId,orderId) {
    return async (dispatch) => {
      const postData = {
        "hathme": {
          "orderId":orderId,
          "driverId":driverId,
        }
      } 
      const config = getTokenPost('post','assignOrderDriver',postData);
      const responsive = await axios(config);
      const result  = responsive.data.hathme;
      if (result.resCode == 1) {
          dispatch({ type: ORDER_ASSIGN_DRIVER, payload:[], isFetching: false });
      }
      toast.success(result.resMsg);
      dispatch(getSingleOrder(orderId));
    };
  }
  export function CancelOrder(orderId) {
    return async (dispatch) => {
      const postData = {
        "hathme": {
          "orderId":orderId,
        }
      } 
      const config = getTokenPost('post','CancelOrder',postData);
      const responsive = await axios(config);
      const result  = responsive.data.hathme;
      if (result.resCode  == 1) {
        dispatch({ type: ORDER_CANCELLED, payload:[], isFetching: false });
      }
      toast.success(result.resMsg);
      dispatch(getSingleOrder(orderId));
    };
  }

