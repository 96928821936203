import { ALL_COLOR, ADD_COLOR, EDIT_COLOR, DELETE_COLOR } from "../Action/Color";

export default function allColor(state = {
    isFetching: true,
    isSucess: false,
    payload: []
}, action) {
    switch (action.type) {
        case ALL_COLOR:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                msg: '',
                payload: action.payload
            });

        case ADD_COLOR:
            return Object.assign({}, state, {
                isSecess: action.isSucess,
                msg: ''
            });

        case EDIT_COLOR:
            return Object.assign({}, state, {
                isSucess: action.isSecess,
                msg: action.resMsg,
            });

        case DELETE_COLOR:
            return Object.assign({}, state, {
                isSucess: action.isSucess,
                msg: action.msg
            });
        default:
            return state;
    }
}
