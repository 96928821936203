import { ALL_BANKS, ADD_BANKS, EDIT_BANKS, DELETE_BANKS } from "../Action/Banks";

export default function allBanks(state = {
    isFetching: true,
    isSucess: false,
    payload: []
}, action) {
    switch (action.type) {
        case ALL_BANKS:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                msg: '',
                payload: action.payload

            });

        case ADD_BANKS:
            return Object.assign({}, state, {
                isSecess: action.isSucess,
                msg: ''
            });

        case EDIT_BANKS:
            return Object.assign({}, state, {
                isSucess: action.isSecess,
                msg: action.resMsg,
            });

        case DELETE_BANKS:
            return Object.assign({}, state, {
                isSucess: action.isSucess,
                msg: action.msg
            });
        default:
            return state;
    }
}
