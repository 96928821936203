import {
    ALL_DRIVER,
    ADD_DRIVER,
    EDIT_DRIVER,
    DELETE_DRIVER,
    SINGLE_DRIVER,
    
  } from "../Action/Driver";
  
  export default function allDriver(
    state = {
      isFetching: true,
      isSucess: false,
      payload: [],
    },
    action
    
  ) {

    switch (action.type) {
      
      case ALL_DRIVER:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          msg: "",
          payload: action.payload,
        });
      case EDIT_DRIVER:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          msg: action.payload.resMsg,
        });
      case ADD_DRIVER:
        return Object.assign({}, state, {
          isSuccess: action.isSucess,
          msg: "",
        });
      case SINGLE_DRIVER:
        return Object.assign({}, state, {
          isStatus: action.isFetching,
          driverDetails: action.payload,
        });
  
      case DELETE_DRIVER:
        return Object.assign({}, state, {
          isSucess: action.isSucess,
          msg: action.msg,
        });
        
    
      default:
        return state;
    }
  }
  