import { BaseURL } from "../Action/BaseURL";

export function getToken(methodGet,userGet,searchTerm) {
    var URLs = BaseURL+'/'+userGet;
    const user = JSON.parse(localStorage.getItem("user")) || [];
    let token = user.token;
    const config = {
        method: methodGet,
        url: URLs,
        headers: {
          deviceType: 3,
          appVersion: 1,
          apiVersion: 1,
          deviceId: "deviceId",
          languageCode: "en",
          loginRegion: "IN",
          token: token,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        params: { searchTerm: searchTerm }, 
      };
      return config;
  }

  export function getTokenPost(methodGet,userGet,getData) {
    var URLs = BaseURL+'/'+userGet;
    const user = JSON.parse(localStorage.getItem("user")) || [];
    let token = user.token;
    const config = {
        method: methodGet,
        url: URLs,
        headers: {
          deviceType: 3,
          appVersion: 1,
          apiVersion: 1,
          deviceId: "deviceId",
          languageCode: "en",
          loginRegion: "IN",
          token: token,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
          'Content-Type': 'application/json',
        },
        data:getData
      };
      return config;
  }