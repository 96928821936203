import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Layout from "./Layout/Layout";
import { addCategory, editCategory } from '../Action/Category';
import { Col, Row, Card, CardBody, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";

const AddCategory = () => {
  const dispatch = useDispatch();
  const [addNewCategory, setaddNewCategory] = useState({ name: "", description: "", image: "",isDocument:0,cancelType:1,isOtherCategory:0 })
  const [base64Image, setBase64Image] = useState(null)
  const [updateCategoryId, setUpdateCategoryId] = useState({ id: 0 })

  const search = useLocation().search;
  const category = new URLSearchParams(search).get("category");
 
  useEffect(() => {
    if (category != null) {
      updateCategory(category);
    }
  // eslint-disable-next-line
  }, []);

  // Update Category Details
  const updateCategory = async () => {
    const postData = {
      "hathme": {
        "categoryId": category
      }
    }
    const config = getTokenPost('post', 'categoryDetailById', postData);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    if (dataResponse.success === 1) {
      setaddNewCategory(dataResponse.data);
      setBase64Image(dataResponse.data.image);
      setUpdateCategoryId({ id: dataResponse.data._id })
    }
  }

  // Add New Category
  const imageStyle = { width: "155px", height: "155px" };

  const onchangeEvent = (event) => {
    if (event.target.name === 'image') {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64Image(reader.result);
        let images = reader.result.split(",");
        setaddNewCategory({ ...addNewCategory, 'image': images[1] });
      };
    } else {
      setaddNewCategory({ ...addNewCategory, [event.target.name]: event.target.value });
    }
  }
  let navigate = useNavigate();
  const doSumbitCategory = (event) => {
    event.preventDefault();
    if (updateCategoryId.id !== 0) {
      dispatch(editCategory({ name: addNewCategory.name, description: addNewCategory.description, image: addNewCategory.image, categoryId: updateCategoryId.id,isDocument:addNewCategory.isDocument,cancelType:addNewCategory.cancelType,isOtherCategory:addNewCategory.isOtherCategory }));
    } else {
      dispatch(addCategory(addNewCategory));
    }
    navigate("/category-list");
  }
  return (
    <>
      <Layout>
        <div className='content-wrapper'>
          <Row className='page-header'>
            <h3 className="page-title">{(updateCategoryId.id !== 0 ? 'Update Category' : 'Add New Category')}
            </h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className='breadcrumb'>
                <li className="breadcrumb-item"> <Link to={'/dashboard'} >Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{(updateCategoryId.id !== 0 ? 'Update' : 'Add')}</li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className='grid-margin stretch-card'>
              <Card>
                <CardBody>
                  <form className="forms-sample" onSubmit={(event) => doSumbitCategory(event)}>

                    <div className="form-group">
                      <label htmlFor="exampleInputName1">Category name</label>
                      <input type="text" className="form-control" value={addNewCategory.name} name="name" onChange={(event) => { onchangeEvent(event) }} id="exampleInputName1" placeholder="Enter category Name" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="cancelType1">Cancel type</label>
                      <select className="form-control" name="cancelType" value={addNewCategory.cancelType} onChange={(event) => { onchangeEvent(event) }}>
                        <option value={1} >Non-Cancellable</option>
                        <option value={2} >Cancel Within Time</option>
                        <option value={3} >Cancel/ Refund</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="documentNo">Is document upload allowed</label>
                          <div className="row">
                            <div class="col-lg-3 col-md-5 col-sm-6 col-xs-6">
                              <label htmlFor="documentNo">  <input type="radio" className="form-control" value={0} name="isDocument" onChange={(event) => { onchangeEvent(event) }} checked={(addNewCategory.isDocument == 0 ? 'checked':'')}/>No</label>
                            </div>
                            <div class="col-lg-3 col-md-5 col-sm-6 col-xs-6">
                                <label htmlFor="documentYes"><input type="radio" className="form-control" value={1} name="isDocument" onChange={(event) => { onchangeEvent(event) }} id="documentYes" checked={(addNewCategory.isDocument == 1 ? 'checked':'')}/>Yes</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="isOtherCategoryNo">Is allowed to merge another category.</label>
                          <div className="row">
                            <div class="col-lg-3 col-md-5 col-sm-6 col-xs-6">
                              <label htmlFor="isOtherCategoryNo">  <input type="radio" className="form-control" value={0} name="isOtherCategory" onChange={(event) => { onchangeEvent(event) }} checked={(addNewCategory.isOtherCategory == 0 ? 'checked':'')}/>No</label>
                            </div>
                            <div class="col-lg-3 col-md-5 col-sm-6 col-xs-6">
                                <label htmlFor="isOtherCategoryYes"><input type="radio" className="form-control" value={1} name="isOtherCategory" onChange={(event) => { onchangeEvent(event) }} id="isOtherCategoryYes" checked={(addNewCategory.isOtherCategory == 1 ? 'checked':'')}/>Yes</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group" style={{display:'none'}}>
                      <label htmlFor="exampleTextarea1">Description</label>
                      <textarea
                        className="form-control"
                        id="exampleTextarea1"
                        onChange={(event) => { onchangeEvent(event) }}
                        rows="4"
                        name="description"
                        value={addNewCategory.description}
                        placeholder="Enter category description">
                        {addNewCategory.description}
                      </textarea>
                    </div>
                    <div className="form-group">
                      <label>Category Image upload</label>
                      <div className="input-group col-xs-12">
                        <input type="file" name="image" onChange={(event) => { onchangeEvent(event) }} className="form-control file-upload-info" placeholder="Upload Image" />
                        <img style={imageStyle} src={base64Image} alt="" />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-gradient-primary mr-2">{(updateCategoryId.id !== 0 ? 'Update' : 'Submit')}</button>
                    <Link to={'/category-list'} className="btn btn-light">Cancel</Link>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default AddCategory;