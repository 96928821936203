import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import RenderInputGroup from "./RenderInputGroup";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
  }

  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if (required && (!value 
      // || !value.trim() 
      )) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }
  renderInput({ field, form: { touched, errors, setFieldValue, values } }) {
    const {
      name,
      id,
      label,
      placeholder,
      required,
      onChange,
      type,
      onSearch,
      onInputChange,
      min,
      maxLength,
      showCharCount,
      defaultValue,
      addonText,
      addonType,
      error,
      onKeyDown,
      onClick,
      disabled,
      autoFocus,
      autoComplete,
      className,
      ref,
      onKeyPress,
      options,
      placeholderValue,
      handleOnChangeSubmit,
      setType
      // value
    } = this.props;

    let errorMessage = touched[name] && errors[name] ? errors[name] : null;
    if (error) {
      errorMessage = error;
    } 

    const countInputChars = () => {
      if (this.state.inputValue !== undefined) {
        return this.state.inputValue.length;
      }
      return 0;
    };

    const setInputValue = (e) => {
      const { value } = e.target;
      this.setState({
        inputValue: value,
      });
    };


    return (
      <FormGroup
        style={{ position: "relative", fontSize: "14px" }}
        className={`${className} ${(!!errorMessage && "is-invalid") || ""}`}
      >
        {label && (
                      <label htmlFor="exampleInputName1">{label}{required ? <span style={{color: 'red'}}>*</span> :""}</label>

        )}
        <RenderInputGroup
          condition={addonText !== null && addonText !== undefined}
          wrapper={(children) => <InputGroup>{children}</InputGroup>}
        >
          {addonText && (
            <InputGroupText addonType={addonType}>{addonText}</InputGroupText>
          )}
          <Input
            id={id || name}
            name={name || id}
            className="form-control"
            {...field}
            type= "select"
            min={min}
            placeholder={placeholder || label}
            invalid={!!errorMessage}
            defaultValue={defaultValue}
            onKeyPress={onKeyPress}
            onInputChange={(value) => onSearch && onSearch(value)}
            onKeyUp={(e) => {
              setInputValue(e);
              // onChange && onChange(e);
              setFieldValue(name,  e.target.value);
            }}
            onChange={(event) => {
              setFieldValue(name, event.target.value);
              // onChange && onChange(event)
              // if (onInputChange) {
              //   values[name] = event.target.value;
              //   onInputChange({ setFieldValue, values });
              // }
              this.props.handleChange && this.props.handleChange(event);
              // if (handleOnChangeSubmit) {
              //   values[name] = event;
              //   handleOnChangeSubmit(values, name, event.target.value);
              // }
            }}
            // onChange={onChange}
            style={required && touched[name] && errors[name] ? {border: "Revert", borderColor: "Red", }:{}}
            onKeyDown={onKeyDown}
            maxLength={maxLength && maxLength ? maxLength : "255"}
            onClick={onClick && onClick}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            ref={ref}
            >
              {placeholder && 
            <option value = {placeholderValue ? placeholderValue : ""}>{placeholder}</option>
              }
             {options.map(option => {
                 return (
                      <option value={option.value}>{option.label}</option>
                   )
            })}

          </Input>
        </RenderInputGroup>
        {showCharCount && (
          <span className="char-count d-block text-inline-grayed h7">
            {`${countInputChars()}/${maxLength ? maxLength : "255"} Characters`}
          </span>
        )}
        {errorMessage && (
          <FormFeedback
            id={id || name}
            name={name || id}
            style={{ position: "absolute", marginTop: 1 }}
          >
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name } = this.props;

    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        // value = {value}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  addonText: PropTypes.string,
  addonType: PropTypes.oneOf(["prepend", "append"]),
};

export default Select;
