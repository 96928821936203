import React from 'react';
import Modal from 'react-bootstrap/Modal';

function VideoShowModal({ show, setShow, VidSrc }) {
    const vidstyle = {
        maxWidth: '100%',
        borderRadius: '5px',
        boxShadow: '0px 4px 29px 11px',
        width: '100%',
        maxHeight: '450px'
    };

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body style={{ padding: '0px' }}>
                    <video controls style={vidstyle}>
                        <source src={VidSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default VideoShowModal;
