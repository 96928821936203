import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { Col, Row, Card, CardBody, Table, List } from "reactstrap";
import axios from "axios";
import { getTokenPost } from "../services/Token";


const CouponDetails = () => {
  const { id } = useParams();
  const [coupon, setCoupon] = useState({ 
    categoryId: "",
    couponCode: "",
    amount: "",
    maxDiscount: "",
    expireDate: "",
    type: "",
    minPriceForApplyCoupon: "",
    maxPriceForApplyCoupon: "",
    isActive: "",
  });

const getCoupon = async () => {
    const config = getTokenPost("get", `getCouponDetail/${id}`);
    const response = await axios(config);
    const dataResponse = response.data.hathme;
    
    if (dataResponse.success == 1) {
        setCoupon(dataResponse.data);

    }
  };

  useEffect(() => {
    if (id) {
        getCoupon(id);
    }
  }, [id]);


  return (
    <>
      <Layout>
        <div className="content-wrapper">
          <Row className="page-header">
            <h3 className="page-title">Product Details</h3>
            <nav aria-label="breadcrumb">
              <List tag="ol" className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Product Details
                </li>
              </List>
            </nav>
          </Row>
          <Row>
            <Col className="grid-margin stretch-card">
              <Card>
                <CardBody>
                  <h4 className="card-title">Product Details</h4>
                  <Table className="table table-striped">
                    {/* <thead> */}
                      <tr>
                        <th>Coupon Code</th>
                        <th>Discount Type</th>
                        <th>Discount Amount</th>
                        <th>Maximum Discount</th>
                        <th>Min Price For Apply Coupon</th>
                        {/* <th>Maximum Price For Apply Coupon</th> */}
                      </tr>
                    {/* </thead> */}
                    <tbody>
                      <tr>
                        <td>{coupon.couponCode}</td>
                        <td>{coupon.type == 1 ? "Percentage" : coupon.type == 2 ? "Flat" : "N/A"}</td>
                        <td>{coupon.amount ? coupon.amount : "N/A"}</td>
                        <td>{coupon.maxDiscount ? coupon.maxDiscount : "N/A"}</td>
                        <td>{coupon.minPriceForApplyCoupon ? coupon.minPriceForApplyCoupon : "N/A"}</td>
                        {/* <td>{coupon.maxPriceForApplyCoupon}</td> */}
                      </tr>

                      <tr>
                        <th>Status</th>
                        <th>Expiry Date</th>
                        <th>useType</th>
                        <th>No Of Times</th>
                        <th>Days</th>
                      </tr>

                      <tr>
                        <td>{coupon.isActive  == '1' ? 'Active' : 'Inactive'}</td> 
                        <td>{coupon.expireDate ? coupon.expireDate : "N/A"}</td>
                        <td>{coupon.useType ==1 ? "Normal" : coupon.useType == 2 ? "Registration" : "Delivery"}</td> 
                        <td>{coupon.NoOfTimes ? coupon.NoOfTimes : "N/A"}</td>
                        <td>{coupon.days ? coupon.days : "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Created</th>

                      </tr>

                      <tr>
                        <td>{coupon.createdAt}</td>

                      </tr>




              
                         <tr>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
}

export default CouponDetails;