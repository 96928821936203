import React, { useEffect, useState } from 'react';
import Text from './Text';

const SearchSelect = (props) => {
  const { options, label, name, required, placeholder, setMerchantId } = props;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState('');
  const [selectedOption, setSelectedOption] = useState({ value: null, label: '' });
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    // Update filteredOptions when options change
    setFilteredOptions(options);
  }, [options]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleOptionHover = (option) => {
    setHoveredOption(option ? option.label : '');
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setMerchantId(option.value)
    setDropdownOpen(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    const data = options.filter(option =>
      option.label.toLowerCase().includes(inputValue) || (option.mobile && option.mobile.toLowerCase().includes(inputValue))
    );
  
    setFilteredOptions(data ? data : []);
  
    setSelectedOption({ value: null, label: e.target.value });
  };

  return (
    <div>
         {label && (
                      <label htmlFor={name}>{label}{required ? <span style={{color: 'red'}}>*</span> :""}</label>

        )}
      <input
        name={name}
        label={label}
        className='form-control'
        type="search"
        placeholder={placeholder}
        onChange={e => handleInputChange(e)}
        value={selectedOption.label}
        onClick={toggleDropdown}
        onBlur={closeDropdown}
        required={required}
      />
      {isDropdownOpen && (
        <div className="dropdown-content form-control h-auto" style={{ position: "absolute", zIndex: 1, backgroundColor: "#FFF", boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", overflow: "auto", maxWidth: '95%' }}>
          {filteredOptions && filteredOptions.map((option, index) => (
            <div
              key={index}
              onMouseOver={() => handleOptionHover(option)}
              onMouseLeave={() => handleOptionHover(null)}
              onMouseDown={() => handleOptionClick(option)}
              style={{
                backgroundColor: hoveredOption === option.label ? "#007aff" : "white",
                cursor: "pointer",
                color: hoveredOption === option.label ? "white" : "black",
                padding: '8px',
                margin: '0px',

              }}
            >
                {
                    option.mobile ? `${option.label}(${option.mobile})` : option.label
                }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
